import { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { maskBigNumber } from "../../../shared/utils";
import { COMMON_REQUIRED_FORM_RULE,INPUT_NUMBER_FORMAT } from "../../../constants";
import { getUniqueKey } from "../../../shared/utils";
import useCompanyForm from "../../../hooks/useCompanyForm";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  InputNumber,
  Divider,
  Table,
  Tooltip,
  Space,
  Typography,
} from 'antd';

const CompanyStocksForm = ({ onSave, setStep }) => {
  const { values, setValues } = useCompanyForm();
  const [form] = Form.useForm();
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [saving, setSaving] = useState(false);

  const { Text, Paragraph } = Typography;

  const onAddClick = async () => {
    try {
      await form.validateFields();

      const copyStocks = [...stocks];
      const formValues = form.getFieldsValue();
      const stock = {
        id: getUniqueKey(),
        serie_clase: formValues.classOrSerie,
        valor_nominal: formValues.price,
        cantidad: formValues.quantity,
        notas: formValues.notes
      };

      copyStocks.push(stock);
      setStocks(copyStocks);

      form.resetFields();
    } catch (error) {
    }
  };

  const onEditClick = async () => {
    try {
      await form.validateFields();

      const copyStocks = [...stocks];
      const stockIndex = copyStocks.findIndex((current) => current.id === selectedStock.id);

      if (stockIndex >= 0) {
        const formValues = form.getFieldsValue();
        const stock = {
          id: selectedStock.id,
          serie_clase: formValues.classOrSerie,
          valor_nominal: formValues.price,
          cantidad: formValues.quantity,
          notas: formValues.notes
        };
  
        copyStocks[stockIndex] = stock;
        setStocks(copyStocks);
      }

      form.resetFields();
      setSelectedStock(null);
    } catch (error) {
    }
  };

  const onDeleteClick = async (id) => {
    const copyStocks = [...stocks];
    const stockIndex = copyStocks.findIndex((current) => current.id === id);
    copyStocks.splice(stockIndex, 1);
    setStocks(copyStocks);
  };

  const setStock = (row) => {
    setSelectedStock(row);
    form.setFieldsValue({
      classOrSerie: row.serie_clase,
      price: row.valor_nominal,
      quantity: row.cantidad,
      notes: row.notas
    });
  }

  const onSaveClick = async () => {
    try {
      const copyStocks = [...stocks];
      const newValues = { ...values, acciones_empresa: copyStocks };
      setValues(newValues);
      setSaving(true);

      await onSave(newValues);

      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  const onSubmitClick = async () => {
    try {
      const copyStocks = [...stocks];
      if (copyStocks.length === 0) return;
      const newValues = { ...values, acciones_empresa: copyStocks };
      setValues(newValues);
      setStep(2)
    } catch (error) {
    }
  };

  const columns = [
    {
      title: 'Serie / Clase',
      dataIndex: 'serie_clase',
      width: 110
    },
    {
      title: 'Precio MID',
      dataIndex: 'valor_nominal',
      width: 180,
      render: (cell) => (<Text>{maskBigNumber(cell)}</Text>)
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      width: 200,
      render: (cell) => (<Text>{maskBigNumber(cell)}</Text>)
    },
    {
      title: 'Notas',
      dataIndex: 'notas',
      width: 300,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '300px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      )
    },
    {
      title: "",
      dataIndex: "id",
      width: 100,
      align: "center",
      render: (cell, row) => (
        <Space size="small">
          <Tooltip title={"Editar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              onClick={() => setStock(row)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              onClick={() => onDeleteClick(cell)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(
    () => {
      const items = form.getFieldValue("stocks");
      if (!items) form.setFieldsValue({ "stocks": [{ serie: null, quantity: null, value: null, notes: null }]});
      setStocks(values.acciones_empresa);
    },
    []
  );

  return (
    <>
      <Form form={form} layout={"vertical"}>
        <Row gutter={24}>
            <Col xs={24} sm={4}>
              <Form.Item label="Serie / Clase" name="classOrSerie" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
                <Input type={"text"} maxLength={20} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item label="Precio MID" name="price" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
                <InputNumber
                  className={"w100"}
                  formatter={value => maskBigNumber(value)}
                  parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                  min={0}
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item label="Cantidad" name="quantity" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
                <InputNumber
                  className={"w100"}
                  formatter={value => maskBigNumber(value)}
                  parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                  min={0}
                  max={2147483647}
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Notas" name="notes" hasFeedback>
                <Input.TextArea maxLength={250} rows={1} autoSize={false} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4} className="dFlex justifyCenter alignItemsCenter">
              <Button onClick={() => selectedStock ? onEditClick() : onAddClick()} className="w100" style={{ marginTop: '6px'}}>
                {!selectedStock && <span>Agregar</span>}
                {selectedStock && <span>Editar</span>}
              </Button>
            </Col>
          </Row>
          <Table columns={columns} dataSource={stocks} size="small" rowKey={'id'} />
          <Divider />
          <Row gutter={24}>
            <Col span={6}>
              <Button
                key={getUniqueKey()}
                size="small"
                onClick={() => setStep(0)}
              >
                Regresar
              </Button>
            </Col>
            <Col span={6} offset={12} className="textEnd">
              <Space>
                <Button
                  type='primary'
                  ghost
                  key={getUniqueKey()}
                  size="small"
                  loading={saving}
                  onClick={() => onSaveClick()}
                >
                  <span>Guardar</span>
                </Button>
                <Button
                  type='primary'
                  ghost
                  key={getUniqueKey()}
                  size="small"
                  loading={values.loading}
                  onClick={() => onSubmitClick()}
                >
                  <span>Siguiente</span>
                </Button>
              </Space>
            </Col>
          </Row>
      </Form>
    </>
  )
}

export default CompanyStocksForm;