import { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Input,
  Space
} from "antd";

const CustomTable = (props) => {

  const searchInput = useRef(null);
  const [paginator, setPaginator] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const getPaginatorParams = (queryParams, pagination) => {
    let newQueryParams = [...queryParams];
    let newOffset = (pagination.current - 1) * 10;
    let newLimit = pagination.pageSize;

    queryParams.forEach((param, index) => {
      if (param?.includes("limit=")) newQueryParams[index] = `limit=${newLimit}`;
      if (param?.includes("offset=")) newQueryParams[index] = `offset=${newOffset}`;
    });

    if (newQueryParams.length === 0) {
      newQueryParams.push(`limit=${newLimit}`);
      newQueryParams.push(`offset=${newOffset}`);
    }

    return newQueryParams;

  }

  const getSorterParams = (queryParams, sorter) => {
    const field = sorter?.column?.filterName;
    const order = sorter?.order;
    let newQueryParams = [...queryParams];
    let isOrderFound = false;

    queryParams.some((param, index) => {
      if (param.includes("ordering") && sorter && field) {
        if (order === "descend") {
          newQueryParams[index] = `ordering=-${field}`;
        } else if (order === "ascend") {
          newQueryParams[index] = `ordering=${field}`;
        } else {
          newQueryParams[index] = "";
        }
        isOrderFound = true;
        return true;
      }
      return false;
    });

    if (!isOrderFound) {
      if (order === "descend") newQueryParams.push(`ordering=-${field}`);
      if (order === "ascend") newQueryParams.push(`ordering=${field}`);
    }

    return newQueryParams;

  }

  const updateProps = (newProps) => {

    let url = newProps.endpoint;

    const queryParamsFiltered = newProps.queryParams.filter((param) => param !== "");
    queryParamsFiltered.forEach((param, index) => {
      if (!index && param) url += `?${param}`;
      if (index && param) url += `&${param}`;
    });

    newProps.url = url;
    newProps.updateFromTable = true;
    newProps.loading = true;
    
    setLoading(true);

    props.config.onRefresh(newProps);

  };

  const onSearchClick = (value, filterName) => {

    let queryParams = [...props?.config?.queryParams];
    let isFilterFound = false;
    let isOffsetFound = false;

    props?.config?.queryParams.some((param, index) => {
      if (param.includes(`${filterName}=`)) {
        if (value) {
          queryParams[index] = `${filterName}=${value}`;
        } else {
          queryParams[index] = "";
        }
        isFilterFound = true;
        return true;
      }
      return false;
    });

    if (!isFilterFound) queryParams.push(`${filterName}=${value}`);

    props?.config?.queryParams.some((param, index) => {
      if (param.includes("offset=")) {
        queryParams[index] = "offset=0";
        isOffsetFound = true;
        return true;
      }
      return false;
    });

    if (!isOffsetFound) queryParams.push("offset=0");

    setPaginator({...paginator, current: 1 });
    updateProps({ ...props.config, queryParams });

  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : "")}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => onSearchClick(selectedKeys, dataIndex)}
            size="small"
            style={{ width: 180 }}
          >
            Aplicar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? "#0056B8" : undefined }}/>),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns = props.config.columns.map((column) => {
    if (column.filterName) {
      if (column.search) {
        return {
          ...column,
          sorter: true,
          sortDirections: ["descend", "ascend"],
          ...getColumnSearchProps(column.filterName),
        }
      } else {
        return {
          ...column,
          sorter: true,
          sortDirections: ["descend", "ascend"]
        }
      }
    } else {
      return { ...column}
    }
  });

  const onChange = (pagination, _, sorter) => {

    if (!props || !props.config || !props.config.queryParams) return;

    let queryParams = [...props?.config?.queryParams];
    let url = props.config.url;

    if (sorter) queryParams = getSorterParams(queryParams, sorter);
    if (pagination) {
      setPaginator({...paginator, current: pagination.current });
      queryParams = getPaginatorParams(queryParams, pagination);
    }

    updateProps({...props.config, url, queryParams });

  }

  useEffect(
    () => {
      if (!paginator && props.config.paginator.total > 0) {
        setPaginator(props.config.paginator);
      }
      if (paginator) {
        setPaginator({...paginator, total: props.config.paginator.total});
      }
      if (props.config.loading === false) {
        setLoading(false);
      }
    },
    [props]
  );

  return (
    <>
      {props && 
        <Table
          columns={columns}
          onChange={onChange}
          loading={loading}
          dataSource={props.config.data}
          pagination={paginator}
          size="small"
          rowKey={props.config.rowKey}
        />
      }
    </>
  )
}

export default CustomTable;