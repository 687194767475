import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorResult, CustomTable } from "../../../components";
import http from "../../../services/http.service";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { EditOutlined } from "@ant-design/icons";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import {
  Card,
  PageHeader,
  Typography,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  notification,
} from "antd";

const SettingsParams = () => {

  const [pageError, setPageError] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [modalConfig, setModalConfig] = useState({ isVisible: false, isLoading: false });
  const [form] = Form.useForm();
  const { Paragraph } = Typography;
  const { TextArea } = Input;
  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/settings/overview",
      breadcrumbName: "Configuraciones",
    },
    {
      path: "/pages/settings/params",
      breadcrumbName: "Parametros",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 80
    },
    {
      title: "Valor",
      dataIndex: "valor",
      filterName: "valor",
      ellipsis: { showTitle: false },
      width: 150,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '150px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Notas",
      dataIndex: "notas",
      width: 500,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '500px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 80,
      align: "center",
      render: (cell, row) => (
        <Tooltip title={"Editar"}>
          <Button
            size={"small"}
            className="noBorderColor bgTransparent"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue({ value: row.valor, notes: row.notas });
              setModalConfig({ isVisible: true, isLoading: false, id: cell })
            }}
          />
        </Tooltip>
      ),
    },
  ];

  const onCreateClick = async () => {
    console.log("onCreateClick");
  }

  const onEditClick = async () => {
    try {
      const values = form.getFieldsValue();
      const payload = {
        valor: values.value,
        notas: values.notes
      };

      const request = {
        endpoint: `parametros/${modalConfig.id}/`,
        method: "PATCH",
        data: payload,
      };

      setModalConfig({...modalConfig, isLoading: true });

      await http(request);

      notification.success({
        message: '¡Correcto!',
        description: 'Actualizado correctamente.',
      });

      setModalConfig({ isVisible: false, isLoading: false });
      form.resetFields();
      getData(customTableConfig);
    } catch (error) {
      setModalConfig({...modalConfig, isLoading: false });
      notification.error({ message: '¡Ups!', description: error.message });
    }

  }

  const getData = async (props = null) => {
    try {
      setPageError(undefined);
      let endpoint = "parametros/";
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "parametros/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {
      setPageError(error);
    }
  };

  useEffect(() => { getData(); }, []);

  return (
    <>
      <PageHeader
        title='Configuración de parametros'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/settings/overview")}
      />
      {!pageError && (
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={customTableConfig} />
          </div>
        </Card>
      )}
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/settings/overview"}
        />
      )}
      {modalConfig && modalConfig.isVisible &&
        <Modal
          title={(modalConfig?.id) ? "Editar Parametro" : "Crear Parametro"}
          visible={modalConfig.isVisible}
          maskClosable={false}
          onOk={(modalConfig?.id) ? onEditClick : onCreateClick}
          okText={(modalConfig?.id) ? "Actualizar" : "Crear"}
          confirmLoading={modalConfig.isLoading}
          onCancel={() => {
            setModalConfig({ isVisible: false, isLoading: false });
            form.resetFields();
          }}
        >
          <Form form={form} layout='vertical'>
            <Form.Item name="value" label="Valor" rules={[{ required: true }]}>
              <Input maxLength={40} />
            </Form.Item>
            <Form.Item name="notes" label="Notas" rules={[{ required: true }]}>
              <TextArea
                showCount
                autoSize={false}
                rows={2}
                maxLength={250}
              />
            </Form.Item>
          </Form>
        </Modal>
      }
    </>
  );
};

export default SettingsParams;