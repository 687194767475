import { useEffect } from "react";
import { Result } from 'antd';
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

const NotSupported = () => {

  const size = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    if (size.width > 1000) {
      navigate("/pages/dashboard", { replace: true });
    }
  }, [size])
  

  return (
    <div style={{ width: "90%", margin: "1rem auto" }}>
      <Result
        status="404"
        title="¡Ups!"
        subTitle="Dispositivos con pantallas pequeñas por el momento no estan soportadas."
      />
    </div>
  )
}

export default NotSupported