import {
  useState,
  useEffect
} from "react";
import {
  useNavigate
} from "react-router-dom";
import http from "../../../../services/http.service";
import useAuth from "../../../../hooks/useAuth";
import CustomTable from "../../../../components/custom-table/CustomTable";
import ErrorResult from "../../../../components/error-result/ErrorResult";
import {
  formatDateTime
} from "../../../../shared/utils";
import {
  itemRender
} from "../../../../shared/helpers/breadcrumb";
import {
  DEFAULT_CUSTOM_TABLE_CONFIG
} from "../../../../constants";
import {
  RightOutlined
} from "@ant-design/icons";
import {
  PageHeader,
  Button,
  Tag,
  Space,
  Tooltip,
  Typography,
  Card
} from "antd";

const ShareholderEventHistoric = () => {

  const [error, setError] = useState(undefined);
  const [configTable, setConfigTable] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();
  const { user } = useAuth();

  const { Text } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/shareholders/events/historic?flag=0",
      breadcrumbName: "Eventos",
    },
    {
      path: "/pages/shareholders/events/historic",
      breadcrumbName: "Histórico",
    },
  ];

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre_evento",
      filterName: "nombre_evento",
      width: 180,
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      ellipsis: { showTitle: false },
      width: 170,
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "tipo_evento_d3",
      width: 200,
      render: (cell) => (
        <Tooltip title={cell?.nombre}>
          {cell?.nombre}
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_evento_d10",
      width: 130,
      render: (cell) => {
        if (cell === "PR") {
          return <Tag color="green">Programado</Tag>
        } else if (cell === "EN") {
          return <Tag color="blue">En Proceso</Tag>
        } else if (cell === "CA") {
          return <Tag color="volcano">Cancelado</Tag>
        } else if (cell === "CM") {
          return <Tag color="geekblue">Completado</Tag>
        } else if (cell === "BR") {
          return <Tag>Borrador</Tag>
        }
      }
    },
    {
      title: "Fecha",
      dataIndex: "inicio_plan_fh",
      filterName: "inicio_plan_fh",
      align: "center",
      width: 120,
      render: (_, row) => (
        (row.inicio_real_fh)
          ? <Text>{formatDateTime(row.inicio_real_fh)}</Text>
          : <Text>{formatDateTime(row.inicio_plan_fh)}</Text>
      )
    },
    {
      title: "Duración",
      dataIndex: "duracion_evento",
      width: 100,
      render: (cell) => (
        (cell)
          ? <Text>{parseFloat(cell).toFixed(2)} hrs</Text>
          : null
      )
    },
    {
      title: "",
      dataIndex: "id",
      width: 100,
      align: "center",
      render: (cell, row) => (
        <Space>
          {(row.status_evento_d10 === "CM" && row.confirmacion_asistencia_status_d11 === "CONFIRMADO") &&
            <Tooltip title={"Memorándum"}>
              <Button
                size={"small"}
                onClick={() => navigate(`/pages/shareholders/events/${cell}/memorandum`)}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<RightOutlined />}
              />
            </Tooltip>
          }
        </Space>
      ),
    },
  ];


  const getData = async (props = null) => {

    try {

      setError(undefined);

      let endpoint = `accionistas/${user?.shareholder?.id}/eventos/?status_evento_d10=CM`;
      let queryParams = ["status_evento_d10=CM"];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setConfigTable({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `accionistas/${user?.shareholder?.id}/eventos/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {

      setError(error);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      <PageHeader
        title='Histórico'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
      />
      {!error && (
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={configTable} />
          </div>
        </Card>
      )}
      {error && (
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  );

};

export default ShareholderEventHistoric;