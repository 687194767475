import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {

  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Lo sentimos, no pudimos encontrar la página."
      extra={
        <Button type="primary" onClick={() => navigate("/pages/dashboard")}>
          Salir
        </Button>
      }
    />
  )
}

export default NotFound