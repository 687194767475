import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorResult from "../../../components/error-result/ErrorResult";
import http from "../../../services/http.service";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { formatUTCSeconds } from "../../../shared/utils"
import {
  Card,
  PageHeader,
  Typography,
  Tooltip,
  Button,
  Table,
  notification,
  Modal
} from "antd";

const SettingsLiterax = () => {

  const [pageError, setPageError] = useState(undefined);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { Paragraph } = Typography;
  const { confirm } = Modal;

  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/settings/overview",
      breadcrumbName: "Configuraciones",
    },
    {
      path: "/pages/settings/literax",
      breadcrumbName: "Documentos Literax",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 80
    },
    {
      title: "Nombre",
      dataIndex: "name",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0">
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status",
      width: 90,
      render: (cell) => (
        <Tooltip title={cell?.key}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '90px'}}>
            {cell?.key}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (cell) => {
        const tags =  cell.map((tag) => tag.name).join(',');
        return (<Tooltip title={tags}>
          <Paragraph ellipsis className="my0">
            {tags}
          </Paragraph>
        </Tooltip>)
      },
    },
    {
      title: "Creado en",
      dataIndex: "createdAt",
      width: 120,
      render: (cell) => formatUTCSeconds(cell),
    },
    {
      title: "",
      dataIndex: "id",
      width: 75,
      align: "center",
      render: (cell) => (
        <Tooltip title={"Eliminar"}>
          <Button
            size={"small"}
            shape='circle'
            className="noBorderColor bgTransparent"
            icon={<DeleteOutlined />}
            onClick={() =>
              confirm({
                title: '¿Usted esta seguro?',
                icon: <ExclamationCircleOutlined />,
                content: `Se eleminará el documento con el ID ${cell}`,
                okText: 'Eliminar',
                okType: 'danger',
                cancelText: 'Cancelar',
                onOk() {
                  deleteDocument(cell)
                },
              })
            }
          />
        </Tooltip>
      ),
    },
  ];

  const deleteDocument = async (id) => {

    try {

      setLoading(true);

      let endpoint = `literax/doc/${id}/`;
  
      await http({ endpoint, method: "DELETE" });

      getData();

    } catch (error) {

      setLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });

    }

  };

  const getData = async (props = null) => {

    try {

      setPageError(undefined);
      setLoading(true);

      let endpoint = "literax/doc/";

      const response = await http({ endpoint });

      setDocuments(response);
      setLoading(false);

    } catch (error) {

      setPageError(error);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      <PageHeader
        title='Listado de documentos en Literax'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/settings")}
      />
      {!pageError && (
        <Card className="mt1">
          <div className="tableContainer">
            <Table dataSource={documents} columns={columns} loading={loading} />
          </div>
        </Card>
      )}
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  );
};

export default SettingsLiterax;