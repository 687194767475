import {
  useState,
  useRef,
  useEffect,
  useCallback
} from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import {
  STORAGE_TOKEN_NAME,
  STORAGE_CONFIG_NAME,
  COMMON_EMAIL_FORM_RULE,
  COMMON_REQUIRED_FORM_RULE,
  RECAPTCHA_SITE_KEY
} from "../../../constants";
import {
  Form,
  Input,
  Button,
  notification
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "../styles/Login.module.css";
import Logo from "../../../shared/img/login-logo.png";
import http from "../../../services/http.service";
import useAuth from "../../../hooks/useAuth";
import { getUserTimezone } from "../../../shared/utils";


const Login = () => {

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const reCaptchaRef = useRef();
  const [formStatus, setFormStatus] = useState(false);
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setUser } = useAuth();

  const onReCaptchaChange = (e) => {
    if (e) setToken(e);
  }

  const validateFormStatus = useCallback(() => {
    const emailErrors = form.getFieldError('email');
    const passwordErrors = form.getFieldError('password');
    const emailValue = form.getFieldValue('email');
    const passwordValue = form.getFieldValue('password');
    
    if (emailErrors.length > 0 || passwordErrors.length > 0 ) {
      setFormStatus(false);
    }
    if (emailErrors.length === 0 && passwordErrors.length === 0 && emailValue && passwordValue) {
      setFormStatus(true);
    }
  }, [form])

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const reCaptchaValue = await reCaptchaRef.current.getValue();
      let modules = {};
      const request = {
        endpoint: "token/",
        method: "POST",
        data: {...values, token: reCaptchaValue },
        needAuthorization: false
      };

      const response = await http(request);

      if (response.accionistas.length === 0) throw { message: "Aún no tienes participantes asignados." };

      sessionStorage.setItem(STORAGE_TOKEN_NAME, response.access);

      const moduleResponse = await http({ endpoint: "usuarios/modulos/" });

      moduleResponse.forEach((module) => modules[module] = true);
      const timezone = getUserTimezone();
      const shareholders = response?.accionistas.map(
        (shareholder) => {
          return {
            id: shareholder.id,
            name: shareholder.nombre_rs,
            type: shareholder.tipo_accionista_d2,
            rfc: shareholder.rfc_firma
          }
        }
      );

      let roles = {}

      response?.rol.forEach((role) => roles[role.rol] = true);

      const profile = {
        id: response?.id,
        firstName: response?.first_name,
        lastName: response?.last_name,
        email: response?.email,
        roles,
        timezone,
        shareholders,
        shareholder: (shareholders.length > 0) ? shareholders[0] : null,
        modules
      };

      const config = {
        shareholderId: profile?.shareholder?.id,
        timezone
      };

      sessionStorage.setItem(STORAGE_CONFIG_NAME, JSON.stringify(config));
  
      setUser(profile);
      setLoading(false);

      if (state && state.path && state.path !== "/") {
        navigate(state.path, { replace: true });
      } else {
        navigate("/pages/dashboard", { replace: true });
      }

    } catch (error) {
      setLoading(false);
      notification.error({ message: '¡Ups!', description: error.message});
    }
  };

  useEffect(
    () => {
      if (token) {
        validateFormStatus();
      }
    },
    [token, validateFormStatus]
  )

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img
          src={Logo}
          alt='intercam'
          className="animate__animated animate__fadeInLeft"
        />
      </div>
      <div className={styles.rightContainer}>
        <div className={`${styles.formContainer} animate__animated animate__fadeInRight`}>
          <h1 className={styles.title}>
            Iniciar Sesión
          </h1>
          <Form layout='vertical' form={form} onChange={() => validateFormStatus()} onFinish={onFinish}>
            <Form.Item
              label='Correo electrónico'
              name='email'
              className='mt2'
              rules={COMMON_EMAIL_FORM_RULE}
            >
              <Input type={"email"} tabIndex={1} />
            </Form.Item>
            <Form.Item
              label='Contraseña'
              name={"password"}
              className='mt2'
              style={{marginBottom: "0px"}}
              rules={COMMON_REQUIRED_FORM_RULE}
            >
              <Input type={"password"} tabIndex={2} />
            </Form.Item>
            <Button
              type="link"
              style={{ padding: "4px 0"}}
              tabIndex={0}
              onClick={() => navigate("/auth/forgot/password")}
            >
              ¿Olvidaste la contraseña?
            </Button>
            <Form.Item className={"mt2"}>
              <Button
                type='primary'
                htmlType='submit'
                className='w100'
                tabIndex={4}
                disabled={!token || !formStatus}
                loading={loading}
              >
                {!loading && <span>Ingresar</span>}
                {loading && <span>Autenticando</span>}
              </Button>
            </Form.Item>
            <div className={styles.recaptchaContainer}>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                hl="es"
                tabIndex={3}
                onChange={onReCaptchaChange}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
