import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { itemRender } from '../../../shared/helpers/breadcrumb';
import GeneralForm from "../forms/CompanyGeneralForm";
import StockForm from "../forms/CompanyStocksForm";
import ShareholderForm from "../forms/CompanyShareholdersForm";
import { CompanyFormProvider } from "../../../context/CompanyFormProvider";
import http from "../../../services/http.service";
import useCompanyForm from "../../../hooks/useCompanyForm";
import {
  PageHeader,
  Steps,
  Card,
  notification
} from 'antd';

const CompanyCreate = () => {
  const { Step } = Steps;
  const [step, setStep] = useState(0);
  const [draft, setDraft] = useState(null);
  const [draftAssigned, setDraftAssigned] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: '/pages/companies/create',
      breadcrumbName: 'Nueva',
    }
  ];

  const onSave = async (values) => {
    try {
      let request = {
        endpoint: "empresas-formulario/1/",
        method: "PATCH",
        data: { data: values }
      };

      await http(request);
      
      notification.success({ message: '¡Correcto!', description: 'Cambios guardados correctamente.' });

      return Promise.resolve();
    } catch(error) {
      notification.error({ message: '¡Ups!', description: error.message });
      return Promise.reject(error.message);
    }
  };

  const onFinish = async (values) => {
    try {
      let request = {
        endpoint: "empresas/",
        method: "POST",
        data: values
      };

      await http(request);
      
      notification.success({ message: '¡Correcto!', description: 'Compañía guardada correctamente.' });
      navigate('/pages/companies/overview');
    } catch(error) {
      notification.error({ message: '¡Ups!', description: error.message });
      return Promise.reject(error.message);
    }
  };

  const getDraft = async () => {
    try {
      const response = await http({ endpoint: `empresas-formulario/1/` });
      setDraft(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(
    () => {
      getDraft();
    },
    [])

  return (
    <CompanyFormProvider>
      <PageHeader
        title="Nueva Compañía"
        onBack={() => navigate("/pages/companies/overview")}
        breadcrumb={{ routes, itemRender }}
      />
      <Card className="mt1">
        <Steps current={step} size="small" className="mb3">
          <Step title="General" />
          <Step title="Acciones" />
          <Step title="Participantes" />
        </Steps>
          {step === 0 && !loading && (
            <GeneralForm
              onSave={onSave}
              setStep={setStep}
              draft={draft}
              from={"create"}
              draftAssigned={draftAssigned}
              setDraftAssigned={setDraftAssigned}
            />
          )}
          {step === 1 && !loading && <StockForm onSave={onSave} setStep={setStep} from={"create"}  />}
          {step === 2 && !loading && <ShareholderForm setStep={setStep} onSave={onSave} from={"create"} onFinish={onFinish} />}
      </Card>
    </CompanyFormProvider>
  )
}

export default CompanyCreate;