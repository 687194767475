import { Spin } from "antd";

const PageLoader = () => {
  return (
    <div className="pageLoader">
      <Spin size="large" />
    </div>
  )
}

export default PageLoader