import {
  useNavigate
} from "react-router-dom";
import {
  Button,
  Result
} from "antd";
import { useEffect, useState } from "react";
// Props
// {
//   message: "Error message"
//   onRetry: () => false,
//   exitPath: "/pages/dashboard"
// }
const ErrorResult = (props) => {
  const navigate = useNavigate();
  const [extras, setExtras] = useState([])

  useEffect(
    () => {
      let buttons = []
      if (props.exitPath !== undefined) {
        buttons.push(<Button key={"exitBtn"} style={{ width: "100px"}} onClick={() => navigate(props.exitPath)}>Salir</Button>)
      }
      if (props.onRetry !== undefined) {
        buttons.push(<Button key={"retryBtn"} style={{ width: "100px"}} onClick={props?.onRetry} type='primary'>Reintentar</Button>)
      }
      setExtras(buttons)
    },
    [props]
  )
  return (
    <>
      <Result
        status='500'
        title='¡Ups!'
        subTitle={`${props?.message}`}
        extra={extras}
      />
    </>
  )
}

export default ErrorResult;