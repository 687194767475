import { Routes, Route } from "react-router-dom";

import Login from "../pages/auth/login/Login";
import ForgotPassword from "../pages/auth/forgot-password/ForgotPassword";
import ResetPassword from "../pages/auth/reset-password/ResetPassword";

export const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="forgot/password" element={<ForgotPassword />} />
        <Route path="reset/password" element={<ResetPassword />} />
      </Routes>
    </>
  );
};
