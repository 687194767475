import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/ForgotPassword.module.css";
import http from "../../../services/http.service";
import { COMMON_EMAIL_FORM_RULE } from "../../../constants";
import {
  Form,
  Input,
  Button,
  notification,
  Card,
  Typography
} from "antd";

const ForgotPassword = () => {

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { Title, Text } = Typography;

  const onFinish = async (values) => {

    try {

      await form.validateFields();

      setLoading(true);

      const request = {
        endpoint: "usuarios/forgot-password/",
        method: "POST",
        data: values,
        needAuthorization: false
      };

      await http(request);

      setLoading(false);

      notification.success({
        message: "¡Correcto!",
        description: "Correo de recuperación enviado correctamente.",
      });

      navigate("/auth/login");

    } catch (error) {

      if (error?.errorFields) return false;

      setLoading(false);

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  return (
    <div className={styles.container}>
      <Card className={styles.formCard}>
        <Title level={3}>
          Recuperar Contraseña
        </Title>
        <Text type="secondary">
          Favor ingresa tu correo electrónico para poder continuar con el proceso de restablecimiento.
        </Text>
        <Form
          layout='vertical'
          className="mt2"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name='email'
            rules={COMMON_EMAIL_FORM_RULE}
            hasFeedback
          >
            <Input
              type={"email"}
              placeholder={"usuario@intercam.com.mx"}
            />
          </Form.Item>
          <div className={styles.actionContainer}>
            <Button
              type='default'
              htmlType='button'
              className={styles.actionBtn}
              onClick={() => navigate("/auth/login")}
            >
              Salir
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              className={styles.actionBtn}
            >
              {!loading && <span>Enviar</span>}
              {loading && <span>Enviando</span>}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
