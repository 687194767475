import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import http from '../../../../../services/http.service';
import { ActionButton, ConfirmationButton, ErrorResult, PageLoader } from '../../../../../components';
import { itemRender } from "../../../../../shared/helpers/breadcrumb";
import { getUniqueKey, maskBigNumber, maskPercentage } from '../../../../../shared/utils';
import { EditOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Typography,
  Tooltip,
  Card,
  Button,
  notification,
  Table,
  Descriptions,
  Modal,
  Form,
  InputNumber,
  Select
} from 'antd';
import { COMMON_REQUIRED_FORM_RULE, DEFAULT_MODAL_STATE, INPUT_NUMBER_FORMAT } from '../../../../../constants';

const CompanyIncreaseStocksDetails = () => {
  const navigate = useNavigate();
  const { companyId, stockDraftId } = useParams();
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);

  const [company, setCompany] = useState(undefined);
  const [draft, setDraft] = useState(undefined);
  const [request, setRequest] = useState({});
  const [summary, setSummary] = useState({});
  const [shareholders, setShareholders] = useState([]);
  const [availableShareholders, setAvailableShareholders] = useState([]);
  const [shareholdersCatalog, setShareholdersCatalog] = useState([]);
  const [selectedShareholder, setSelectedShareholder] = useState(undefined);

  const [stockModalState, setStockModalState] = useState(DEFAULT_MODAL_STATE);
  const [shareholderModalState, setShareholderModalState] = useState(DEFAULT_MODAL_STATE);

  const [stockForm] = Form.useForm();
  const [shareholderForm] = Form.useForm();

  const { Text } = Typography;
  const { Option } = Select;
  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${companyId}/details`,
      breadcrumbName: `${company?.nombre_corto}`,
    },
    {
      path: `/pages/companies/${companyId}/increase-in-capital`,
      breadcrumbName: 'Aumento de capital',
    },
    {
      path: `/pages/companies/${companyId}/increase-in-capital/${stockDraftId}/details`,
      breadcrumbName: 'Detalles',
    }
  ];

  const columns = [
    {
      title: 'Accionista',
      dataIndex: 'nombre_rs',
      align: 'center',
      ellipsis: { showTitle: false },
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: 'Participación',
      dataIndex: 'porcentaje_participacion',
      align: 'center',
      render: (cell) => (
        <Text>
          {maskPercentage(cell)}
        </Text>
      )
    },
    {
      title: 'Inicio',
      dataIndex: 'posicion_inicial',
      align: 'center',
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: 'Asignación',
      dataIndex: 'posicion_aumento',
      align: 'center',
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: 'Final',
      dataIndex: 'posicion_final',
      align: 'center',
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: 'Participación',
      dataIndex: 'porcentaje_participacion_final',
      align: 'center',
      render: (cell) => (
        <Text>
          {maskPercentage(cell)}
        </Text>
      )
    },
    {
      title: '',
      dataIndex: 'posicion_aumento',
      align: 'center',
      width: 80,
      render: (cell, row) => (
        <Tooltip title={"Editar"}>
          <Button
            size={"small"}
            shape="circle"
            className="noBorderColor bgTransparent"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedShareholder(row);
              stockForm.setFieldsValue({ quantity:  cell });
              setStockModalState({ visible: true, loading: false });
            }}
          />
        </Tooltip>
      )
    },
  ];

  const getCompany = async () => {
    try {
      const response = await http({ endpoint: `empresas/${companyId}/posicion-actual/` });
      setCompany(response);
    } catch (error) {
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const getShareholders = async () => {
    try {
      const response = await http({ endpoint: `accionistas/?status_d1=A&no_page=true&ordering=nombre_rs` });
      setShareholdersCatalog(response);
    } catch (error) {
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const onAddShareholderClick = () => {
    const values = shareholderForm.getFieldsValue();
    const selected = shareholdersCatalog.find((item) => item.id === values.shareholder);
    const items = [...shareholders];
    items.push({
      accionista: values.shareholder,
      nombre_rs: selected?.nombre_rs,
      porcentaje_participacion: 0,
      posicion_aumento: values.quantity,
      posicion_final: values.quantity,
      posicion_inicial: 0
    });

    const params = { ...draft, aumento_accionistas: items }
    calculate(params);

    shareholderForm.resetFields();
    setShareholderModalState(DEFAULT_MODAL_STATE);
  }

  const onUpdateModalClick = () => {
    const values = stockForm.getFieldsValue();
    const items = shareholders.map((shareholder) => {
      let response = { ...shareholder };
      if (shareholder.accionista === selectedShareholder.accionista) {
        response.posicion_aumento = values.quantity;
        response.posicion_final = response.posicion_inicial + values.quantity;
      }
      return response;
    });

    const params = { ...draft, aumento_accionistas: items }

    calculate(params);

    stockForm.resetFields();
    setStockModalState(DEFAULT_MODAL_STATE);
    setSelectedShareholder(undefined);
  }

  const openNewShareholderModal = () => {
    const available = shareholdersCatalog.filter((current) => !shareholders.some((item) => item.accionista === current.id));
    setAvailableShareholders(available)
    setShareholderModalState({ visible: true, loading: false });
  }

  const onCancelModalClick = () => {
    stockForm.resetFields();
    shareholderForm.resetFields();
    setStockModalState(DEFAULT_MODAL_STATE);
    setShareholderModalState(DEFAULT_MODAL_STATE);
    setSelectedShareholder(undefined);
  }

  const calculate = (response) => {
    let currentTotal = 0;
    let lastPositionQuantity = 0;
    let lastPositionPercent = 0;
    let totalAssigned = 0;
    let currentPositionQuantity = 0;
    let currentPositionPercent = 0;
    let pending = 0;

    response.aumento_accionistas.forEach((item) => {
      lastPositionQuantity += item.posicion_final;
      currentTotal += item.total_acciones_empresa_inicial;
      totalAssigned += item.posicion_aumento;
      currentPositionQuantity += item.posicion_inicial;
      currentPositionPercent += item.porcentaje_participacion;
    });

    const total = currentTotal + response.criterios_num_acciones;
    const shareholdersMapped = response.aumento_accionistas.map((item) => {
      const stocks = item.total_acciones_empresa_inicial + item.posicion_aumento;
      return ({
        ...item,
        porcentaje_participacion_final: (stocks / total) * 100
      })
    });

    shareholdersMapped.forEach((shareholder) => {
      lastPositionPercent += shareholder.porcentaje_participacion_final;
    });

    pending = response.criterios_num_acciones - totalAssigned;

    setSummary({ lastPositionQuantity, totalAssigned, currentPositionPercent, currentPositionQuantity, lastPositionPercent, pending });
    setShareholders(shareholdersMapped);
    setDraft(response);

    const newRequest = {
      endpoint: `empresas/${companyId}/aumento-capital/${stockDraftId}/`,
      method: 'PATCH',
      data: {
        criterios_serie_clase: response.criterios_serie_clase,
        criterios_num_acciones: response.criterios_num_acciones,
        criterios_algoritmo: response.criterios_algoritmo,
        aplicado: false,
        aumento_accionistas: shareholdersMapped
      }
    };

    setRequest(newRequest);
  }

  const getData = async () => {
    try {
      setPageError(undefined);

      let endpoint = `empresas/${companyId}/aumento-capital/${stockDraftId}/`;

      const response = await http({ endpoint });

      calculate(response)
      setPageLoading(false);
    } catch(error) {
      setPageError(error);
      setPageLoading(false);
    }
  };

  const pageHeaderExtras = ([
    <Button
      key={getUniqueKey()}
      size={"small"}
      onClick={openNewShareholderModal}
      disabled={shareholdersCatalog.length === 0}
    >
      Nuevo Accionista
    </Button>,
    <ActionButton
      key={getUniqueKey()}
      size={"small"}
      shape={"default"}
      className={"default"}
      value={"Guardar Borrador"}
      request={request}
    />,
    <ConfirmationButton
      key={getUniqueKey()}
      size={"small"}
      shape={"default"}
      className={"default"}
      value={"Aplicar"}
      confirmContent={'A continuación se realizará el aumento de capital.'}
      request={{ ...request, data: { ...request.data, aplicado: true } }}
      disabled={summary.pending !== 0}
      onFinish={() => navigate(`/pages/companies/${companyId}/details`)}
    />
  ]);

  const getTableSummary = () => {
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align={"center"}>
            <strong>
              {maskPercentage(summary.currentPositionPercent)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align={"center"}>
            <strong>
              {maskBigNumber(summary.currentPositionQuantity)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align={"center"}>
            <strong>
              {maskBigNumber(summary.totalAssigned)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align={"center"}>
            <strong>
              {maskBigNumber(summary.lastPositionQuantity)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align={"center"}>
            <strong>
              {maskPercentage(summary.lastPositionPercent)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align={"center"}>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align={"center"}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align={"center"}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align={"center"}>
            <strong>
              {maskBigNumber(summary.pending)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align={"center"}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align={"center"}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align={"center"}>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  useEffect(
    () => {
      getData();
      getCompany();
      getShareholders()
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && draft &&
        <>
          <PageHeader
            title={`Aumento de capital`}
            subTitle={draft.status}
            onBack={() => navigate(`/pages/companies/${companyId}/increase-in-capital`)}
            breadcrumb={{ routes, itemRender }}
            extra={(draft.status === "BORRADOR") ? pageHeaderExtras : []}
          >
             <Descriptions size="small" column={{ xs: 1, sm: 3 }}>
              <Descriptions.Item label="Serie / Clase">
                {draft?.criterios_serie_clase}
              </Descriptions.Item>
              <Descriptions.Item label="Nuevas Acciones">
                {maskBigNumber(draft?.criterios_num_acciones)}
              </Descriptions.Item>
              <Descriptions.Item label="Asignación por">
                {draft?.criterios_algoritmo === "TENENCIA" && "Por % accionario"}
                {draft?.criterios_algoritmo === "MANUAL" && "Manual"}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card className={"mt1"}>
            <div className="tableContainer">
              <Table
                columns={columns}
                dataSource={shareholders}
                size="small"
                rowKey={"accionista"}
                summary={getTableSummary}
              />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={`/pages/companies/${companyId}/increase-in-capital`}
        />
      }
      <Modal
        title={"Nueva Asignación"}
        visible={stockModalState.visible}
        onOk={onUpdateModalClick}
        confirmLoading={stockModalState.loading}
        onCancel={onCancelModalClick}
      >
        <Form layout='vertical' form={stockForm}>
          <Form.Item
            label="Cantidad"
            name="quantity"
            rules={COMMON_REQUIRED_FORM_RULE}
            hasFeedback
          >
            <InputNumber
              className={"w100"}
              formatter={value => maskBigNumber(value)}
              parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
              min={0}
              controls={false}
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={"Nuevo Accionista"}
        visible={shareholderModalState.visible}
        onOk={onAddShareholderClick}
        confirmLoading={shareholderModalState.loading}
        onCancel={onCancelModalClick}
      >
        <Form layout='vertical' form={shareholderForm}>
          <Form.Item
            label="Nombre"
            name="shareholder"
            rules={COMMON_REQUIRED_FORM_RULE}
            hasFeedback
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => `${option.key}`.toLowerCase().includes(input.toLowerCase())}
            >
              {availableShareholders.map(({id, nombre_rs}) => (
                <Option key={`${nombre_rs}-${id}`} value={id}>
                  {nombre_rs}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Cantidad"
            name="quantity"
            rules={COMMON_REQUIRED_FORM_RULE}
            hasFeedback
          >
            <InputNumber
              className={"w100"}
              formatter={value => maskBigNumber(value)}
              parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
              min={0}
              controls={false}
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CompanyIncreaseStocksDetails;