import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { formatDateTime } from "../../../shared/utils";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { DEFAULT_CUSTOM_TABLE_CONFIG, DEFAULT_MODAL_STATE } from "../../../constants";
import {
  PageHeader,
  Button,
  Tag,
  Space,
  Tooltip,
  Modal,
  Typography,
  notification,
  Card
} from "antd";
import {
  PlaySquareOutlined,
  TeamOutlined,
  DeleteOutlined,
  EditOutlined,
  RightOutlined
} from "@ant-design/icons";

const EventOverview = () => {

  const [pageError, setPageError] = useState(undefined);
  const [deleteModalState, setDeleteModalState] = useState(DEFAULT_MODAL_STATE);
  const [startModalState, setStartModalState] = useState(DEFAULT_MODAL_STATE);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();

  const { Text } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      filterName: "id",
      width: 100
    },
    {
      title: "Compañía",
      dataIndex: "empresa",
      filterName: "id",
      search: true,
      ellipsis: { showTitle: false },
      width: 170,
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "tipo_evento_d3",
      filterName: "tipo_evento_d3",
      width: 200,
      render: (cell) => (
        <Tooltip title={cell?.nombre}>
          {cell?.nombre}
        </Tooltip>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre_evento",
      ellipsis: { showTitle: false },
      width: 180,
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Fecha",
      dataIndex: "inicio_plan_fh",
      filterName: "inicio_plan_fh",
      align: "center",
      width: 120,
      render: (_, row) => (
        (row.inicio_real_fh)
          ? <Text>{formatDateTime(row.inicio_real_fh)}</Text>
          : <Text>{formatDateTime(row.inicio_plan_fh)}</Text>
      )
    },
    {
      title: "Duración",
      dataIndex: "duracion_evento",
      width: 100,
      align: "center",
      render: (cell) => (
        (cell)
          ? <Text>{parseFloat(cell).toFixed(2)} hrs</Text>
          : null
      )
    },
    {
      title: "Estatus",
      dataIndex: "status_evento_d10",
      filterName: "status_evento_d10",
      align: "center",
      width: 120,
      render: (cell) => {
        if (cell === "PR") {
          return <Tag color="green">Programado</Tag>
        } else if (cell === "EN") {
          return <Tag color="blue">En Proceso</Tag>
        } else if (cell === "CA") {
          return <Tag color="volcano">Cancelado</Tag>
        } else if (cell === "CM") {
          return <Tag color="geekblue">Completado</Tag>
        } else if (cell === "BR") {
          return <Tag>Borrador</Tag>
        }
      }
    },
    {
      title: "",
      dataIndex: "id",
      width: 150,
      align: "center",
      render: (cell, row) => (
        <Space size={"small"}>
          {row.status_evento_d10 === "PR" &&
            <Tooltip title={"Iniciar"}>
              <Button
                size={"small"}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<PlaySquareOutlined />}
                onClick={() => {
                  setSelectedEvent(row);
                  setStartModalState({visible: true, loading: false});
                }}
              />
            </Tooltip>
          }
          <Tooltip title={"Asistentes"}>
            <Button
              size={"small"}
              onClick={() => navigate(`/pages/events/${cell}/guests`)}
              shape='circle'
              className="noBorderColor bgTransparent"
              icon={<TeamOutlined />}
            />
          </Tooltip>
          {(row.status_evento_d10 === "PR" || row.status_evento_d10 === "BR") &&
            <Tooltip title={"Editar"}>
              <Button
                size={"small"}
                onClick={() => navigate(`/pages/events/${cell}/update`)}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<EditOutlined />}
              />
            </Tooltip>
          }
          {(row.status_evento_d10 === "PR" || row.status_evento_d10 === "BR") &&
            <Tooltip title={"Cancelar"}>
              <Button
                size={"small"}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<DeleteOutlined />}
                onClick={() => {
                  setSelectedEvent(row);
                  setDeleteModalState({visible: true, loading: false});
                }}
              />
            </Tooltip>
          }
          {row.status_evento_d10 === "EN" &&
            <Tooltip title={"Administrar"}>
              <Button
                size={"small"}
                className="noBorderColor bgTransparent"
                onClick={() => navigate(`/pages/events/${cell}/manage`)}
                shape='circle'
                icon={<RightOutlined />}
              />
            </Tooltip>
          }
          {row.status_evento_d10 === "CM" &&
            <Tooltip title={"Memorándum"}>
              <Button
                size={"small"}
                className="noBorderColor bgTransparent"
                onClick={() => navigate(`/pages/events/${cell}/memorandum`)}
                shape='circle'
                icon={<RightOutlined />}
              />
            </Tooltip>
          }
        </Space>
      ),
    },
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = "eventos/?ordering=-id&limit=10&offset=0";
      let queryParams = [
        "ordering=-id",
        "limit=10",
        "offset=0"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "eventos/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {

      setPageError(error);

    }

  };

  const onStartClick = async () => {

    try {

      setStartModalState({visible: true, loading: true });
      // const diffMinutes = getDiffDateTimes(selectedEvent?.inicio_plan_fh);

      // if (diffMinutes > 10) {

      //   notification.info({
      //     message: "Información",
      //     description: "El evento se puede iniciar con máximo 10 minutos de anticipación.",
      //   });

      // } else {

        const request = {
          method: "PATCH",
          endpoint: `eventos/${selectedEvent?.id}/status/`,
          data: {
            status: "EN"
          }
        };
  
        await http(request);

        setStartModalState(DEFAULT_MODAL_STATE);

        navigate(`/pages/events/${selectedEvent?.id}/manage`);

      // }

    } catch (error) {

      setStartModalState(DEFAULT_MODAL_STATE);
      setSelectedEvent(undefined);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  }

  const onDeleteClick = async () => {

    try {

      setDeleteModalState({visible: true, loading: true});

      const request = {
        method: "DELETE",
        endpoint: `eventos/${selectedEvent?.id}/`
      };

      await http(request);

      setSelectedEvent(undefined);
      setDeleteModalState(DEFAULT_MODAL_STATE);

      getData();

      notification.success({
        message: "¡Correcto!",
        description: "Evento eliminado correctamente.",
      });

    } catch (error) {

      setSelectedEvent(undefined);
      setDeleteModalState(DEFAULT_MODAL_STATE);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const pageHeaderExtras = (
    <Button
      onClick={() => navigate("/pages/events/create")}
      type='primary'
      size={"small"}
      ghost
    >
      Nuevo
    </Button>
  );

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      <PageHeader
        title='Eventos Corporativos'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
        extra={pageHeaderExtras}
      />
      {!pageError && (
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={customTableConfig} />
          </div>
        </Card>
      )}
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
      <Modal
        title="Confirmación"
        visible={startModalState.visible}
        onOk={() => onStartClick()}
        confirmLoading={startModalState.loading}
        maskClosable={false}
        onCancel={() => {
          setSelectedEvent(undefined);
          setStartModalState(DEFAULT_MODAL_STATE);
        }}
      >
        <Space direction="vertical">
          <div>
            <Text>
              Estará iniciando el siguiente evento:
            </Text>
          </div>
          <div>
            <Text strong>
              Compañía:
            </Text>
            <Text className="ml1">
              {selectedEvent?.empresa}
            </Text>
          </div>
          <div>
            <Text strong>
              Duración:
            </Text>
            <Text className="ml1">
              {selectedEvent?.duracion_evento} hrs
            </Text>
          </div>
          <div>
            <Text strong>
              Nombre:
            </Text>
            <Text className="ml1">
              {selectedEvent?.nombre_evento}
            </Text>
          </div>
          <div>
            <Text strong>
              Fecha y Hora:
            </Text>
            <Text className="ml1">
              {formatDateTime(selectedEvent?.inicio_plan_fh)}
            </Text>
          </div>
        </Space>
      </Modal>
      <Modal
        title="Confirmación"
        visible={deleteModalState.visible}
        onOk={() => onDeleteClick()}
        confirmLoading={deleteModalState.loading}
        maskClosable={false}
        onCancel={() => {
          setDeleteModalState(DEFAULT_MODAL_STATE);
          setSelectedEvent(undefined);
        }}
      >
        <Text>
          Se cancelará el evento {selectedEvent?.nombre_evento} al confirmar.
        </Text>
      </Modal>
    </>
  );

};

export default EventOverview;