import { AuthProvider } from "./context/AuthProvider";
import { AppRouter } from "./router/AppRouter";
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import moment from 'moment-timezone';
import 'moment/locale/es-mx';

moment.locale('es');
moment.tz("America/Mexico_City");

function App() {

  return (
    <>    
      <AuthProvider>
        <ConfigProvider locale={esES}>
          <AppRouter />
        </ConfigProvider>
      </AuthProvider>
    </>
  );
}

export default App;
