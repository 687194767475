import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { TransactionForm } from "../form/TransactionForm";
import { PageHeader, Button } from "antd";

const TransactionCreate = () => {

  const [isSubmitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/transactions/overview",
      breadcrumbName: "Transacciones",
    },
    {
      path: "/pages/transactions/create",
      breadcrumbName: "Nueva Transacción",
    },
  ];

  return (
    <>
      <PageHeader
        title='Nueva Transacción'
        onBack={() => navigate("/pages/transactions/overview")}
        breadcrumb={{ routes, itemRender }}
        extra={
          <Button
            type='primary'
            ghost
            size="small"
            loading={isSubmitted}
            onClick={() => setSubmitted(true)}
          >
            {!isSubmitted && <span>Crear</span>}
            {isSubmitted && <span>Creando</span>}
          </Button>
        }
      />
      <TransactionForm
        parentName={"create"}
        isSubmitted={isSubmitted}
        setSubmitted={setSubmitted}
      />
    </>
  );
};

export default TransactionCreate;