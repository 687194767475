import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import { notification, Card, Modal, Typography, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PageLoader from "../../../components/page-loader/PageLoader";
import {
  LITERAX_PASSWORD,
  LITERAX_SESSION_URL,
  LITERAX_USERNAME
} from "../../../constants";

const Literax = () => {

  const navigate = useNavigate();
  const [listeners, setListeners] = useState(false);
  const [token, setToken] = useState(null);
  const [values, setValues] = useState(null);
  const [isWidgetLoading, setWidgetLoading] = useState(false);
  const { Text } = Typography;

  const observer = new PerformanceObserver((list) => {
    list.getEntries().forEach((entry) => {
      // if (entry.name.includes('/api/ws/v1/sign_request/signatures')) setWidgetLoading(false);
      if (entry.name.includes('/api/ws/v1/documents')) setWidgetLoading(false);
    });
  });
  

  observer.observe({ type: 'resource', buffered: true });

  const notifyBackend = async () => {
    if (values) {
      const { transactionId, messageId, transactionDetailId } = values;
      const request = {
        endpoint: `transacciones/${transactionId}/registro-envio-firma-digital/`,
        method: "POST",
        data: {
          mensaje_id: messageId,
          detalle_transaccion_id: transactionDetailId
        }
      };

      await http(request).catch((error) => console.error(error));
    }

    notification.success({
      message: "¡Correcto!",
      description: "Solicitud de firma enviada correctamente.",
    });

    navigate(values.goBack);
  }

  const initListeners = () => {
    setListeners(true);
    setWidgetLoading(true);

    const widget = document.getElementById("signatureWidget");

    widget.addEventListener("signatureDone", (_) => notifyBackend());
    
    widget.addEventListener("signatureError", (e) => {
      const error = e?.detail?.error?.error;
      const description = (error && error.title) ? error.title : "Parece que algo salió mal, favor de contactar a soporte técnico.";
      notification.error({ message: '¡Ups!', description });
    });
  }

  const getAccessToken = async () => {
    try {
      const url = LITERAX_SESSION_URL;
      const init = {
        method: "POST",
        headers:{ 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: LITERAX_USERNAME,
          password: LITERAX_PASSWORD
        })
      };

      const response = await fetch(url, init);
      const data = await response.json();

      setToken(data);
    } catch (error) {
      notification.error({
        message: '¡Ups!',
        description: error.message,
      });
    }
  };

  useEffect(
    () => {
      const currentUrl = new URL(window.location.href);
      const queryParams = currentUrl.searchParams;
      const rfc = queryParams.get('rfc');
      const operationId = queryParams.get('operationId');
      const transactionId = queryParams.get('transactionId') || '';
      const transactionDetailId = queryParams.get('transactionDetailId') || '';
      const messageId = queryParams.get('messageId') || '';
      const goBack = queryParams.get('goBack') || '/pages/dashboard';
      let tags = '';

      if (transactionDetailId) tags = `transaction_detail_id:${transactionDetailId}`;
      if (transactionId) tags = `transaction_id:${transactionId}`;

      setValues({ rfc, operationId, transactionDetailId, transactionId, messageId, tags, goBack });
      getAccessToken()
    },
    []
  );

  useEffect(
    () => {
      if (token && !listeners) initListeners();
    },
    [token, listeners]
  );

  return (
    <>
      {!token && <PageLoader />}
      {token && values &&
        <>
          <Alert className="mt1" message="Simplemente haga un clic en el botón de firmar, incluso si no parece que esta cargando, su solicitud ya se estará procesando." type="info" showIcon />
          <Card className="mt1">
            <preview-signature
              id="signatureWidget"
              access-token={token.access_token}
              refresh={token.refresh_token}
              rfc={values.rfc}
              info="false"
              operation-id={values.operationId}
              tags={values.tags}
              info-and-signers="false"
              quick-signature="false"
              show-sign="true"
              lang="es"
            />
          </Card>
        </>
      }
      <Modal
        title="Cargando"
        visible={isWidgetLoading}
        maskClosable={false}
        footer={null}
        closable={false}
        centered
      >
        <LoadingOutlined />
        <Text className="ml1">
          Espere un momento por favor ...
        </Text>
      </Modal>
    </>
  );
};

export default Literax;