import {
  useEffect,
  useState,
  useCallback,
  useRef
} from "react";
import { useNavigate } from "react-router-dom";
import update from "immutability-helper";
import moment from "moment-timezone";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  notification,
  DatePicker,
  Radio,
  Card,
  Table,
  Tag,
  Space,
  Modal,
  Upload
} from "antd";
import {
  MenuOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  UploadOutlined
} from "@ant-design/icons";
import http from "../../../services/http.service";
import {
  DownloadButton,
  PageLoader,
  ErrorResult
} from "../../../components";
import {
  getBeforeDateTime,
  getSanitizedFileName,
  getUniqueKey
} from "../../../shared/utils";
import {
  DndProvider,
  useDrag,
  useDrop
} from "react-dnd";
import {
  DATETIME_FORMAT,
  COMMON_REQUIRED_FORM_RULE,
  COMMON_ACCEPT_FILE_FORMATS,
  COMMON_ALPHA_NUMERIC_FORM_RULE
} from "../../../constants";

const type = "DraggableBodyRow";

const EventForm = ({ parentName, isSubmitted, setSubmitted, event }) => {
  const [form] = Form.useForm();
  const [agendaForm] = Form.useForm();
  const [guestForm] = Form.useForm();
  const [isPageLoading, setPageLoading] = useState(true);
  const [isAgendaModalVisible, setAgendaModalVisible] = useState(false);
  const [isGuestModalVisible, setGuestModalVisible] = useState(false);
  const [error, setError] = useState(undefined);
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [selectedMode, setSelectedMode] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [guests, setGuests] = useState([]);
  const [shareholders, setShareholders] = useState([]);
  const [types, setTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const navigate = useNavigate();

  const { Option } = Select;

  const getNotificationDateTime = (value, date) => {
    switch(value) {
      case "1":
        return {
          cantidad: 5,
          unidad: "minutes",
          opcion: 1,
          fecha_a_notificar: getBeforeDateTime(5, "minutes", date)
        }
      case "2":
        return {
          cantidad: 30,
          unidad: "minutes",
          opcion: 2,
          fecha_a_notificar: getBeforeDateTime(30, "minutes", date)
        }
      case "3":
        return {
          cantidad: 1,
          unidad: "hours",
          opcion: 3,
          fecha_a_notificar: getBeforeDateTime(1, "hours", date)
        }
      case "4":
        return {
          cantidad: 1,
          unidad: "days",
          opcion: 4,
          fecha_a_notificar: getBeforeDateTime(1, "days", date)
        }
      case "5":
        return {
          cantidad: 1,
          unidad: "weeks",
          opcion: 5,
          fecha_a_notificar: getBeforeDateTime(1, "weeks", date)
        }
      case "6":
        return {
          cantidad: 1,
          unidad: "months",
          opcion: 6,
          fecha_a_notificar: getBeforeDateTime(1, "months", date)
        }
      default:
        return ""
    }
  }

  const onSubmitClick = async () => {
    try {
      if (isSubmitted.clicked === false) return;

      await form.validateFields();

      if (isSubmitted.button === "create") {
        if (activities.length === 0) throw Error({ message: "Al menos 1 actividad en la agenda se requiere en la lista." });
        if (guests.length === 0) throw Error({ message: "Al menos 1 asistente se requiere en la lista." });
      }

      let selectedActivities = [];
      let activityOrder = 0;

      for await (let activity of activities) {
        activityOrder++;

        if (activity.file && activity.isNewFile) {
          const formData = new FormData();
          const fileNameSanitized = getSanitizedFileName(activity.file.name);

          formData.append("file", activity.file);
          formData.append("path", "/eventos");
          formData.append("filename", fileNameSanitized);

          const request = {
            endpoint: "upload/",
            method: "POST",
            data: formData
          };

          const response = await http(request);

          selectedActivities.push({
            orden: activityOrder,
            descripcion: activity.name,
            se_vota: activity.hasVoting,
            status_punto_agenda_d12: "a",
            nombre_archivo_anexo: response.nombre_archivo,
            referencia_s3_archivo: response.referencia_s3_archivo,
          });
        } else {
          selectedActivities.push({
            orden: activityOrder,
            descripcion: activity.name,
            se_vota: activity.hasVoting,
            status_punto_agenda_d12: "a",
            nombre_archivo_anexo: activity.file.name,
            referencia_s3_archivo: activity.bucketPath
          });
        }
      }

      const notifications = form.getFieldValue("notifications") || [];
      const startDatetime = form.getFieldValue("startDatetime");
      const selectedNotifications = notifications.map((notification) => getNotificationDateTime(notification, startDatetime));
      const selectedShareholders = guests.map((guest) => { return { accionista: guest.id} });

      const payload = {
        empresa: form.getFieldValue("company"),
        nombre_evento: form.getFieldValue("name"),
        tipo_evento_d3: form.getFieldValue("type"),
        status_evento_d10: (isSubmitted.button === "create") ? "PR" : "BR",
        inicio_plan_fh: startDatetime.toISOString(),
        duracion_evento: form.getFieldValue("duration"),
        virtual_presencial: (form.getFieldValue("mode")) ? true : false,
        ubicacion: form.getFieldValue("location") || "",
        plataforma: form.getFieldValue("platform") || "",
        link: form.getFieldValue("link") || "",
        agenda: selectedActivities,
        notificaciones: selectedNotifications,
        asistentes: selectedShareholders
      };

      if (parentName === "create") {
        const request = {
          endpoint: "eventos/",
          method: "POST",
          data: payload,
        };

        await http(request);
      }

      if (parentName === "update") {
        const request = {
          endpoint: `eventos/${event.id}/`,
          method: "PUT",
          data: payload,
        };

        await http(request);
      }

      setSubmitted({ clicked: false, button: ""});

      notification.success({
        message: "¡Correcto!",
        description: "Evento guardado correctamente.",
      });

      navigate("/pages/events/overview");
    } catch (error) {
      setSubmitted({ clicked: false, button: ""});

      if (error?.errorFields) return false;

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });
    }
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ""}`}
        style={{
          cursor: "move",
          ...style,
        }}
        {...restProps}
      />
    );
  };

  const getCatalogs = async () => {

    try {

      setError(undefined);
      setPageLoading(true);

      const requests = [
        http({ endpoint: "empresas/?status_d1=A" }),
        http({ endpoint: `dominios/?dominio=D3` }),
        http({ endpoint: `consejo-administracion/?no_page=true` }),
        http({ endpoint: `accionistas/?ordering=nombre_rs&no_page=true` })
      ];

      const [
        companyResponse,
        typeResponse,
        executiveResponse,
        shareholderResponse
      ] = await Promise.all(requests);

      setCompanies(companyResponse.results);
      setTypes(typeResponse.results);
      setExecutives(executiveResponse);
      setShareholders(shareholderResponse);

      if (event) setValues();

      setPageLoading(false);

    } catch (error) {

      setPageLoading(false);
      setError(error);

    }

  };

  const disabledDate = current => current && current < moment().tz("America/Mexico_City").subtract(1, 'days').endOf("day");

  const renderCalendarFooter = () => {
    let cdmxStartTime = moment().tz("America/Mexico_City").format("HH:mm");
    return (
      <span>
        Hora de México: {cdmxStartTime}
      </span>
    )
  }

  const documentProps = {
    onRemove: (file) => {
      const index = selectedFile.indexOf(file);
      const newFileList = selectedFile.slice();
      newFileList.splice(index, 1);
      setSelectedFile(newFileList);
    },
    beforeUpload: (file) => {
      setSelectedFile([file]);
      return false;
    },
    selectedFile,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e?.fileList;
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = activities[dragIndex];
      setActivities(
        update(activities, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [activities],
  );

  const onAgendaOkClick = async () => {
    try {
      await agendaForm.validateFields();

      let activity = {
        id: (selectedActivity?.id) ? selectedActivity.id : getUniqueKey(),
        name: agendaForm.getFieldValue("name"),
        hasVoting: agendaForm.getFieldValue("hasVoting"),
        isNewFile: true,
        file: (selectedFile && selectedFile.length > 0) ? selectedFile[0] : "",
        link: "",
      };

      if (selectedActivity?.id) {
        let currentActivities = [...activities];
        const index = currentActivities.findIndex((activity) => activity.id === selectedActivity.id);
        const currentFileName = (activity.file) ? activity.file.name : "";
        const oldFileName = (selectedActivity && selectedActivity.file) ? selectedActivity.file.name : "";

        if (currentFileName !== "" && oldFileName !== "" && currentFileName === oldFileName) activity.isNewFile = false;
        if (!activity.isNewFile) activity.link = selectedActivity.link;

        currentActivities[index] = activity;

        setActivities(currentActivities);
      } else {
        setActivities([...activities, activity]);
      }

      agendaForm.resetFields();

      setSelectedFile([]);
      setAgendaModalVisible(false);
      setSelectedActivity(undefined);
    } catch(error) {
      if (error?.errorFields) return false;

      notification.error({ message: "¡Ups!", description: error.message });
    }
  };

  const onAgendaEditClick = (id) => {

    const currentActivities = [...activities];
    const activity = currentActivities.find((activity) => activity.id === id);

    setSelectedActivity(activity);

    if (activity.file) setSelectedFile([activity.file]);

    agendaForm.setFieldsValue({
      name: activity.name,
      hasVoting: activity.hasVoting,
      files: (activity.file) ? [activity.file] : []
    });

    setAgendaModalVisible(true);

  };

  const onAgendaDeleteClick = (id) => {

    const currentActivities = [...activities];
    const index = currentActivities.findIndex((activity) => activity.id === id);
    currentActivities.splice(index, 1);

    setActivities(currentActivities);

  };

  const onAgendaCancelClick = () => {

    agendaForm.resetFields();

    setAgendaModalVisible(false);
    setSelectedActivity(undefined);

  };

  const onGuestCancelClick = () => {

    guestForm.resetFields();

    setGuestModalVisible(false);

  };

  const onGuestDeleteClick = (id) => {

    const currentGuests = [...guests];
    const index = currentGuests.findIndex((guest) => guest.id === id);
    currentGuests.splice(index, 1);

    setGuests(currentGuests);

  };

  const onGuestOkClick = async () => {

    try {

      await guestForm.validateFields();

      let selectedShareholders = guestForm.getFieldValue("shareholders") || [];
      let currentGuest = [...guests];
      const selectedGuests = shareholders.filter((shareholder) => (selectedShareholders.includes(shareholder.id)));

      setGuests(currentGuest.concat(selectedGuests));

      guestForm.resetFields();

      setGuestModalVisible(false);

    } catch(error) {

      if (error?.errorFields) return false;

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const getDefaultGuests = async (companyId) => {
    try {
      const endpoint = `empresas/${companyId}/accionistas/?no_page=true`;
      const results = await http({ endpoint });
      setGuests(results);
    } catch (error) {
      setGuests([]);
      notification.error({ message: "¡Ups!", description: "No se pudieron obtener los participantes." });
    }
  };

  const getExecutives = (companyId) => {
    const selectedExecutives = executives.filter((executive) => (executive.empresa.id === companyId)).map((executive) => executive.accionista);
    setGuests(selectedExecutives);
  }

  const onFormValuesChange = (e) => {

    if (e.mode !== undefined) {
      form.resetFields(["link", "platform", "location"]);
      setSelectedMode(e.mode);
    }

    if (e.company !== undefined) {
      const currentType = form.getFieldValue("type");
      if (currentType === undefined) {
        setGuests([]);
      } else if (currentType === "CA") {
        getExecutives(e.company);
      } else {
        getDefaultGuests(e.company);
      }
    }

    if (e.type !== undefined) {
      const currentCompany = form.getFieldValue("company");
      if (currentCompany !== undefined) {
        if (e.type === "CA") {
          getExecutives(currentCompany)
        } else {
          getDefaultGuests(currentCompany);
        }
      }
    }
  }

  const agendaColumns = [
    {
      title: "",
      width: 70,
      align: "center",
      render: (_) => (<MenuOutlined />)
    },
    {
      title: "Actividad",
      dataIndex: "name"
    },
    {
      title: "¿Se Vota?",
      dataIndex: "hasVoting",
      width: 100,
      align: "center",
      render: (cell) => ((cell) ? <Tag color="green">Si</Tag> : <Tag>No</Tag>)
    },
    {
      title: "",
      dataIndex: "id",
      width: 200,
      align: "center",
      render: (cell, row) => (
        <Space size={"middle"}>
          {row.link &&
            <DownloadButton
              tooltip="Descargar PDF"
              url={row.link}
              fileName={row.nombre_archivo}
              icon={<FilePdfOutlined />}
            />
          }
          <Button
            size={"small"}
            shape="circle"
            className="noBorderColor bgTransparent"
            onClick={() => onAgendaEditClick(cell)}
            icon={<EditOutlined />}
          />
          <Button
            size={"small"}
            shape="circle"
            className="noBorderColor bgTransparent"
            icon={<DeleteOutlined />}
            onClick={() => onAgendaDeleteClick(cell)}
          />
        </Space>
      )
    },
  ];

  const guestColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre_rs"
    },
    {
      title: "",
      dataIndex: "id",
      width: 100,
      align: "center",
      render: (cell) => (
        <Button
          size={"small"}
          shape="circle"
          className="noBorderColor bgTransparent"
          icon={<DeleteOutlined />}
          onClick={() => onGuestDeleteClick(cell)}
        />
      )
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const setValues = async () => {
    try {
      form.setFieldsValue({
        "name": event.nombre_evento,
        "company": event?.empresa?.id,
        "type": event.tipo_evento_d3?.valor,
        "duration": event.duracion_evento,
        "platform": event.plataforma || "",
        "link": event.link || "",
        "location": event.ubicacion,
        "mode": (event.virtual_presencial) ? 1 : 0,
        "startDatetime": moment(event.inicio_plan_fh),
        notifications: []
      });

      setSelectedMode((event.virtual_presencial) ? 1 : 0);

      if (event.agenda) {
        const activitiesMapped = event.agenda.map((activity) => {
          return {
            name: activity.descripcion,
            hasVoting: activity.se_vota,
            id: activity.id,
            isNewFile: false,
            file: (activity.nombre_archivo_anexo) ? new File([], activity.nombre_archivo_anexo) : "",
            link: activity.link,
            bucketPath: activity.referencia_s3_archivo
          }
        });
        setActivities(activitiesMapped);
      }
      if (event.asistentes) {
        const guestMapped = event.asistentes.map(({ accionista }) => ({ id: accionista.pk, nombre_rs: accionista.nombre_rs }));
        setGuests(guestMapped);
      }
    } catch (error) {
      notification.error({ message: "¡Ups!", description: "Ocurrió un problema procesando los datos." });
    }
  }

  const agendaCardExtra = (
    <Button
      size="small"
      onClick={() => setAgendaModalVisible(true)}
      type={"primary"}
      ghost
    >
      Agregar
    </Button>
  );

  const guestCardExtra = (
    <Button
      size="small"
      onClick={() => setGuestModalVisible(true)}
      type={"primary"}
      ghost
    >
      Agregar
    </Button>
  )

  useEffect(
    () => {
      if (
        event &&
        event.tipo_evento_d3?.valor === "CA" &&
        executives.length > 0) {
          const selectedExecutives = executives.filter((executive) => (executive.empresa.id === event?.empresa?.id)).map((executive) => executive.accionista);
          setShareholders(selectedExecutives);
      }
    },
    [executives]
  );

  useEffect(
    () => {
      onSubmitClick();
    },
    [isSubmitted]
  );

  useEffect(
    () => {
      getCatalogs();
    },
    []
  );

  return (
    <>
      {isPageLoading && !error && <PageLoader />}
      {!isPageLoading && !error && (
        <Form
          layout="vertical"
          form={form}
          onValuesChange={onFormValuesChange}
        >
          <Card className="mt1">
            <Row gutter={24}>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Nombre"
                  name="name"
                  initialValue={""}
                  rules={COMMON_REQUIRED_FORM_RULE}
                  hasFeedback
                >
                  <Input
                    type={"text"}
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Compañía"
                  name="company"
                  rules={COMMON_REQUIRED_FORM_RULE}
                  hasFeedback
                >
                  <Select>
                    {
                      companies.map(({ id, razon_social}) => {
                        return (
                          <Option key={`company-${id}`} value={id}>
                            {razon_social}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Tipo"
                  name="type"
                  rules={COMMON_REQUIRED_FORM_RULE}
                  hasFeedback
                >
                  <Select>
                    {
                      types.map(({ id, valor, nombre}) => {
                        return (
                          <Option key={`type-${id}`} value={valor}>
                            {nombre}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Fecha y Hora"
                  name="startDatetime"
                  rules={COMMON_REQUIRED_FORM_RULE}
                  hasFeedback
                >
                  <DatePicker
                    format={DATETIME_FORMAT}
                    disabledDate={disabledDate}
                    showTime
                    className="w100"
                    renderExtraFooter={renderCalendarFooter}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Duración"
                  name="duration"
                  rules={COMMON_REQUIRED_FORM_RULE}
                  hasFeedback
                >
                  <InputNumber
                    min={0.5}
                    step={0.5}
                    max={12}
                    controls={false}
                    className="w100"
                    addonAfter={"hrs"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Es un evento"
                  name="mode"
                  rules={COMMON_REQUIRED_FORM_RULE}
                  hasFeedback
                >
                  <Radio.Group>
                    <Radio value={1}>Presencial</Radio>
                    <Radio value={0}>Virtual</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {selectedMode === 1 &&
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Ubicación"
                    name="location"
                    initialValue={""}
                    hasFeedback
                  >
                    <Input type={"text"} />
                  </Form.Item>
                </Col>
              }
              {selectedMode === 0 &&
                <>
                  <Col xs={24} sm={6}>
                    <Form.Item
                      label="Plataforma"
                      name="platform"
                      hasFeedback
                    >
                      <Select>
                        <Option value={"Google Meets"}>
                          Google Meets
                        </Option>
                        <Option value={"Microsoft Teams"}>
                          Microsoft Teams
                        </Option>
                        <Option value={"Skype"}>
                          Skype
                        </Option>
                        <Option value={"Webex"}>
                          Webex
                        </Option>
                        <Option value={"Zoom"}>
                          Zoom
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              }
              <Col xs={24} sm={(selectedMode === 0) ? 18 : 12}>
                    <Form.Item
                      label="Enlace"
                      name="link"
                      initialValue={""}
                      hasFeedback
                    >
                      <Input type={"url"} />
                    </Form.Item>
              </Col>
              {/* <Col xs={24} sm={12}>
                <Form.Item
                  label="Notificaciones"
                  name="notifications"
                  hasFeedback
                >
                  <Select
                    mode="multiple"
                    placeholder={"Seleccionar"}
                    allowClear
                  >
                    <Option value="1">
                      5 minutos antes
                    </Option>
                    <Option value="2">
                      30 minutos antes
                    </Option>
                    <Option value="3">
                      1 hora antes
                    </Option>
                    <Option value="4">
                      1 día antes
                    </Option>
                    <Option value="5">
                      1 semana antes
                    </Option>
                    <Option value="6">
                      1 mes antes
                    </Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
          </Card>
          <Card
            title="Agenda"
            className="mt1"
            extra={agendaCardExtra}
          >
            <DndProvider backend={HTML5Backend}>
              <Table
                columns={agendaColumns}
                dataSource={activities}
                components={components}
                rowKey={"id"}
                size={"small"}
                onRow={(_, index) => {
                  const attr = {
                    index,
                    moveRow,
                  };
                  return attr;
                }}
              />
            </DndProvider>
          </Card>
          <Card
            title="Asistentes"
            className="mt1"
            extra={guestCardExtra}
          >
            <Table
              columns={guestColumns}
              dataSource={guests}
              size={"small"}
              rowKey={"id"}
            />
          </Card>
        </Form>
      )}
      {!isPageLoading && error && (
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getCatalogs}
          exitPath={"/pages/events/overview"}
        />
      )}
      {agendaForm &&
        <Modal
          title={`${(selectedActivity) ? "Editar" : "Agregar"} Actividad`}
          visible={isAgendaModalVisible}
          onOk={onAgendaOkClick}
          maskClosable={false}
          onCancel={onAgendaCancelClick}
        >
          <Form layout="vertical" form={agendaForm}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={COMMON_ALPHA_NUMERIC_FORM_RULE}
              hasFeedback
            >
              <Input
                type={"text"}
                autoFocus
              />
            </Form.Item>
            <Form.Item
              label="¿Se Vota?"
              name="hasVoting"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
                <Radio.Group>
                  <Radio value={true}>
                    Si
                  </Radio>
                  <Radio value={false}>
                    No
                  </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Documento"
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              hasFeedback
            >
              <Upload
                {...documentProps}
                maxCount={1}
                accept={COMMON_ACCEPT_FILE_FORMATS}
              >
                <Button
                  className="w100"
                  icon={<UploadOutlined />}
                >
                  Seleccionar
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      }
      {guestForm &&
        <Modal
          title="Agregar Asistentes"
          visible={isGuestModalVisible}
          onOk={onGuestOkClick}
          maskClosable={false}
          onCancel={onGuestCancelClick}
        >
          <Form
            layout="vertical"
            form={guestForm}
          >
            <Form.Item
              label="Asistentes"
              name="shareholders"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => `${option.key}`.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  shareholders.map(
                    ({id, nombre_rs}) => {
                      const isSelected = guests.some((guest) => guest.id === id);
                      if (!isSelected) {
                        return (
                          <Option
                            key={`${nombre_rs}-${id}`}
                            value={id}
                          >
                            {nombre_rs}
                          </Option>);
                      } else {
                        return null;
                      }
                    }
                  )
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      }
    </>
  );
};

export default EventForm;
