import {
  useEffect,
  useState
} from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import {
  PageHeader,
  Button,
  Card,
  Tooltip
} from "antd";
import PageLoader from "../../../components/page-loader/PageLoader";
import http from "../../../services/http.service";
import ErrorResult from "../../../components/error-result/ErrorResult";
import CustomTable from "../../../components/custom-table/CustomTable";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { EditOutlined } from "@ant-design/icons";

const ShareholderDocumentVariables = () => {

  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [shareholder, setShareholder] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();
  const { id } = useParams();

  const columns = [
    {
      title: "Campo",
      dataIndex: "nombre_campo",
      filterName: "campo__nombre",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      filterName: "valor",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 80,
      render: (cell) => (
        <Tooltip title={"Editar"}>
          <Button
            size={"small"}
            shape="circle"
            className="noBorderColor bgTransparent"
            onClick={() => navigate(`/pages/shareholders/${id}/documents/variables/${cell}/update`)}
            icon={<EditOutlined />}
          />
        </Tooltip>
      ),
    }
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = `accionistas/${id}/datos-para-documentos/?ordering=campo__nombre&offset=0&limit=10`;
      let queryParams = [
        "ordering=campo__nombre",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const requests = [
        http({ endpoint: `accionistas/${id}/`}),
        http({ endpoint })
      ];

      const [
        shareholderResponse,
        variablesResponse
      ] = await Promise.all(requests);

      setCustomTableConfig({
        data: variablesResponse.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `accionistas/${id}/datos-para-documentos/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: variablesResponse?.count || 0,
          next: variablesResponse?.next || "",
          prev: variablesResponse?.previous || ""
        }
      });

      setShareholder(shareholderResponse);
      setPageLoading(false);

    } catch (error) {

      setPageError(error);

    }

  };

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/shareholders/overview",
      breadcrumbName: "Participantes",
    },
    {
      path: `/pages/shareholders/${id}/details`,
      breadcrumbName: `${shareholder?.nombre_rs || id}`,
    },
    {
      path: `/pages/shareholders/${id}/documents/variables`,
      breadcrumbName: "Datos para documentos",
    }
  ];

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && shareholder &&
        <>
          <PageHeader
            title={`${shareholder.nombre_rs}`}
            onBack={() => navigate(`/pages/shareholders/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
          />
          <Card
            title={"Variables"}
            className="mt1">
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={`/pages/shareholders/${id}/details`}
        />
      }
    </>
  )
}

export default ShareholderDocumentVariables;