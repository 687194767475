import {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import http from "../../../services/http.service";
import PageLoader from "../../../components/page-loader/PageLoader";
import ErrorResult from "../../../components/error-result/ErrorResult";
import {
  maskTelephone,
  sanitizeAlphaNumericText,
  maskTelephoneField
} from "../../../shared/utils";
import {
  COMMON_REQUIRED_FORM_RULE,
  DATE_FORMAT
} from "../../../constants";
import {
  BIRTH_DATE_FORM_RULE,
  CURP_FORM_RULE,
  EMAIL_FORM_RULE,
  NAME_FORM_RULE,
  NATIONALITY_FORM_RULE,
  RFC_FORM_RULE,
  TELEPHONE_FORM_RULE,
  TYPE_FORM_RULE
} from "./rules";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  notification,
  Card,
  Radio
} from 'antd';

const ShareholderForm = ({ isSubmitted, setSubmitted, shareholder }) => {

  const [form] = Form.useForm();
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [types, setTypes] = useState([]);
  const [config, setConfiguration] = useState({});
  const [hasJointSignature, setJointSignature] = useState(false);
  const navigate = useNavigate();

  const { Option } = Select;

  const minAdultDate = moment().subtract(18, 'years');
  const minDefaultDate = moment();

  const onSubmitClick = async () => {

    try {

      if (!isSubmitted) return;

      await form.validateFields();

      const values = form.getFieldsValue();

      setSubmitted(true);

      const payload = {
        nombre_rs: values.name,
        fecha_nacimiento: values.birthDate.format('YYYY-MM-DD'),
        nacionalidad: values.nationality.toUpperCase(),
        rfc: values.rfc.toUpperCase(),
        curp: values.curp.toUpperCase(),
        domicilio: values.address,
        telefono: values.telephone.split(".").join(""),
        correo: values.email,
        notificaciones_correo: values?.notifications?.includes("mail") || false,
        notificaciones_sms: values?.notifications?.includes("sms") || false,
        comision_porcentaje: values.commission,
        retencion_porcentaje: values.isr,
        contrato_liquidacion: values.settlement,
        notas: values.notes,
        status_d1: "A",
        tipo_accionista_d2: values.type,
        firma_mancomunada: (values?.hasJointSignature === "1") ? true : false,
        firma_mancomunada_correo: values.jointSignatureEmail,
        firma_mancomunada_nombre: values.jointSignatureName,
        firma_mancomunada_rfc: values.jointSignatureRFC,
        usa_firma_digital: (values?.digitalSign === "1") ? true : false,
        rfc_firma: values.signatureRFC,
      };

      if (!shareholder) {
        const request = {
          endpoint: "accionistas/",
          method: "POST",
          data: payload
        };

        await http(request);
      }

      if (shareholder) {
        const request = {
          endpoint: `accionistas/${shareholder.id}/`,
          method: "PUT",
          data: payload
        };

        await http(request);
      }
      
      setSubmitted(false);

      notification.success({
        message: '¡Correcto!',
        description: 'Expediente guardado correctamente.',
      });

      if (!shareholder) navigate("/pages/shareholders/overview");
      if (shareholder) navigate(`/pages/shareholders/${shareholder.id}/details`);

    } catch(error) {

      setSubmitted(false);

      if (error?.errorFields) return false;

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  const disabledDate = (current) => {

    const typeValue = form.getFieldValue("type");

    if (typeValue === "PF") {
      return current && current > minAdultDate.endOf('day');
    } else {
      return current && current > minDefaultDate.endOf('day');
    }

  };

  const onAlphaNumericChange = (field) => {

    const value = form.getFieldValue(field);
    const newValue = sanitizeAlphaNumericText(value);

    form.setFieldsValue({ [field]: newValue });

  };

  const getCatalogs = async () => {

    try {

      const endpoint = "dominios/?dominio=D2";

      const response = await http({ endpoint });

      const results = response?.results.map((result) => { return { value: result.valor, label: result.nombre }});
      
      setTypes(results);
      setPageLoading(false);
      
      if (shareholder) setValues();

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  }

  const onTelephoneChange = (e) => {

    e.preventDefault();

    const value = e.target.value;
    const newValue = maskTelephoneField(value);

    form.setFieldsValue({ "telephone": newValue });

  }

  const onTypeChange = (value) => {

    const newConfig = {
      name: {
        label: (value === "PF") ? "Nombre" : "Razón Social",
      },
      birthDate: {
        label: (value === "PF") ? "Fecha Nacimiento" : "Fecha Constitución",
        defaultPickerValue: (value === "PF") ? minAdultDate : minDefaultDate
      },
      curp: {
        rules: (value === "PF") ?  COMMON_REQUIRED_FORM_RULE : [],
        label: "CURP",
        disabled: (value === "PF") ? false : true
      },
    };

    form.resetFields(["curp", "birthDate"]);

    setConfiguration(newConfig);

  };

  const setValues = () => {

    if (!shareholder) return;

    onTypeChange(shareholder?.tipo_accionista_d2?.valor);

    let notifications = [];

    if(shareholder?.notificaciones_correo) notifications.push("mail");
    if(shareholder?.notificaciones_sms) notifications.push("sms");

    const birthDate = moment(shareholder?.fecha_nacimiento).add(1, 'days');

    form.setFieldsValue({
      name: shareholder?.nombre_rs,
      nationality: shareholder?.nacionalidad,
      rfc: shareholder?.rfc,
      curp: shareholder?.curp,
      address: shareholder?.domicilio,
      telephone: maskTelephone(shareholder?.telefono),
      email: shareholder?.correo,
      notifications,
      birthDate,
      type: shareholder?.tipo_accionista_d2?.valor,
      commission: shareholder?.comision_porcentaje,
      isr: shareholder?.retencion_porcentaje,
      notes: shareholder?.notas,
      hasJointSignature: (shareholder?.firma_mancomunada) ? "1" : "0",
      jointSignatureEmail: shareholder.firma_mancomunada_correo,
      jointSignatureName: shareholder.firma_mancomunada_nombre,
      jointSignatureRFC: shareholder.firma_mancomunada_rfc,
      signatureRFC: shareholder.rfc_firma,
      settlement: shareholder.contrato_liquidacion,
      digitalSign: (shareholder?.usa_firma_digital) ? "1" : "0"
    });
  
  };

  const initConfiguration = () => {

    setConfiguration({
      name: {
        label: "Nombre",
      },
      birthDate: {
        label: "Fecha Nacimiento",
        defaultPickerValue: minAdultDate
      },
      curp: {
        rules: CURP_FORM_RULE,
        label: "CURP",
        disabled: false
      },
    });

    if (!shareholder) {
      form.setFieldsValue({
        notifications: ["mail"],
        hasJointSignature: "0"
      })
    }

  }

  const onJointSignatureChange = (value) => {
    if (value.target.value === '1') setJointSignature(true)
    if (value.target.value === '0') {
      setJointSignature(false);
      form.setFieldsValue({
        jointSignatureName: '',
        jointSignatureEmail: '',
        jointSignatureRFC: ''
      })
    }
  }

  useEffect(
    () => {
      getCatalogs();
      initConfiguration();
    },
    []
  )

  useEffect(
    () => {
      onSubmitClick();
    },
    [isSubmitted]
  )

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && form &&
        <Card className="mt1">
          <Form
            layout='vertical'
            form={form}
          >
            <Row gutter={24}>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={"Tipo"}
                  name="type"
                  rules={TYPE_FORM_RULE}
                  hasFeedback
                >
                  <Select
                    onChange={onTypeChange}
                    disabled={shareholder}
                  >
                    {
                      types.map((type) => {
                        return (
                          <Option key={`type-option-${type.value}`} value={type.value}>
                            {type.label}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={config?.name?.label}
                  name="name"
                  initialValue={""}
                  rules={NAME_FORM_RULE}
                  hasFeedback
                >
                  <Input
                    type={"text"}
                    maxLength={65}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={config?.birthDate?.label}
                  name="birthDate"
                  rules={BIRTH_DATE_FORM_RULE}
                  hasFeedback
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    format={DATE_FORMAT}
                    defaultPickerValue={config?.birthDate?.defaultPickerValue}
                    className="w100"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="Nacionalidad"
                  name="nationality"
                  initialValue={"MEXICANA"}
                  rules={NATIONALITY_FORM_RULE}
                  hasFeedback
                >
                  <Input
                    type={"text"}
                    maxLength={45}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={config?.curp?.label}
                  name="curp"
                  initialValue={""}
                  rules={config?.curp?.rules}
                  hasFeedback
                >
                  <Input
                    type={"text"}
                    maxLength={18}
                    disabled={config?.curp?.disabled}
                    onChange={() => onAlphaNumericChange('curp')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="RFC"
                  name="rfc"
                  initialValue={""}
                  rules={RFC_FORM_RULE}
                  hasFeedback
                >
                  <Input
                    type={"text"}
                    maxLength={13}
                    onChange={() => onAlphaNumericChange('rfc')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="RFC Firma"
                  name="signatureRFC"
                  initialValue={""}
                  rules={RFC_FORM_RULE}
                  hasFeedback
                >
                  <Input
                    type={"text"}
                    maxLength={13}
                    onChange={() => onAlphaNumericChange('rfc')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="¿Firma digital?"
                  name="digitalSign"
                  initialValue={"0"}
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <Radio.Group>
                    <Radio value="1">Si</Radio>
                    <Radio value="0">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Teléfono"
                  name="telephone"
                  rules={TELEPHONE_FORM_RULE}
                  initialValue={""}
                  hasFeedback
                >
                  <Input
                    type={"tel"}
                    className="w100"
                    onChange={onTelephoneChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Correo eléctronico"
                  name="email"
                  rules={EMAIL_FORM_RULE}
                  hasFeedback
                >
                  <Input
                    type={"email"}
                    maxLength={65}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Dirección"
                  name="address"
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <Input
                    type={"text"}
                    maxLength={400}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Comisión"
                  name="commission"
                  initialValue={0}
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <InputNumber
                    addonAfter={"%"}
                    min={0}
                    max={99}
                    className="w100"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Retención ISR"
                  name="isr"
                  initialValue={0}
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <InputNumber
                    addonAfter={"%"}
                    min={0}
                    max={99}
                    className={"w100"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Contrato Liquidación"
                  name="settlement"
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <Input
                    type={"text"}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Notificaciones"
                  name="notifications"
                >
                  <Select mode="multiple" allowClear>
                    <Option value="sms">
                      SMS
                    </Option>
                    <Option value="mail">
                      Correo
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="¿Firma mancomunada?"
                  name="hasJointSignature"
                  initialValue={"0"}
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <Radio.Group onChange={onJointSignatureChange}>
                    <Radio value="1">Si</Radio>
                    <Radio value="0">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="Nombre"
                  name="jointSignatureName"
                >
                  <Input
                    type={"text"}
                    maxLength={45}
                    disabled={!hasJointSignature}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="RFC"
                  name="jointSignatureRFC"
                >
                   <Input
                    type={"text"}
                    maxLength={13}
                    onChange={() => onAlphaNumericChange('rfc')}
                    disabled={!hasJointSignature}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  label="Email"
                  name="jointSignatureEmail"
                >
                  <Input
                    type={"email"}
                    maxLength={65}
                    disabled={!hasJointSignature}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Notas"
                  name="notes"
                  initialValue={""}
                >
                  <Input
                    type={"text"}
                    maxLength={400}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getCatalogs}
          exitPath={"/pages/dashboard"}
        />
      }
    </>
  )
}

export default ShareholderForm;