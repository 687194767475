import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import http from '../../../../services/http.service';
import { DeleteButton, ErrorResult, CustomTable, PageLoader } from '../../../../components';
import { itemRender } from "../../../../shared/helpers/breadcrumb";
import { DeleteOutlined, RightOutlined } from '@ant-design/icons';
import { formatDateTime, getUniqueKey, maskBigNumber } from '../../../../shared/utils';
import { COMMON_REQUIRED_FORM_RULE, INPUT_NUMBER_FORMAT, DEFAULT_CUSTOM_TABLE_CONFIG, DEFAULT_MODAL_STATE } from '../../../../constants';
import {
  PageHeader,
  Typography,
  Tooltip,
  Card,
  Modal,
  Button,
  notification,
  Form,
  Select,
  Radio,
  InputNumber,
  Space,
  Input,
} from 'antd';

const CompanyIncreaseStocks = () => {
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [capitalForm] = Form.useForm();
  const [capitalModalState, setCapitalModalState] = useState(DEFAULT_MODAL_STATE);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [series, setSeries] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { Text, Paragraph } = Typography;
  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${id}/details`,
      breadcrumbName: `${company?.nombre_corto || 'Loading'}`,
    },
    {
      path: `/pages/companies/${id}/increase-in-capital`,
      breadcrumbName: 'Aumento de capital',
    }
  ];

  const columns = [
    {
      title: 'Serie / Clase',
      dataIndex: 'criterios_serie_clase',
      ellipsis: { showTitle: false },
      width: 150,
      align: 'center',
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: 'Cantidad',
      dataIndex: 'criterios_num_acciones',
      align: 'center',
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: 'Asignación',
      dataIndex: 'criterios_algoritmo',
      width: 150,
      align: 'center',
      render: (cell) => (
        cell === "MANUAL" ? <Text>Manual</Text> : <Text>Por % accionario</Text>
      )
    },
    {
      title: "Estatus",
      dataIndex: "status",
      align: 'center',
      width: 120,
    },
    {
      title: "Fecha Aplicación",
      dataIndex: "aplicado_fh",
      align: 'center',
      width: 150,
      render: (cell) => (formatDateTime(cell))
    },
    {
      title: "Notas",
      dataIndex: "notas",
      align: 'center',
      ellipsis: { showTitle: false },
      width: 200,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '200px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 120,
      align: "center",
      render: (cell, row) => (
        <Space>
          {row.status === "BORRADOR" &&
            <DeleteButton
              endpoint={`empresas/${id}/aumento-capital/${cell}/`}
              icon={<DeleteOutlined />}
              confirmContent="Se eliminará el borrador del aumento de capital permanentemente."
              onFinish={getData}
            />
          }
          <Tooltip title={"Detalle"}>
            <Button
              size={"small"}
              className="noBorderColor bgTransparent"
              onClick={() => navigate(`/pages/companies/${id}/increase-in-capital/${cell}/details`)}
              shape={"circle"}
              icon={<RightOutlined />}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  const onCreateCapitalClick = async () => {
    try {
      await capitalForm.validateFields();

      setCapitalModalState({ visible: true, loading: true });

      const request = {
        endpoint: `empresas/${id}/aumento-capital/`,
        method: "POST",
        data: {
          criterios_algoritmo: capitalForm.getFieldValue("algorithm"),
          criterios_num_acciones: capitalForm.getFieldValue("stocks"),
          criterios_serie_clase: capitalForm.getFieldValue("serie")[0],
          notas: capitalForm.getFieldValue("notas"),
        }
      };

      const response = await http(request);

      setCapitalModalState(DEFAULT_MODAL_STATE);
      navigate(`/pages/companies/${id}/increase-in-capital/${response.id}/details`)
    } catch (error) {
      if (error?.errorFields) return false;

      setCapitalModalState({ visible: true, loading: false });
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const onCapitalSerieChange = (value) => {
    if (value.length > 1) {
      const lastIndex = value.length - 1;
      const lastItem = value[lastIndex];
      capitalForm.setFieldsValue({ serie: [lastItem] });
    }
  };

  const onCancelCapitalClick = () => {
    setCapitalModalState(DEFAULT_MODAL_STATE);
    capitalForm.resetFields();
  }

  const getCompany = async () => {
    try {
      const response = await http({ endpoint: `empresas/${id}/posicion-actual/` });
      setCompany(response);
    } catch (error) {
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const getSeries = async () => {
    try {
      const response = await http({ endpoint: `empresas/${id}/serie-clase/?no_page=true` });
      setSeries(response.values);
    } catch (error) {
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const getData = async (props = null) => {
    try {
      setPageError(undefined);

      let endpoint = `empresas/${id}/aumento-capital/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint })

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `empresas/${id}/aumento-capital/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

      setPageLoading(false);
    } catch(error) {
      setPageError(error);
      setPageLoading(false);
    }
  };

  const cardExtra = (
    <Button
      key={getUniqueKey()}
      type='primary'
      size={"small"}
      onClick={() => setCapitalModalState({ visible: true, loading: false })}
      ghost
    >
      Nuevo
    </Button>
  );

  useEffect(
    () => {
      getData();
      getCompany();
      getSeries();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company &&
        <>
          <PageHeader
            title={`Aumento de capital`}
            onBack={() => navigate(`/pages/companies/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
            extra={cardExtra}
          />
          <Card className={"mt1"}>
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
      <Modal
        title={"Nuevo"}
        visible={capitalModalState.visible}
        onOk={onCreateCapitalClick}
        confirmLoading={capitalModalState.loading}
        onCancel={onCancelCapitalClick}
      >
        <Form layout='vertical' form={capitalForm}>
          <Form.Item
              label="Serie / Clase"
              name="serie"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <Select
                mode="tags"
                onChange={onCapitalSerieChange}
                options={series.map((serie) => ({ value: serie, label: serie}))}
              />
          </Form.Item>
          <Form.Item
            label="Acciones"
            name="stocks"
            rules={COMMON_REQUIRED_FORM_RULE}
            hasFeedback
          >
            <InputNumber
              className={"w100"}
              formatter={value => maskBigNumber(value)}
              parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
              min={0}
              controls={false}
            />
          </Form.Item>
          <Form.Item
            label="Asignación"
            name="algorithm"
            initialValue={"MANUAL"}
            rules={COMMON_REQUIRED_FORM_RULE}
          >
            <Radio.Group>
              <Radio value="MANUAL">Manual</Radio>
              <Radio value="TENENCIA">Por % accionario</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Notas" name="notes" hasFeedback>
            <Input.TextArea maxLength={250} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CompanyIncreaseStocks;