import { useNavigate } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { PageHeader } from "antd";
import Literax from "../../../shared/components/literax/Literax";

const TransactionSignDocuments = () => {

  const navigate = useNavigate();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/transactions/overview',
      breadcrumbName: 'Transacciones',
    },
    {
      path: '/pages/transactions/sign-documents',
      breadcrumbName: 'Firma de documentos',
    }
  ];

  return (
    <>
      <PageHeader
        title="Firma de documentos"
        onBack={() => navigate(-1)}
        breadcrumb={{ routes, itemRender }}
      />
      <Literax />
    </>
  );
};

export default TransactionSignDocuments;