import { useState } from 'react';
import http from "../../../services/http.service";
import { getUniqueKey, maskBigNumber } from '../../../shared/utils';
import { EditOutlined } from "@ant-design/icons";
import { COMMON_REQUIRED_FORM_RULE, INPUT_NUMBER_FORMAT } from "../../../constants";
import {
    Form,
    Tooltip,
    Button,
    InputNumber,
    notification,
    Modal,
    Input
} from "antd";

const TransactionEditExtrasButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isModalVisible, setModalVisibility] = useState(false)

    const onActionClick = async () => {
        try {
            setModalVisibility(false)
            setLoading(true);
            const { metadata, transaction } = props;
            const shareholder = metadata.accionista.id
            const stocks = form.getFieldValue("stocks");
            const request = {
                endpoint: `accionistas/${shareholder}/derechos-oferta-acciones-adicionales/${transaction}/`,
                method: "PATCH",
                data: {
                    acciones_adicionales: stocks
                }
            };
            await http(request);
            setLoading(false);
            notification.success({ message: '¡Correcto!', description: "Actualizadas correctamente." });
            if (props?.onFinish) props.onFinish()
        } catch (error) {
            setLoading(false);
            notification.error({ message: '¡Ups!', description: error.message });
        }
    }

    return (
        <>
            <Tooltip title="Editar Acciones Adicionales" key={getUniqueKey()}>
                <Button
                    key={getUniqueKey()}
                    shape="circle"
                    className="noBorderColor bgTransparent"
                    icon={<EditOutlined />}
                    loading={loading}
                    onClick={() => setModalVisibility(true)}
                />
            </Tooltip>
            <Modal
                title="Acciones Adicionales"
                visible={isModalVisible}
                maskClosable={false}
                onOk={() => onActionClick()}
                okText={"Actualizar"}
                onCancel={() => setModalVisibility(false)}>
                <Form layout="vertical" form={form}>
                    <Form.Item label="Expediente:" name="shareholder" initialValue={props.metadata.accionista.nombre_rs}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Solicitadas:"
                        name="stocks"
                        initialValue={props.metadata.derecho_solicita_extra}
                        rules={COMMON_REQUIRED_FORM_RULE}
                        hasFeedback>
                        <InputNumber
                            min={1}
                            formatter={value => maskBigNumber(value)}
                            controls={false}
                            parser={value => value?.replace(INPUT_NUMBER_FORMAT, "")}
                            className='w100'
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default TransactionEditExtrasButton;