import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Space,
  Tooltip,
  Typography,
  Tag,
  Table,
  Descriptions,
  Card,
  notification,
  Modal,
  Badge
} from "antd";
import {
  PlayCircleOutlined,
  CheckOutlined,
  FastForwardOutlined,
  FilePdfOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import http from "../../../services/http.service";
import { DownloadButton, PageLoader, ErrorResult } from "../../../components";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { formatDateTime } from "../../../shared/utils";
import { DEFAULT_MODAL_STATE } from "../../../constants";

const EventManage = () => {
  const [event, setEvent] = useState({});
  const [confirmModalState, setConfirmModalState] = useState(DEFAULT_MODAL_STATE);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [pageHeaderExtras, setPageHeaderExtras] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();

  const { Text, Paragraph } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
    {
      path: `/pages/events/${id}/manage`,
      breadcrumbName: "Administrar",
    },
  ];

  const getData = async (onRefresh = false) => {
    try {
      if (!onRefresh) {
        setError(undefined);
        setLoading(true);
      }

      const response = await http({ endpoint: `eventos/${id}/` });

      const checkins = response.asistentes.filter((asistente) => asistente.checkin_status === "S");
      response.total_asistentes = checkins.length;

      if (response.status_evento_d10 !== "CM") {
        setPageHeaderExtras([
          <Button
            onClick={() => setConfirmModalState({visible: true, loading: false})}
            type='primary'
            size={"small"}
            key={"pageHeaderExtraFinish"}
            ghost
          >
            Finalizar
          </Button>
        ]);

      }

      setEvent(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onCloseClick = async () => {
    try {
      setConfirmModalState({ visible: false, loading: true });

      const request = {
        endpoint: `eventos/${id}/status/`,
        method: "PATCH",
        data: { status: "CM" }
      };

      await http(request);

      setConfirmModalState(DEFAULT_MODAL_STATE);

      navigate("/pages/events/overview");
    } catch (error) {
      setConfirmModalState(DEFAULT_MODAL_STATE);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });
    }
  }

  const onActivityStatusChange = async (activityId, status) => {
    try {
      const request = {
        endpoint: `eventos/${id}/agenda/${activityId}/`,
        method: "PATCH",
        data: {
          accion: status
        }
      };

      await http(request);

      const response = await http({ endpoint: `eventos/${id}/` });

      const checkins = response.asistentes.filter((asistente) => asistente.checkin_status === "S");
      response.total_asistentes = checkins.length;

      setEvent(response);
    } catch (error) {
      notification.error({
        message: "¡Ups!",
        description: error.message,
      });
    }
  }

  const columns = [
    {
      title: "Actividad",
      dataIndex: "descripcion",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_punto_agenda_d12",
      width: 140,
      render: (cell) => {
        if (cell === "C") {
          return <Tag color="green">Completado</Tag>
        } else if (cell === "R") {
          return <Tag color="blue">En Proceso</Tag>
        } else if (cell === "O") {
          return <Tag>Omitido</Tag>
        } else if (cell === "V") {
          return <Tag color="geekblue">En Votación</Tag>
        } else {
          return <Tag>Pendiente</Tag>
        }
      }
    },
    {
      title: "Votación",
      dataIndex: "resultado_votacion",
      width: 200,
      render: (cell, row) => (
        (row.se_vota)
          ?
            <Space direction="vertical">
              <Badge color={"blue"} text={`Pendientes: ${cell.pendiente} - (${row.resultado_votacion_porcentaje.pendiente} %)`} />
              <Badge color={"green"} text={`A Favor: ${cell.a_favor} - (${row.resultado_votacion_porcentaje.a_favor} %)`} />
              <Badge color={"red"} text={`En Contra: ${cell.en_contra} - (${row.resultado_votacion_porcentaje.en_contra} %)`} />
              <Badge color={"yellow"} text={`Abstención: ${cell.abstencion} - (${row.resultado_votacion_porcentaje.abstencion} %)`} />
              <Badge color={"gray"} text={`Sin emitir: ${cell.sin_emitir} - (${row.resultado_votacion_porcentaje.sin_emitir} %)`} />
            </Space>
          :
            null
      )
    },
    {
      title: "",
      dataIndex: "id",
      width: 220,
      align: "center",
      render: (cell, row) => (
        <Space size={"large"}>
          {row.se_vota && row.status_punto_agenda_d12 === "R" &&
            <Tooltip title={"iniciar votación"}>
              <Button
                size={"small"}
                onClick={() => onActivityStatusChange(cell, "VOTACION INICIO")}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<PlayCircleOutlined />}
              />
            </Tooltip>
          }
          {row.se_vota && row.status_punto_agenda_d12 === "V" &&
            <Tooltip title={"finalizar votación"}>
              <Button
                size={"small"}
                onClick={() => onActivityStatusChange(cell, "VOTACION FIN")}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<CloseCircleOutlined />}
              />
            </Tooltip>
          }
          {row.status_punto_agenda_d12 === "R" &&
            <Tooltip title={"saltar paso"}>
              <Button
                size={"small"}
                onClick={() => onActivityStatusChange(cell, "SALTAR")}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<FastForwardOutlined />}
              />
            </Tooltip>
          }
          {row.se_vota && (row.status_punto_agenda_d12 === "V" || row.status_punto_agenda_d12 === "C") &&
            <Tooltip title={"Detalle"}>
              <Button
                size={"small"}
                onClick={() => navigate(`/pages/events/${id}/activity/${cell}/votes`)}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<InfoCircleOutlined />}
              />
            </Tooltip>
          }
          {row?.referencia_s3_archivo &&
            <DownloadButton
              tooltip="Descargar"
              url={row.link}
              fileName={row?.descripcion}
              icon={<FilePdfOutlined />}
            />
          }
          {row.status_punto_agenda_d12 === "R" &&
            <Tooltip title={"completar actividad"}>
              <Button
                size={"small"}
                onClick={() => onActivityStatusChange(cell, "FINALIZAR")}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<CheckOutlined />}
              />
            </Tooltip>
          }
        </Space>
      ),
    },
  ];

  useEffect(
    () => {
      getData();
    },
    []
  );

  useEffect(
    () => {
      const interval = setInterval(() => {
        getData(true);
      }, 3000);
      return () => clearInterval(interval);
    },
    []
  );

  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error &&
        <>
          <PageHeader
            title={`${event.nombre_evento}`}
            breadcrumb={{ routes, itemRender }}
            onBack={() => navigate("/pages/events/overview")}
            extra={pageHeaderExtras}
          >
            <Descriptions size="small" column={{xs: 1, sm: 1, lg: 3, xl: 3}}>
              <Descriptions.Item label="Compañía">
                <Text>
                  {event?.empresa?.nombre_corto}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Tipo">
                <Text>
                  {event?.tipo_evento_d3?.nombre}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Modalidad">
                {event?.virtual_presencial && <Text>Presencial</Text>}
                {!event?.virtual_presencial && <Text>Virtual</Text>}
              </Descriptions.Item>
              <Descriptions.Item label="Fecha">
                {!event?.inicio_real_fh && (
                  <Text>
                    {formatDateTime(event?.inicio_plan_fh)}
                  </Text>
                )}
                {event?.inicio_real_fh && (
                  <Text>
                    {formatDateTime(event?.inicio_real_fh)}
                  </Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Asistentes">
                <Text>
                  {event?.numero_de_asistentes}
                </Text>
              </Descriptions.Item>
              {event?.ubicacion &&
                <Descriptions.Item label="Ubicación">
                  <Text>
                    {event?.ubicacion}
                  </Text>
                </Descriptions.Item>
              }
              {event?.plataforma &&
                <Descriptions.Item label="Plataforma">
                  <Text>
                    {event?.plataforma}
                  </Text>
                </Descriptions.Item>
              }
              <Descriptions.Item label="Quorum">
                <Text>
                  {event?.quorum}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Invitados">
                <Text>
                  {event?.numero_de_invitados}
                </Text>
              </Descriptions.Item>
              {event?.link &&
                <Descriptions.Item label="Link">
                  <Paragraph style={{ width: "calc(100% - 1rem)"}} copyable ellipsis>
                    {event?.link}
                  </Paragraph>
                </Descriptions.Item>
              }
            </Descriptions>
          </PageHeader>
          <Card title="Agenda" className="mt1">
            <Table
              columns={columns}
              dataSource={event.agenda}
              rowKey={"id"}
              size={"small"}
            />
          </Card>
        </>
      }
      {!loading && error &&  (
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getData}
          exitPath={"/pages/events/overview"}
        />
      )}
      <Modal
        title="Confirmación"
        visible={confirmModalState.visible}
        onOk={() => onCloseClick()}
        confirmLoading={confirmModalState.loading}
        maskClosable={false}
        onCancel={() => setConfirmModalState(DEFAULT_MODAL_STATE)}
      >
        <Text>
          Se finalizará el evento al confirmar.
        </Text>
      </Modal>
    </>
  );
};

export default EventManage;