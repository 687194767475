import {
  EMAIL_PATTERN,
  ALPHA_PATTERN
} from "../../../../constants";

export const EMAIL_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    whitespace: false,
    message: "No se acepta solo espacios.",
  },
  {
    pattern: EMAIL_PATTERN,
    message: "Formato incorrecto.",
  },
];

export const FIRST_NAME_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: ALPHA_PATTERN,
    message: "Solo se aceptan letras.",
  },
];

export const LAST_NAME_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: ALPHA_PATTERN,
    message: "Solo se aceptan letras.",
  },
];

export const ROLES_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  }
];