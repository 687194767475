
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const ModuleGuard = ({ children, module }) => {
  const { user } = useAuth();
  if (user.modules[module]) {
    return children;
  } else {
    return <Navigate to='/pages/dashboard' />
  }
};
