import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Space,
  Tooltip,
  Typography,
  Tag,
  Table,
  Descriptions,
  Card,
  Badge,
  Upload,
  notification
} from "antd";
import {
  RightOutlined,
  FilePdfOutlined,
  UploadOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import http from "../../../services/http.service";
import { DownloadButton, PageLoader, ErrorResult, DeleteButton } from "../../../components"
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { formatDateTime, getSanitizedFileName, getUniqueKey } from "../../../shared/utils";
import { COMMON_ACCEPT_FILE_FORMATS } from "../../../constants";

const EventMemorandum = () => {

  const [event, setEvent] = useState();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();

  const { Text, Paragraph } = Typography;

  const documentProps = {
    beforeUpload: (file) => {
      setSelectedFile([file]);
      return false;
    },
    selectedFile,
  };

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
    {
      path: `/pages/events/${id}/memorandum`,
      breadcrumbName: "Minutas",
    },
  ];

  const getData = async () => {
    try {
      setError(undefined);
      setLoading(true);
      const endpoint = `eventos/${id}/`;
      const response = await http({ endpoint });
      const checkins = response.asistentes.filter((asistente) => asistente.checkin_status === "S");
      response.total_asistentes = checkins.length;
      setEvent(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getEvent = async () => {
    try {
      const endpoint = `eventos/${id}/`;
      const response = await http({ endpoint });
      const checkins = response.asistentes.filter((asistente) => asistente.checkin_status === "S");
      response.total_asistentes = checkins.length;
      setEvent(response);
    } catch (error) {
      notification.error({
        message: "¡Ups!",
        description: error.message,
      });
    }
  };

  const onUploadChange = async () => {
    try {
      setUploading(true);

      const formData = new FormData();
      const fileName = getSanitizedFileName(selectedFile[0].name);

      formData.append("archivo", selectedFile[0]);
      formData.append("id_evento", id);
      formData.append('nombre_archivo', fileName);

      const request = {
        endpoint: `eventos/${id}/minuta/`,
        method: "POST",
        data: formData
      };

      await http(request);

      getEvent()
      setSelectedFile([]);
      setUploading(false);

      notification.success({
        message: "¡Correcto!",
        description: "Minúta guardada correctamente.",
      });

    } catch (error) {

      setUploading(false);
      setSelectedFile([]);
      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  }

  const agendaColumns = [
    {
      title: "Actividad",
      dataIndex: "descripcion",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_punto_agenda_d12",
      width: 140,
      render: (cell) => {
        if (cell === "C") {
          return <Tag color="green">Completado</Tag>
        } else if (cell === "R") {
          return <Tag color="blue">En Proceso</Tag>
        } else if (cell === "O") {
          return <Tag>Omitido</Tag>
        } else if (cell === "V") {
          return <Tag color="geekblue">En Votación</Tag>
        } else {
          return <Tag>Pendiente</Tag>
        }
      }
    },
    {
      title: "Votación",
      dataIndex: "resultado_votacion",
      width: 200,
      render: (cell, row) => (
        (row.se_vota)
          ?
            <Space direction="vertical">
              <Badge color={"blue"} text={`Pendientes: ${cell.pendiente} - (${row.resultado_votacion_porcentaje.pendiente} %)`} />
              <Badge color={"green"} text={`A Favor: ${cell.a_favor} - (${row.resultado_votacion_porcentaje.a_favor} %)`} />
              <Badge color={"red"} text={`En Contra: ${cell.en_contra} - (${row.resultado_votacion_porcentaje.en_contra} %)`} />
              <Badge color={"yellow"} text={`Abstención: ${cell.abstencion} - (${row.resultado_votacion_porcentaje.abstencion} %)`} />
              <Badge color={"gray"} text={`Sin emitir: ${cell.sin_emitir} - (${row.resultado_votacion_porcentaje.sin_emitir} %)`} />
            </Space>
          :
            null
      )
    },
    {
      title: "",
      dataIndex: "id",
      width: 170,
      align: "center",
      render: (cell, row) => (
        <Space size={"large"}>
          {row?.link &&
            <DownloadButton
              tooltip="Descargar PDF"
              url={row.link}
              fileName={row.nombre_archivo}
              icon={<FilePdfOutlined />}
            />
          }
          {row.se_vota &&
            <Tooltip title={"Detalle"}>
              <Button
                size={"small"}
                className="noBorderColor bgTransparent"
                onClick={() => navigate(`/pages/events/${id}/activity/${cell}/votes`)}
                shape='circle'
                icon={<RightOutlined />}
              />
            </Tooltip>
          }
        </Space>
      ),
    },
  ];

  const minutaColumns = [
    {
      title: "Archivo",
      dataIndex: "nombre_archivo",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 80,
      align: "center",
      render: (cell, row) => (
        <Space size={"small"}>
          <DownloadButton
            key={getUniqueKey()}
            tooltip="Descargar"
            url={row.archivo}
            fileName={row.nombre_archivo}
            icon={<FilePdfOutlined />}
          />
          <DeleteButton
            endpoint={`eventos/${id}/minuta/${cell}/`}
            icon={<DeleteOutlined />}
            confirmContent="Se eliminará la minúta permanentemente."
            onFinish={getEvent}
          />
        </Space>
      ),
    },
  ];

  useEffect(
    () => {
      getData();
    },
    []
  );

  useEffect(
    () => {
      if (selectedFile && selectedFile.length > 0) {
        onUploadChange()
      }
    },
    [selectedFile]
  );

  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error && event &&
        <>
          <PageHeader
            title={`${event.nombre_evento}`}
            breadcrumb={{ routes, itemRender }}
            onBack={() => navigate(-1)}
            extra={
              <Button
                key='pageHeaderDetailBtn'
                type='primary'
                size={"small"}
                onClick={() => navigate(`/pages/events/${id}/guests`)}
                ghost
              >
                Asistentes
              </Button>
            }
          >
            <Descriptions size="small" column={{xs: 1, sm: 1, lg: 3, xl: 3}}>
              <Descriptions.Item label="Compañía">
                <Text>
                  {event?.empresa?.nombre_corto}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Tipo">
                <Text>
                  {event?.tipo_evento_d3?.nombre}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Modalidad">
                {event?.virtual_presencial && <Text>Presencial</Text>}
                {!event?.virtual_presencial && <Text>Virtual</Text>}
              </Descriptions.Item>
              <Descriptions.Item label="Fecha">
                {!event?.inicio_real_fh && (
                  <Text>
                    {formatDateTime(event?.inicio_plan_fh)}
                  </Text>
                )}
                {event?.inicio_real_fh && (
                  <Text>
                    {formatDateTime(event?.inicio_real_fh)}
                  </Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Asistentes">
                <Text>
                  {event?.numero_de_asistentes}
                </Text>
              </Descriptions.Item>
              {event?.ubicacion &&
                <Descriptions.Item label="Ubicación">
                  <Text>{event?.ubicacion}</Text>
                </Descriptions.Item>
              }
              {event?.plataforma &&
                <Descriptions.Item label="Plataforma">
                  <Text>{event?.plataforma}</Text>
                </Descriptions.Item>
              }
              <Descriptions.Item label="Quorum">
                <Text>
                  {event?.quorum}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Invitados">
                <Text>
                  {event?.numero_de_invitados}
                </Text>
              </Descriptions.Item>
              {event?.link &&
                <Descriptions.Item label="Link">
                  <Paragraph style={{ width: "calc(100% - 1rem)" }} copyable ellipsis>
                    {event?.link}
                  </Paragraph>
                </Descriptions.Item>
              }
            </Descriptions>
          </PageHeader>
          <Card
            title="Documentos"
            className="mt1"
            extra={
              <Upload
                {...documentProps}
                maxCount={1}
                accept={COMMON_ACCEPT_FILE_FORMATS}
                disabled={uploading}
                fileList={[]}
              >
                <Button
                  size="small"
                  icon={<UploadOutlined />}
                  loading={uploading}
                >
                  Subir
                </Button>
              </Upload>
            }>
              <Table
                columns={minutaColumns}
                size={"small"}
                dataSource={event.minutas}
                rowKey={"id"}
              />
          </Card>
          <Card title="Agenda" className="mt1">
            <Table
              columns={agendaColumns}
              size={"small"}
              dataSource={event.agenda}
              rowKey={"id"}
            />
          </Card>
        </>
      }
      {!loading && error &&  (
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getData}
          exitPath={"/pages/events/overview"}
        />
      )}
    </>
  );
};

export default EventMemorandum