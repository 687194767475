import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import useAuth from "../../../hooks/useAuth";
import http from "../../../services/http.service";
import styles from "../styles/Settings.module.css";
import {
  PageHeader,
  List,
  Card,
  notification
} from "antd";
import {
  KeyOutlined,
  RightOutlined,
  FireOutlined,
  LoadingOutlined,
  TableOutlined,
  AlertOutlined
} from "@ant-design/icons";

const SettingsOverview = () => {

  const [data, setData] = useState([]);
  const [isItemLoading, setItemLoading] = useState(undefined);

  const { user } = useAuth();
  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/settings/overview",
      breadcrumbName: "Configuraciones",
    },
  ];

  const setListItems = () => {

    let items = [
      {
        label: "Cambiar contraseña",
        id: "1",
        key: "/pages/settings/change/password",
        icon: <KeyOutlined />,
      },
    ];

    if (user.roles["ADMIN"]) {
      items.push(
        {
          label: "Literax",
          id: "3",
          key: "/pages/settings/literax",
          icon: <TableOutlined />,
        },
        {
          label: "Ejecutar vencimientos",
          id: "2",
          key: "/pages/settings/change/password",
          icon: <FireOutlined />,
        },
        {
          label: "Alertas",
          id: "4",
          key: "/pages/settings/alerts",
          icon: <AlertOutlined />,
        },
        {
          label: "Parametros",
          id: "5",
          key: "/pages/settings/params",
          icon: <TableOutlined />,
        }
      );
    }
    
    setData(items);

  };

  const onFireClick = async () => {
    try {
      setItemLoading('2');
      const request = {
        endpoint: "transacciones/vencimientos/",
        method: "PATCH",
        data: {}
      };
  
      await http(request);
  
      setItemLoading(undefined);
      notification.success({ message: '¡Correcto!', description: 'Ejecutado correctamente.' });
    } catch (error) {
      setItemLoading(undefined);
      notification.error({ message: '¡Ups!', description: error.message });
    }

  }

  const onItemClick = (id) => {
    if (isItemLoading) return;
    if (id === "1") navigate(`/pages/settings/change/password`)
    if (id === "2") onFireClick();
    if (id === "3") navigate(`/pages/settings/literax`)
    if (id === "4") navigate(`/pages/settings/alerts`)
    if (id === "5") navigate(`/pages/settings/params`)
  }

  useEffect(
    () => {
      setListItems();
    },
    []
  );

  return (
    <>
      <PageHeader
        title='Configuraciones'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
      />
      <Card className="mt1">
        <List
          size="large"
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              className={styles.settingsListItem}
              onClick={() => onItemClick(item.id)}
            >
              <List.Item.Meta
                avatar={item.icon}
                title={item.label}
              />
              {(!isItemLoading || (isItemLoading && isItemLoading != item.id)) &&
                <>
                  <RightOutlined />
                </>
              }
              {isItemLoading && isItemLoading == item.id &&
                <>
                  <LoadingOutlined />
                </>
              }
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

export default SettingsOverview;