import moment from "moment-timezone";
import { DATETIME_FORMAT, DATE_FORMAT } from "../../constants";

// START FORM FUNCTIONS
export const maskTelephone = (value) => `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ".");
export const maskTelephoneField = (value) => {
  const cleanValue = value.split(".").join("");
  if (cleanValue.length % 2 === 0) {
    const newValue = maskTelephone(value);
    return newValue;
  } else {
    return value;
  }
};
export const sanitizeAlphaNumericText = (value) => value.toUpperCase().replace(/[^A-Z0-9]/g, '');
export const maskPercentage = (value) => {
  if (!value) return "0 %";
  return `${parseFloat(value).toFixed(6)} %`;
};
export const maskBigNumber = (value, type = "quantity", decimals = 6) => {
  if (!value && type === "quantity") return "0";
  if (!value && type === "currency") return "$ 0";
  let splitValue = (type === "currency") ? `${parseFloat(value).toFixed(decimals)}`.split('.') :`${parseFloat(value)}`.split('.') ;
  let response = "";
  if (splitValue.length === 1) response += splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (splitValue.length > 1) {
    response += splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    response += `.${splitValue[1]}`
  };
  if (type === "currency") response = `$ ${response}`;
  return response;
};

export const getUniqueKey = () => {
  const date = new Date().getTime().toString(36);
  const random = Math.random().toString(36).substring(2);
  return `${random}${date}`;
}

// END FORM FUNCTIONS

// START FILES FUNCTIONS

export const getSanitizedFileName = (fileName) => {
  const splitName = fileName.split('.');
  const extension = splitName[splitName.length - 1];
  const date = new Date();
  // const cleanedFileName = `${splitName[0]}`.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  return `${date.getTime()}_${splitName[0]}.${extension}`;
}

// END FILES FUNCTIONS

// START DATES FUNCTIONS

export const getMoment = (date = null) => (date) ? moment(date).tz("America/Mexico_City") : moment().tz("America/Mexico_City");

export const formatDateTime = (date, timezone = null) => {
  if (!date) return "";
  if (timezone) return moment(date).tz(timezone).format(DATETIME_FORMAT);
  return moment(date).format(DATETIME_FORMAT);
};

export const formatUTCSeconds = (seconds) => {
  const d = new Date(0);
  d.setUTCSeconds(seconds);
  return formatDateTime(d);
}

export const formatDate = (date, timezone = "America/Mexico_City") => {
  if (!date) return "";
  return moment(date).tz(timezone).format(DATE_FORMAT);
}

export const getDiffDates = (endDate, startDate = null) => {
  if (!endDate) return "";
  const endMoment = moment(endDate);
  const startMoment = (startDate) ? moment(startDate) : moment();
  let duration = moment.duration(endMoment.diff(startMoment));
  const days = parseInt(duration.asDays());
  duration.subtract(moment.duration(days,'days'));
  const hours = duration.hours();
  duration.subtract(moment.duration(hours,'hours'));
  const minutes = duration.minutes();
  duration.subtract(moment.duration(minutes,'minutes'));
  const daySuffix = (days === 1) ? "día" : "días";
  const hourSuffix = (hours === 1) ? "hora" : "horas";
  const minuteSuffix = (minutes === 1) ? "minuto" : "minutos";
  if (days < 0 || hours < 0 || minutes < 0) return "";
  return `${days} ${daySuffix} ${hours} ${hourSuffix} ${minutes} ${minuteSuffix}`;
};

export const getDiffDateTimes = (endDateTime, unit = 'minutes') => {
  if (!endDateTime) return 0;
  return moment(endDateTime).tz("America/Mexico_City").diff(moment().tz("America/Mexico_City"), unit);
}

export const getBeforeDateTime = (amount, unit, date = null) => {
  if (date) {
    return moment(date).tz("America/Mexico_City").subtract(amount, unit).toISOString();
  } else {
    return moment().tz("America/Mexico_City").subtract(amount, unit).toISOString();
  }
}

// END DATES FUNCTIONS

export const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getUserNameFromEmail = (email) => {
  const splitEmail = email.split('@');
  return`${splitEmail[0]}`.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}