import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_REQUIRED_FORM_RULE } from "../../../constants";
import http from "../../../services/http.service";
import {
  Col,
  Row,
  Form,
  Input,
  notification,
  Card
} from 'antd';

const ShareholderDocumentVariableForm = ({ isSubmitted, setSubmitted, variable }) => {

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmitClick = async () => {

    try {

      if (!isSubmitted) return;

      await form.validateFields();

      const values = form.getFieldsValue();

      setSubmitted(true);

      const request = {
        endpoint: `accionistas/${variable.shareholderId}/datos-para-documentos/${variable.id}/`,
        method: "PATCH",
        data: {
          campo_id: variable.campo_id,
          valor: values.value,
        }
      }

      await http(request);

      setSubmitted(false);

      notification.success({
        message: '¡Correcto!',
        description: 'Variable actualizada correctamente.',
      });

      navigate(`/pages/shareholders/${variable.shareholderId}/documents/variables`);

    } catch(error) {

      setSubmitted(false);

      if (error?.errorFields) return false;

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  const setValues = () => {

    if (!variable) return;

    form.setFieldsValue({
      field: variable?.nombre_campo,
      value: variable?.valor
    });
  
  };

  useEffect(
    () => {
      setValues();
    },
    [variable]
  )

  useEffect(
    () => {
      onSubmitClick();
    },
    [isSubmitted]
  )

  return (
    <>
      {form &&
        <Card className="mt1">
          <Form
            layout='vertical'
            form={form}
          >
            <Row gutter={24}>
              <Col xs={24} sm={6}>
                <Form.Item
                  label={"Campo"}
                  name="field"
                  initialValue={""}
                >
                  <Input
                    type={"text"}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={18}>
                <Form.Item
                  label="Valor"
                  name="value"
                  initialValue={""}
                  rules={COMMON_REQUIRED_FORM_RULE}
                >
                  <Input
                    type={"text"}
                    maxLength={65}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      }
    </>
  )
}

export default ShareholderDocumentVariableForm;