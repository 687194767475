import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Card,
  Descriptions,
  Tag,
  Space,
  Form,
  Modal,
  Select,
  notification,
  InputNumber,
  DatePicker,
  Tooltip,
  Typography,
  Input,
  Upload,
  Table,
  Radio
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined
} from "@ant-design/icons";
import http from "../../../services/http.service";
import {
  CustomTable,
  DownloadButton,
  ErrorResult,
  PageLoader
} from "../../../components";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import {
  maskBigNumber,
  maskTelephone,
  formatDate,
  formatDateTime,
  getUniqueKey
} from "../../../shared/utils";
import {
  COMMON_REQUIRED_FORM_RULE,
  DEFAULT_CUSTOM_TABLE_CONFIG,
  DATE_FORMAT,
  DEFAULT_MODAL_STATE,
  INPUT_NUMBER_FORMAT,
  COMMON_ACCEPT_FILE_FORMATS
} from "../../../constants";

const CompanyDetails = () => {
  const DOCUMENT_DEFAULT_MODAL_STATE = {
    createVisible: false,
    createLoading: false,
    deleteVisible: false,
    deleteLoading: false
  };

  const [documentForm] = Form.useForm();
  const [adminForm] = Form.useForm();
  const [priceForm] = Form.useForm();
  const [notesForm] = Form.useForm();

  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [selectedAdmin, setAdmin] = useState(undefined);

  const [adminModalState, setAdminModalState] = useState(DEFAULT_MODAL_STATE);
  const [priceModalState, setPriceModalState] = useState(DEFAULT_MODAL_STATE);
  const [notesModalState, setNotesModalState] = useState(DEFAULT_MODAL_STATE);
  const [priceCustomTableConfig, setPriceCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [stockCustomTableConfig, setStockCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [adminCustomTableConfig, setAdminCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const [positions, setPositions] = useState([]);
  const [adminTypes, setAdminTypes] = useState([]);
  const [availableShareholders, setAvailableShareholders] = useState([]);

  const [documentModalState, setDocumentModalState] = useState(DOCUMENT_DEFAULT_MODAL_STATE);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isDocumentTableLoading, setDocumentTableLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { Option } = Select;
  const { Text, Paragraph } = Typography;
  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/companies/overview",
      breadcrumbName: "Compañías",
    },
    {
      path: `/pages/companies/${id}/update`,
      breadcrumbName: `${company?.nombre_corto}`,
    },
    {
      path: `/pages/companies/${id}/details`,
      breadcrumbName: "Detalles",
    }
  ];

  const priceColumns = [
    {
      title: "Fecha",
      dataIndex: "fecha",
      align: "center",
      render: (cell) => (
        <Text>
          {formatDate(cell,)}
        </Text>)
    },
    {
      title: "Valoraciones",
      dataIndex: "precio",
      align: "center",
      render: (cell) => (
        <Text>
          {maskBigNumber(cell, "currency")}
        </Text>)
    }
  ];

  const documentColumns = [
    {
      title: "Etiqueta",
      dataIndex: "nombre_documento",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre_archivo",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Fecha",
      dataIndex: "creado_fh",
      width: 130,
      align: "center",
      render: (cell) => (
        <Text>
          {formatDateTime(cell)}
        </Text>
      )
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 160,
      render: (_, row) => (
        <Space size={"small"}>
          <DownloadButton
            tooltip="Descargar"
            url={row.link}
            fileName={row.nombre_archivo}
            icon={<DownloadOutlined />}
          />
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              icon={<DeleteOutlined />}
              onClick={
                () => {
                  setSelectedDocument(row);
                  setDocumentModalState({
                    ...documentModalState,
                    deleteVisible: true
                  });
                }
              }
            />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const stockColumns = [
    {
      title: "Serie / Clase",
      dataIndex: "serie_clase",
      align: "center",
      width: 250,
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      align: "center",
      width: 250,
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: "Notas",
      dataIndex: "notas",
      width: 300,
      align: "center",
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '300px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      )
    },
    {
      title: "",
      dataIndex: "id_accion",
      align: "center",
      width: 80,
      render: (_, row) => (
        <Space size="small">
          <Tooltip title={"Editar Notas"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedStock(row);
                notesForm.setFieldsValue({ notes: row.notas });
                setNotesModalState({ visible: true, loading: false });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const adminColumns = [
    {
      title: "Expediente",
      dataIndex: "accionista",
      filterName: "accionista",
      render: (cell) => (
        <Text>
          {cell.nombre_rs}
        </Text>
      )
    },
    {
      title: "Posición",
      dataIndex: "posicion_d5",
      render: (cell) => (
        <Text>
          {cell}
        </Text>
      )
    },
    {
      title: "Tipo",
      dataIndex: "posicion_tipo_d14",
      render: (cell) => (
        <Text>
          {cell}
        </Text>
      )
    },
    {
      title: "Correo Electrónico",
      dataIndex: "accionista",
      render: (cell) => (
        <Text>
          {cell.correo}
        </Text>
      )
    },
    {
      title: "Teléfono",
      dataIndex: "accionista",
      render: (cell) => (
        <Text>
          {maskTelephone(cell.telefono || "")}
        </Text>
      )
    },
    {
      title: "¿Puede votar?",
      dataIndex: "vota",
      align: 'center',
      render: (cell) => (
        <>
          {cell && <Tag color="green">Si</Tag>}
          {!cell && <Tag>No</Tag>}
        </>
      )
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 120,
      render: (cell, row) => (
        <Space size="small">
          <Tooltip title={"Editar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              onClick={() => onPrepareAdminEditClick(row)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              onClick={() => onDeleteAdminClick(cell)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getDocuments = async () => {

    try {

      setDocumentTableLoading(true);

      const endpoint = `documentos-empresas/${id}/?no_page=true`;

      const documents = await http({ endpoint });

      setDocuments(documents);
      setDocumentTableLoading(false);

    } catch (error) {

      setDocumentTableLoading(false);

      notification.error({
        message: "¡Ups!",
        description: "No se pudo recuperar los documentos.",
      });

    }

  };

  const onDocumentCreateOkClick = async () => {

    try {

      await documentForm.validateFields();

      setDocumentModalState({
        ...documentModalState,
        createLoading: true
      });

      const formData = new FormData();
      const file = selectedFile[0];
      const name = documentForm.getFieldValue("name");

      formData.append("nombre_documento", name);
      formData.append('nombre_archivo', file);
      formData.append("empresa_id", id);

      const request = {
        endpoint: `documentos-empresas/${id}/`,
        method: "POST",
        data: formData
      };

      await http(request);

      setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);

      documentForm.resetFields();

      notification.success({
        message: '¡Correcto!',
        description: 'Documento creado correctamente.',
      });

      getDocuments();

    } catch(error) {

      if (error?.errorFields) return false;

      setDocumentModalState({
        ...documentModalState,
        createLoading: false
      });

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  const onDeleteDocumentClick = async () => {

    try {

      setDocumentModalState({
        ...selectedDocument,
        deleteLoading: true
      });

      const request = {
        endpoint: `documentos-empresas/${id}/${selectedDocument.id}/`,
        method: "DELETE"
      };

      await http(request);

      setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);

      notification.success({
        message: '¡Correcto!',
        description: 'Documento eliminado correctamente.',
      });

      getDocuments();

    } catch(error) {

      setDocumentModalState({
        ...selectedDocument,
        deleteLoading: false
      });

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e?.fileList;
  };

  const documentProps = {
    onRemove: (file) => {
      const index = selectedFile.indexOf(file);
      const newFileList = selectedFile.slice();
      newFileList.splice(index, 1);
      setSelectedFile(newFileList);
    },
    beforeUpload: (file) => {
      setSelectedFile([file]);
      return false;
    },
    selectedFile,
  };

  const getAdmins = async (props = null) => {

    try {

      let endpoint = `consejo-administracion/?empresa=${id}&limit=10&offset=0&ordering=accionista`;
      let queryParams = [
        `empresa=${id}`,
        "limit=10",
        "offset=0",
        "ordering=accionista"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setAdminCustomTableConfig({
        data: response.results,
        columns: adminColumns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "consejo-administracion/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getAdmins,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch(error) {

      setAdminCustomTableConfig({...adminCustomTableConfig, loading: false});

      notification.error({
        message: '¡Ups!',
        description: "No se pudo obtener el consejo de administración.",
      });

    }

  }

  const getPrices = async (props = null) => {

    try {

      let endpoint = `empresas/${id}/valoraciones/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setPriceCustomTableConfig({
        data: response.results,
        columns: priceColumns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `empresas/${id}/valoraciones/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getPrices,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch(error) {

      setPriceCustomTableConfig({...priceCustomTableConfig, loading: false});

      notification.error({
        message: '¡Ups!',
        description: "No se pudo obtener las valoraciones.",
      });

    }

  }

  const getStocks = async (props = null) => {
    try {
      let endpoint = `empresas/${id}/acciones/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setStockCustomTableConfig({
        data: response.results,
        columns: stockColumns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `empresas/${id}/acciones/`,
        url: endpoint,
        rowKey: "id_accion",
        onRefresh: getStocks,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch(error) {

      setStockCustomTableConfig({...stockCustomTableConfig, loading: false});

      notification.error({
        message: '¡Ups!',
        description: "No se pudo obtener las acciones.",
      });

    }

  }

  const getData = async () => {
    try {
      setPageLoading(true);
      setPageError(undefined);

      const promises = [
        http({ endpoint: `empresas/${id}/` }),
        http({ endpoint: `dominios/?dominio=D5` }),
        http({ endpoint: `accionistas/?status_d1=A&no_page=true&ordering=nombre_rs` }),
        http({ endpoint: `dominios/?dominio=D14&no_page=true` }),
      ];

      const [
        companyResponse,
        positionResponse,
        shareholderResponse,
        typeResponse,
      ] = await Promise.all(promises);

      setAvailableShareholders(shareholderResponse);
      setPositions(positionResponse.results);
      setCompany(companyResponse);
      setAdminTypes(typeResponse);
      setPageLoading(false);
      getDocuments();
      getAdmins();
      getPrices();
      getStocks();
    } catch(error) {
      setPageError(error);
      setPageLoading(false);
    }
  };

  const onPrepareAdminEditClick = (administrator) => {

    setAdmin(administrator);

    adminForm.setFieldsValue({
      shareholder: parseInt(administrator?.accionista?.id),
      position: administrator.posicion_d5,
      type: administrator.posicion_tipo_d14,
      canVote: administrator.vota ? '1' : '0',
    });

    setAdminModalState({ visible: true, loading: false });

  };

  const onDeleteAdminClick = async (administratorId) => {

    try {

      const request = {
        endpoint: `consejo-administracion/${administratorId}/`,
        method: "DELETE"
      };

      await http(request);

      notification.success({
        message: '¡Correcto!',
        description: 'Eliminado correctamente.',
      });

      getAdmins();

    } catch (error) {

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const onEditAdminClick = async () => {

    try {

      setAdminModalState({ visible: true, loading: true });

      const request = {
        endpoint: `consejo-administracion/${selectedAdmin.id}/`,
        method: "PUT",
        data: {
          empresa: id,
          accionista: selectedAdmin.accionista.id,
          posicion_d5: adminForm.getFieldValue("position"),
          posicion_tipo_d14: adminForm.getFieldValue("type") || null,
          vota: adminForm.getFieldValue("canVote") === '1' ? true : false,
        }
      };

      await http(request);

      setAdminModalState(DEFAULT_MODAL_STATE);
      setAdmin(undefined);

      adminForm.resetFields();

      notification.success({
        message: '¡Correcto!',
        description: 'Actualizado correctamente.',
      });

      getAdmins();

    } catch (error) {

      setAdminModalState(DEFAULT_MODAL_STATE);
      setAdmin(undefined);

      adminForm.resetFields();

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const onCreateAdminClick = async () => {

    try {

      await adminForm.validateFields();

      setAdminModalState({ visible: true, loading: true });

      const request = {
        endpoint: "consejo-administracion/",
        method: "POST",
        data: {
          empresa: id,
          accionista: adminForm.getFieldValue("shareholder"),
          posicion_d5: adminForm.getFieldValue("position"),
          posicion_tipo_d14: adminForm.getFieldValue("type") || null,
          vota: adminForm.getFieldValue("canVote") === '1' ? true : false,
        }
      };

      await http(request);

      setAdminModalState(DEFAULT_MODAL_STATE);

      adminForm.resetFields();

      notification.success({
        message: '¡Correcto!',
        description: 'Guardado correctamente.',
      });

      getAdmins();

    } catch (error) {

      if (error?.errorFields) return false;

      setAdminModalState(DEFAULT_MODAL_STATE);

      adminForm.resetFields();

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const onCreatePriceClick = async () => {

    try {

      await priceForm.validateFields();

      setPriceModalState({ visible: true, loading: true });

      const request = {
        endpoint: `empresas/${id}/valoraciones/`,
        method: "POST",
        data: {
          fecha: priceForm.getFieldValue("date"),
          precio: priceForm.getFieldValue("price")
        }
      };

      await http(request);

      setPriceModalState(DEFAULT_MODAL_STATE);

      priceForm.resetFields();

      notification.success({
        message: '¡Correcto!',
        description: 'Creado correctamente.',
      });

      getPrices();

    } catch (error) {

      if (error?.errorFields) return false;

      setPriceModalState({ visible: true, loading: false });

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const onUpdateNotesClick = async () => {
    try {
      await notesForm.validateFields();

      setNotesModalState({ visible: true, loading: true });

      const request = {
        endpoint: `acciones-empresa/${selectedStock.id_accion}/`,
        method: "PATCH",
        data: { notas: notesForm.getFieldValue("notes") }
      };

      await http(request);

      setNotesModalState(DEFAULT_MODAL_STATE);
      notesForm.resetFields();

      notification.success({ message: '¡Correcto!', description: 'Actualizadas correctamente.' });

      getStocks();
    } catch (error) {

      if (error?.errorFields) return false;

      setNotesModalState({ visible: true, loading: false });
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const onCancelAdminClick = () => {
    setAdminModalState(DEFAULT_MODAL_STATE);
    adminForm.resetFields();
  }

  const onCancelNotesClick = () => {
    setNotesModalState(DEFAULT_MODAL_STATE);
    notesForm.resetFields();
  }

  const onCancelPriceClick = () => {
    setPriceModalState(DEFAULT_MODAL_STATE);
    priceForm.resetFields();
  }

  const pageHeaderExtras = ([
    <Button
      onClick={() => navigate(`/pages/companies/${id}/templates`)}
      type="primary"
      size="small"
      key={"templateBtn"}
      ghost
    >
      Formatos
    </Button>,
    <Button
      onClick={() => navigate(`/pages/companies/${id}/update`)}
      key={"editBtn"}
      type="primary"
      size="small"
      ghost
    >
      Editar
    </Button>
  ]);

  const adminCardExtra = (
    <Button
      key='administratorCardNewBtn'
      type='primary'
      size={"small"}
      onClick={() => setAdminModalState({ visible: true, loading: false })}
      ghost
    >
      Nuevo
    </Button>
  );

  const priceCardExtra = (
    <Button
      key='priceCardNewBtn'
      type='primary'
      size={"small"}
      onClick={() => setPriceModalState({ visible: true, loading: false })}
      ghost
    >
      Nueva
    </Button>
  );

  const stockCardExtra = (
    <Button
      key={getUniqueKey()}
      type='primary'
      size={"small"}
      onClick={() => navigate(`/pages/companies/${id}/increase-in-capital`)}
      ghost
    >
      Aumento Capital
    </Button>
  );

  const documentCardExtra = (
    <Button
      onClick={() => setDocumentModalState({...documentModalState, createVisible: true})}
      type="primary"
      size="small"
      ghost
    >
      Agregar
    </Button>
  );

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company &&
        <>
          <PageHeader
            title={company?.nombre_corto}
            onBack={() => navigate("/pages/companies/overview")}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderExtras}
          >
            <Descriptions size="small" column={{ xs: 1, sm: 3 }}>
              <Descriptions.Item label="Razón Social">
                {company?.razon_social}
              </Descriptions.Item>
              <Descriptions.Item label="Precio MID">
                {maskBigNumber(company?.valoracion_empresa?.precio, "currency")}
              </Descriptions.Item>
              <Descriptions.Item label="Estatus">
                {company?.status_d1?.nombre === "Activo" && <Tag color="green">{company?.status_d1?.nombre}</Tag>}
                {company?.status_d1?.nombre !== "Activo" && <Tag>{company?.status_d1?.nombre}</Tag>}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card
            title="Acciones"
            bordered={false}
            className="mt1"
            extra={stockCardExtra}
          >
            <CustomTable config={stockCustomTableConfig} />
          </Card>
          <Card
            title="Precio MID"
            bordered={false}
            className="mt1"
            extra={priceCardExtra}
          >
            <CustomTable config={priceCustomTableConfig} />
          </Card>
          <Card
            title="Consejo de Administración"
            bordered={false}
            className="mt1"
            style={{ height: "calc(100% - 0.5rem)"}}
            extra={adminCardExtra}
          >
            <CustomTable config={adminCustomTableConfig} />
          </Card>
          <Card
            title="Documentos"
            bordered={false}
            className="mt1"
            extra={documentCardExtra}
          >
            <Table
              columns={documentColumns}
              dataSource={documents}
              loading={isDocumentTableLoading}
              size={"small"}
              rowKey="id"
            />
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
      {adminForm &&
        <Modal
          title={(selectedAdmin) ? "Editar Integrante" : "Nuevo Integrante"}
          visible={adminModalState.visible}
          onOk={() => (selectedAdmin) ? onEditAdminClick() : onCreateAdminClick()}
          confirmLoading={adminModalState.loading}
          onCancel={onCancelAdminClick}
        >
          <Form layout='vertical' form={adminForm}>
            <Form.Item
              label="Nombre"
              name="shareholder"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={(selectedAdmin)}
                filterOption={(input, option) => `${option.key}`.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  availableShareholders.map(
                    ({id, nombre_rs}) => (
                      <Option
                        key={`${nombre_rs}-${id}`}
                        value={id}
                      >
                        {nombre_rs}
                      </Option>
                    )
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="Posición"
              name="position"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <Select>
                {
                  positions.map(
                    ({id, valor, nombre}) => (
                      <Option key={`position-${id}`} value={valor}>{nombre}</Option>
                    )
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="Tipo"
              name="type"
            >
              <Select>
                <Option key={getUniqueKey()} value={""}>Seleccionar</Option>
                {
                  adminTypes.map(
                    ({id, valor, nombre}) => (
                      <Option key={`type-${id}`} value={valor}>{nombre}</Option>
                    )
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Puede votar?"
              name="canVote"
              initialValue={"1"}
              rules={COMMON_REQUIRED_FORM_RULE}
            >
              <Radio.Group>
                <Radio value="1">Si</Radio>
                <Radio value="0">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      }
      <Modal
        title={"Nueva Valoración"}
        visible={priceModalState.visible}
        onOk={onCreatePriceClick}
        confirmLoading={priceModalState.loading}
        onCancel={onCancelPriceClick}
      >
        <Form layout='vertical' form={priceForm}>
          <Form.Item
            label="Valoración"
            name="price"
            rules={COMMON_REQUIRED_FORM_RULE}
            hasFeedback
          >
            <InputNumber
              addonBefore={"$"}
              className={"w100"}
              formatter={value => maskBigNumber(value)}
              parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
              min={0}
              controls={false}
              autoFocus
            />
          </Form.Item>
          <Form.Item
            label={"Fecha"}
            name="date"
            rules={COMMON_REQUIRED_FORM_RULE}
            hasFeedback
          >
            <DatePicker
              format={DATE_FORMAT}
              className="w100"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={"Modificar"}
        visible={notesModalState.visible}
        onOk={onUpdateNotesClick}
        confirmLoading={notesModalState.loading}
        onCancel={onCancelNotesClick}
      >
        <Form layout='vertical' form={notesForm}>
          <Form.Item label="Notas" name="notes" hasFeedback>
            <Input.TextArea maxLength={250} />
          </Form.Item>
        </Form>
      </Modal>
      {documentForm &&
        <Modal
          title="Subir Documento"
          visible={documentModalState.createVisible}
          onOk={onDocumentCreateOkClick}
          confirmLoading={documentModalState.createLoading}
          maskClosable={false}
          onCancel={
            () => {
              documentForm.resetFields();
              setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);
            }
          }
        >
          <Form layout="vertical" form={documentForm}>
            <Form.Item
              label="Etiqueta"
              name="name"
              initialValue={""}
            >
              <Input type={"text"} />
            </Form.Item>
            <Form.Item
              label="Archivo"
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={COMMON_REQUIRED_FORM_RULE}
            >
              <Upload
                {...documentProps}
                maxCount={1}
                accept={COMMON_ACCEPT_FILE_FORMATS}
              >
                <Button className="w100" icon={<UploadOutlined />}>
                  Seleccionar
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      }
      {selectedDocument &&
        <Modal
          title="Eliminar Documento"
          visible={documentModalState.deleteVisible}
          onOk={onDeleteDocumentClick}
          confirmLoading={documentModalState.deleteLoading}
          maskClosable={false}
          onCancel={
            () => {
              setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);
              setSelectedDocument(undefined);
            }
          }
        >
          <Text>
            ¿Estas seguro(a) de remover el documento "{selectedDocument?.nombre_documento}"?
          </Text>
        </Modal>
      }
    </>
  )
}

export default CompanyDetails;