import {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import http from '../../../../services/http.service';
import PageLoader from "../../../../components/page-loader/PageLoader";
import CustomTable from "../../../../components/custom-table/CustomTable";
import ErrorResult from "../../../../components/error-result/ErrorResult";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../../constants";
import { maskBigNumber, maskPercentage } from "../../../../shared/utils";
import { itemRender } from "../../../../shared/helpers/breadcrumb";
import {
  PageHeader,
  Typography,
  Descriptions,
  Card
} from 'antd';

const CompanyStocksDetails = () => {

  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();
  const { companyId, shareholderId } = useParams();
  
  const { Text } = Typography;

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${companyId}/stocks`,
      breadcrumbName: `${company?.empresa?.nombre_corto}`,
    },
    {
      path: `/pages/shareholders/${shareholderId}/details`,
      breadcrumbName: `${company?.accionista?.nombre_rs}`,
    },
    {
      path: `/pages/companies/${companyId}/stocks/${shareholderId}/details`,
      breadcrumbName: 'Posición Actual',
    }
  ];

  const columns = [
    {
      title: 'Serie / Clase',
      dataIndex: 'serie_clase',
      align: "center",
      width: 100
    },
    {
      title: 'Posición Inicial',
      dataIndex: 'cantidad_inicial',
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: 'Posición Actual',
      dataIndex: 'cantidad_actual',
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    }
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = `empresas/${companyId}/posicion-actual/accionistas/${shareholderId}/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCompany(response);

      setCustomTableConfig({
        data: response.desgloses,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `empresas/${companyId}/posicion-actual/accionistas/${shareholderId}/`,
        url: endpoint,
        rowKey: "serie_clase",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company &&
        <>
          <PageHeader
            title={`${company?.accionista?.nombre_rs}`}
            subTitle={`${company?.empresa?.nombre_corto}`}
            onBack={() => navigate(`/pages/companies/${companyId}/stocks`)}
            breadcrumb={{ routes, itemRender }}
          >
            <Descriptions size="small" column={{ xs: 1, sm: 2 }}>
              <Descriptions.Item label="Posición Inicial">
                <Text>
                  {company.posicion_inicial}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Posición Actual">
                <Text>
                  {company.posicion_actual}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Porcentaje Inicial">
                <Text>
                  {maskPercentage(company.porcentaje_inicial)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Porcentaje Actual">
                <Text>
                  {maskPercentage(company.porcentaje_actual)}
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card className={"mt1"}>
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
    </>
  )
}

export default CompanyStocksDetails;