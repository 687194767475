import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Descriptions,
  Card,
  Steps,
  Table,
  Badge,
  notification,
  Typography,
  Modal,
  Tooltip
} from "antd";
import {
  BellOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { DownloadButton, ErrorResult, PageLoader } from "../../../components";
import http from "../../../services/http.service";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { BASE_API_URL } from "../../../constants";
import useAuth from "../../../hooks/useAuth";
import {
  maskBigNumber,
  maskPercentage,
  getDiffDates,
  getUniqueKey
} from "../../../shared/utils";
import EditExtrasButton from "../_components/EditExtras.button";

const TransactionDetail = () => {

  const DEFAULT_MODAL_STATE = {
    name: "",
    text: "",
    visible: false,
    loading: false,
    metadata: {}
  };

  const [isPageLoading, setPageLoading] = useState(true);
  const [isRefreshing, setRefreshing] = useState(false);
  const [pageError, setPageError] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [isActionLoading, setActionLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [shareholders, setShareholders] = useState([]);
  const [pageHeaderButtons, setPageHeaderButtons] = useState([]);
  const [confirmationModalState, setConfirmationModalState] = useState(DEFAULT_MODAL_STATE);
  const [canGoContracts, setCanGoContracts] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  let { id } = useParams();
  let refreshInterval = undefined;

  const { Text, Paragraph } = Typography;
  const { Step } = Steps;

  const openConfirmationModal = (name, metadata = {}) => {
    let text = "";
    if (name === "cancel") text = "A continuación se cancelará la transacción.";
    if (name === "notificationLetter") text = "A continuación se generará la carta de notificación.";
    if (name === "offer") text = "A continuación se notificará a los accionistas.";
    if (name === "cancelSaleRequest") text = "A continuación se cancelará la intención de venta.";
    if (name === "finishStage") text = "A continuación se finalizará la etapa.";
    if (name === "cancelOffer") text = "A continuación se cancelará la oferta de compra.";
    if (name === "finalize") {
      if (metadata.count === 0) {
        text = "A continuación se finalizará la transacción.";
      } else {
        text = `A continuación se finalizará la transacción con ${metadata.count} documentos pendientes por firmar.`;
      }
    }

    setConfirmationModalState({
      name,
      text,
      visible: true,
      loading: false,
      metadata
    });
  }

  const onActionModal = (close = null) => {
    if (close) {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      return;
    }
    if (confirmationModalState.name === "cancel") onCancelClick();
    if (confirmationModalState.name === "finalize") onFinalizeClick();
    if (confirmationModalState.name === "notificationLetter") onNotificationLetterClick();
    if (confirmationModalState.name === "offer") onOfferClick();
    if (confirmationModalState.name === "cancelSaleRequest") onCancelSaleRequestClick();
    if (confirmationModalState.name === "cancelOffer") onCancelOfferClick();
    if (confirmationModalState.name === "finishStage") onFinishStageClick();
  }

  const setConfig = (status) => {
    if (status === 1) {
      const hasDocuments = transaction?.detalles_transaccion.some((detail) => detail.status_d7.valor === "15");
      if (hasDocuments) {
        setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.calculate, actionButtons.notificationLetter]);
      } else {
        setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.calculate, actionButtons.notificationLetter]);
      }
    }
    if (status === 2) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.finishStage]);
      setCurrentStep(1);
    }
    if (status === 3) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.extras]);
      setCurrentStep(2);
    }
    if (status === 4) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.invite]);
      setCurrentStep(3);
    }
    if (status === 5) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.contracts]);
      setCurrentStep(4);
    }
    if (status === 55) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.contracts, actionButtons.close]);
      setCurrentStep(4);
    }
    if (status === 7) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.close]);
      setCurrentStep(5);
    }
    if (status === 8) {
      setCurrentStep(5);
      setPageHeaderButtons([actionButtons.report, actionButtons.documents]);
    }
    if (status === 9) {
      setPageHeaderButtons([actionButtons.report, actionButtons.documents]);
      setCurrentStep(5);
    }
    if (status === 11) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.signLetter]);
      setCurrentStep(1);
    }
    if (status === 12) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.offer]);
      setCurrentStep(1);
    }
    if (status === 111) {
      setPageHeaderButtons([actionButtons.cancel, actionButtons.report, actionButtons.documents, actionButtons.webhook]);
      setCurrentStep(1);
    }
  }

  const onRefresh = async () => {
    try {
      setRefreshing(true);
      const endpoint = `transacciones/${id}/detalle/`;
      const response = await http({ endpoint });
      const hasAlertActive = response?.detalles_transaccion.some(({alerta_status}) => alerta_status === "A");
      if (hasAlertActive) setCanGoContracts(false);
      setConfig(response.status_d6);
      setTransaction(response);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
    }
  };

  const onCancelSaleRequestClick = async () => {
    try {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      setActionLoading(true);
      const request = {
        endpoint: `transacciones/${id}/intenciones-venta/${confirmationModalState?.metadata?.shareholder}/`,
        method: "DELETE",
        data: null
      };
      await http(request);
      sessionStorage.setItem('canRefresh', 'true');
      onRefresh();
      setActionLoading(false);
      notification.success({ message: '¡Correcto!', description: "Intención de venta cancelada." });
    } catch (error) {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      setActionLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  };

  const onCancelOfferClick = async () => {
    try {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      setActionLoading(true);
      const request = {
        endpoint: `accionistas/${confirmationModalState?.metadata?.shareholder}/derechos-oferta/${id}/`,
        method: "DELETE",
        data: null
      };

      await http(request);
      sessionStorage.setItem('canRefresh', 'true');
      onRefresh();
      setActionLoading(false);
      notification.success({ message: '¡Correcto!', description: "Oferta cancelada." });
    } catch (error) {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      setActionLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  };

  const getTransaction = async () => {
    try {
      setPageLoading(true);
      setPageError(undefined);
      sessionStorage.removeItem("isCalculated");
      const endpoint = `transacciones/${id}/detalle/`;
      const response = await http({ endpoint });
      const customShareholders = response.detalles_transaccion.map((current) => { return { text: current?.accionista?.nombre_rs, value: current?.accionista?.nombre_rs } });
      const hasAlertActive = response?.detalles_transaccion.some(({alerta_status}) => alerta_status === "A");
      if (hasAlertActive) setCanGoContracts(false);
      setTransaction(response);
      setShareholders(customShareholders);
      setConfig(response.status_d6);
      if (response.status_d6 !== 1) setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      setPageError(error);
    }
  };

  const onCancelClick = async () => {
    try {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      setActionLoading(true);
      const request = {
        endpoint: `transacciones/${id}/cancelar/`,
        method: "PATCH",
        data: null
      };
      await http(request);
      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Transacción cancelada correctamente",
          });
          navigate(`/pages/transactions/overview`);
        },
        1500
      );
    } catch (error) {
      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1500
      );
    }
  }

  const onValidateDocumentsClick = async () => {
    try {
      setActionLoading(true);
      const request = {
        endpoint: `transacciones/${id}/documentos-por-firmar/`
      };

      const { documentos } = await http(request);
      setActionLoading(false);
      openConfirmationModal("finalize", { count: documentos })
    } catch (error) {
      setActionLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const onFinalizeClick = async () => {
    try {
      setConfirmationModalState(DEFAULT_MODAL_STATE);
      setActionLoading(true);
      const request = {
        endpoint: `transacciones/${id}/finalizar/`,
        method: "PATCH",
        data: null
      };
      await http(request);
      sessionStorage.setItem('canRefresh', 'false');
      setPageHeaderButtons([actionButtons.report, actionButtons.documents,]);
      setCurrentStep(6);
      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Transacción finalizada correctamente",
          });
        },
        1500
      );

    } catch (error) {
      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1500
      );
    }
  };

  const onDocumentsClick = () => navigate(`/pages/transactions/${id}/documents`);

  const onContractsClick = () => {
    if (canGoContracts) {
      navigate(`/pages/transactions/${id}/contracts`)
    } else {
      notification.error({
        message: '¡Ups!',
        description: "Aún hay alertas sin resolver",
      });
    }
  };

  const onSignOfferClick = (shareholderId) => navigate(`/pages/transactions/${id}/sign-offer/${shareholderId}`);
  const onSettlementClick = () => navigate(`/pages/transactions/${id}/settlement`);
  const onInviteClick = () => navigate(`/pages/transactions/${id}/invite`);
  const onAlertClick = (detailId) => navigate(`/pages/transactions/${id}/details/${detailId}/alerts`);

  const onAssignExtraClick = async () => {
    try {
      setActionLoading(true);

      const request = {
        endpoint: `transacciones/${id}/derecho-asignacion-extra/`,
        method: "PUT",
        data: null
      };

      await http(request);

      sessionStorage.setItem('canRefresh', 'true');
      onRefresh();

      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Asignaciones generadas correctamente",
          });
        },
        1500
      );

    } catch (error) {
      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1500
      );
    }
  };

  const onCalculateExtraClick = async () => {
    try {
      setActionLoading(true);

      const request = {
        endpoint: `transacciones/${id}/derecho-calculo-extra/`,
        method: "PUT",
        data: null
      };

      await http(request);

      sessionStorage.setItem('canRefresh', 'false');
      setPageHeaderButtons([actionButtons.cancel, actionButtons.documents, actionButtons.assign]);

      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Calculo de acciones extra generado correctamente",
          });
        },
        1500
      );

      const endpoint = `transacciones/${id}/detalle/`;
      const response = await http({ endpoint });
      setTransaction(response);

    } catch (error) {
      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({ message: '¡Ups!', description: error.message });
        },
        1500
      );
    }
  };

  const onCalculateClick = async () => {
    try {
      const isInitialStatus = transaction.detalles_transaccion.every(({venta_acciones}) => (venta_acciones === 0));

      if (isInitialStatus) {
        notification.warning({ message: '¡Ups!', description: "Aún no hay intenciones de venta creadas." });
        return;
      }

      setActionLoading(true);

      const request = {
        endpoint: `transacciones/${id}/derecho-calculo/`,
        method: "PUT",
        data: null
      };

      const offers = await http(request);

      sessionStorage.setItem("isCalculated", "true");

      let newTransaction = {...transaction};
      let total = 0;

      const newDetail = newTransaction.detalles_transaccion.map((detail) => {
        const shareholderId = detail?.accionista?.id;
        const shareholderOffer = offers.find((offer) => offer.accionista_id === shareholderId);
        const offer = shareholderOffer?.derecho_tanto || 0;
        total += offer;
        return {
          ...detail,
          derecho_oferta_acciones: offer
        }
      });

      newTransaction.detalles_transaccion = newDetail;
      newTransaction.total_derecho_al_tanto_oferta = total;

      sessionStorage.setItem('canRefresh', 'false');
      setTransaction(newTransaction);
      setCurrentStep(1);
      setPageHeaderButtons([actionButtons.cancel, actionButtons.documents, actionButtons.notificationLetter]);

      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Calculado correctamente",
          });
        },
        1500
      );

    } catch (error) {

      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1500
      );

    }

  };

  const onOfferClick = async () => {

    try {

      setActionLoading(true);
      setConfirmationModalState(DEFAULT_MODAL_STATE);

      const request = {
        endpoint: `transacciones/${id}/derecho-oferta/`,
        method: "PUT",
        data: null
      };

      await http(request);

      sessionStorage.removeItem("isCalculated");

      onRefresh();

      sessionStorage.setItem('canRefresh', 'true');
      setPageHeaderButtons([actionButtons.cancel]);
      setConfirmationModalState(DEFAULT_MODAL_STATE);

      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Notificaciones enviadas correctamente.",
          });
        },
        1500
      );
    } catch (error) {

      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1500
      );

    }

  };

  const onNotificationLetterClick = async () => {
    try {
      setConfirmationModalState(DEFAULT_MODAL_STATE);

      const isInitialStatus = transaction.detalles_transaccion.every(({venta_acciones}) => (venta_acciones === 0));

      if (isInitialStatus) {
        notification.warning({ message: '¡Ups!', description: "Aún no hay intenciones de venta creadas." });
        return;
      }

      setActionLoading(true);

      const request = {
        endpoint: `transacciones/${id}/carta-notificacion/`,
        method: "POST",
        data: null
      };

      await http(request);

      sessionStorage.removeItem("isCalculated");

      onRefresh();

      sessionStorage.setItem('canRefresh', 'true');
      setPageHeaderButtons([actionButtons.cancel, actionButtons.signLetter]);
      setConfirmationModalState(DEFAULT_MODAL_STATE);

      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Carta generada correctamente",
          });
        },
        1000
      );
    } catch (error) {

      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1000
      );

    }

  };

  const onFinishStageClick = async () => {

    try {

      setConfirmationModalState(DEFAULT_MODAL_STATE);

      const allHaveResponses = transaction.detalles_transaccion.every(({derecho_resultado}) => (derecho_resultado !== null));

      if (!allHaveResponses) {
        notification.warning({ message: '¡Ups!', description: "Aún hay ofertas sin firmar." });
        return;
      }

      setActionLoading(true);

      const request = {
        endpoint: `transacciones/${id}/derecho-oferta/`,
        method: "PATCH",
        data: null
      };

      await http(request);

      onRefresh();
      sessionStorage.setItem('canRefresh', 'true');
      setPageHeaderButtons([actionButtons.cancel, actionButtons.documents, actionButtons.extras]);

      setTimeout(
        () => {
          setActionLoading(false);
          notification.success({
            message: '¡Correcto!',
            description: "Etapa finalizada correctamente",
          });
        },
        1000
      );
    } catch (error) {

      setTimeout(
        () => {
          setActionLoading(false);
          notification.error({
            message: '¡Ups!',
            description: error.message,
          });
        },
        1000
      );

    }

  };

  const onSignLetterClick = (e) => {
    navigate(`/pages/transactions/sign-documents?rfc=${user?.shareholder?.rfc}&operationId=${transaction.operation_id}&transactionId=${id}&messageId=1115&goBack=/pages/transactions/${id}/details`);
  }

  const getTableSummary = (pageData) => {
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_posicion_inicial_acciones || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_venta || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_derecho_al_tanto_oferta || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_derecho_al_tanto_compra || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_derecho_solicita_extra || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_asignacion_extra || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_nuevos_socios_oferta || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_nuevos_socios_compra || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={10}>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={11} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_posicion_final_acciones || 0)}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={12} align={"center"}>
            <strong>
              {maskBigNumber(transaction?.total_variacion_acciones || 0)}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/transactions/overview",
      breadcrumbName: "Transacciones",
    },
    {
      path: `/pages/transactions/${id}/details`,
      breadcrumbName: "Detalle",
    },
  ];

  const steps = [
    {
      title: 'Inicio',
    },
    {
      title: 'Socios Actuales'
    },
    {
      title: 'Extras'
    },
    {
      title: 'Nuevos Socios'
    },
    {
      title: 'Contratos'
    },
    {
      title: 'Fin'
    },
  ];

  const purchaseStatuses = {
    "COMPRA": <Text type="success"><small>Compra</small></Text>,
    "COMPRA+": <Text type="success"><small>Compra + Extra</small></Text>,
    "RENUNCIA": <Text type="secondary"><small>Renuncia</small></Text>,
    "VENCIO": <Text type="secondary"><small>Renuncia (V)</small></Text>
  };

  const saleRequestStatuses = {
    "11": <Text type="secondary"><small>Por firmar</small></Text>,
    "14": <Text type="danger"><small>Error en firma</small></Text>,
  };

  const columns = [
    {
      title: 'Expediente',
      dataIndex: 'accionista',
      fixed: 'left',
      align: "center",
      width: 300,
      filters: shareholders,
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.accionista.nombre_rs.startsWith(value),
      render: (cell, row) => (
        <Badge dot={!row.posicion_inicial_acciones} color={"blue"} offset={[10, 10]}>
          <Text>
            {cell?.nombre_rs}
          </Text>
        </Badge>
      )
    },
    {
      title: "Posición Inicial",
      dataIndex: "posicion_inicial_acciones",
      align: "center",
      width: 150,
      render: (cell, row) => (
        <>
          <Text>
            {maskBigNumber(cell)}
          </Text>
          <br/>
          <Text type="secondary">
            <small>
              {maskPercentage(row.posicion_inicial_porcentaje)}
            </small>
          </Text>
        </>
      )
    },
    {
      title: "Vende",
      dataIndex: "venta_acciones",
      align: "center",
      width: 120,
      render: (cell, { status_d7, accionista, venta_acciones }) => (
        <>
          <Text type={(cell) ? "default": "secondary"}>
            {maskBigNumber(cell)}
          </Text>
          <br/>
          {saleRequestStatuses[status_d7.valor] &&
            <Paragraph ellipsis className="my0">
              {saleRequestStatuses[status_d7.valor] || ""}
            </Paragraph>
          }
          {venta_acciones > 0 && transaction?.status_d6 === 1  &&
            <Tooltip title="Cancelar intención de venta">
              <Button
                shape="circle"
                className="noBorderColor bgTransparent"
                icon={<CloseCircleOutlined />}
                onClick={() => openConfirmationModal('cancelSaleRequest', { shareholder: accionista.id })}
              />
            </Tooltip>
          }
        </>
      )
    },
    {
      title: "Derecho Al Tanto",
      children: [
        {
          title: "Oferta",
          dataIndex: "derecho_oferta_acciones",
          align: "center",
          width: 120,
          render: (cell, { derecho_resultado, accionista }) => (
            <>
              <Text type={(cell) ? "default": "secondary"}>
                {maskBigNumber(cell)}
              </Text>
              <br/>
              {!derecho_resultado && transaction?.status_d6 === 2  &&
                <Tooltip title="Registrar respuesta">
                  <Button
                    shape="circle"
                    className="noBorderColor bgTransparent"
                    icon={<EditOutlined />}
                    onClick={() => onSignOfferClick(accionista.id)}
                  />
                </Tooltip>
              }
            </>
          )
        },
        {
          title: "Compra",
          dataIndex: "derecho_compra",
          align: "center",
          width: 120,
          render: (cell, { derecho_resultado, accionista, venta_acciones }) => (
            <>
              <Text type={(cell) ? "default": "secondary"}>
                {maskBigNumber(cell)}
              </Text>
              {purchaseStatuses[derecho_resultado] && <br/>}
              {purchaseStatuses[derecho_resultado]}
              <br />
              {derecho_resultado && transaction?.status_d6 === 2 && venta_acciones === 0 &&
                <Tooltip title="Cancelar respuesta">
                  <Button
                    shape="circle"
                    className="noBorderColor bgTransparent"
                    icon={<CloseCircleOutlined />}
                    onClick={() => openConfirmationModal('cancelOffer', { shareholder: accionista.id })}
                  />
                </Tooltip>
              }
            </>
          )
        },
        {
          title: "Extra",
          dataIndex: "derecho_solicita_extra",
          width: 120,
          align: "center",
          render: (cell, row) => {
            if (cell > 0) {
              return (
                <>
                  <Text type={(cell) ? "default": "secondary"}>{maskBigNumber(cell)}</Text>
                  <br />
                  {transaction?.status_d6 === 3 &&
                    <EditExtrasButton transaction={transaction.id} metadata={row} onFinish={() => onRefresh()} />
                  }
                </>)
            } else {
              return (<Text type={(cell) ? "default": "secondary"}>{maskBigNumber(cell)}</Text>)
            }
          }
        },
      ]
    },
    {
      title: 'Asignación Extra',
      dataIndex: 'extra_asignado',
      align: "center",
      width: 120,
      render: (cell) => (
        <Text type={(cell) ? "default": "secondary"}>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: "Nuevo Socio",
      children: [
        {
          title: "Oferta",
          width: 120,
          dataIndex: "nuevo_socio_oferta",
          align: "center",
          render: (cell) => (
            <Text type={(cell) ? "default": "secondary"}>
              {maskBigNumber(cell)}
            </Text>
          )
        },
        {
          title: "Resultado",
          dataIndex: "nuevo_socio_resultado",
          align: "center",
          width: 120,
          render: (cell) => (
            <Text type={(cell === "COMPRA") ? "success": "secondary"}>
              {cell === "COMPRA" && <small>Compra</small>}
              {cell === "RENUNCIA" && <small>Renuncia</small>}
            </Text>
          )
        }
      ]
    },
    {
      title: 'Alerta',
      dataIndex: 'alerta_status',
      align: "center",
      width: 70,
      render: (cell, row) => (
        (cell !== null && cell === "A")
          ? <Button shape="circle" danger size="small" icon={<BellOutlined onClick={() => onAlertClick(row.id)} />} />
          : null
      )
    },
    {
      title: 'Contratos',
      dataIndex: 'contratos_firmados_fh',
      align: "center",
      width: 100,
      render: (cell) => (
        (cell)
          ? <Badge color={"green"} />
          : <Badge color={"#d9d9d9"} />
      )
    },
    {
      title: "Posición Final",
      dataIndex: "posicion_final_acciones",
      align: "center",
      width: 150,
      render: (cell, row) => (
        <>
          <Text>
            {maskBigNumber(cell)}
          </Text>
          <br/>
          <Text type="secondary">
            <small>
              {maskPercentage(row.posicion_final_porcentaje)}
            </small>
          </Text>
        </>
      )
    },
    {
      title: "Variación",
      dataIndex: "resultado_acciones",
      align: "center",
      width: 120,
      render: (cell, row) => (
        <>
          <Text>
            {maskBigNumber(cell)}
          </Text>
          <br/>
          <Text type="secondary">
            <small>
              ({maskPercentage(row.variacion)})
            </small>
          </Text>
        </>
      )
    },
  ];

  const actionButtons = {
    webhook: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        disabled={!transaction}
        onClick={() => false}
        ghost
      >
        Procesando firma
      </Button>
    ),
    calculate: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        disabled={!transaction}
        onClick={() => onCalculateClick()}
        ghost
      >
        Calcular derecho al tanto
      </Button>
    ),
    offer: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={() => openConfirmationModal("offer")}
        ghost
      >
        Notificar accionistas
      </Button>
    ),
    notificationLetter: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={() => openConfirmationModal("notificationLetter")}
        ghost
      >
        Generar carta notificación
      </Button>
    ),
    signLetter: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        ghost
        onClick={onSignLetterClick}
      >
        Firmar carta notificación
      </Button>
    ),
    finishStage: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        ghost
        onClick={() => openConfirmationModal("finishStage")}
      >
        Finalizar etapa
      </Button>
    ),
    extras: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={onCalculateExtraClick}
        ghost
      >
        Calcular extras
      </Button>
    ),
    assign: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={onAssignExtraClick}
        ghost
      >
        Asignar extras
      </Button>
    ),
    invite: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={onInviteClick}
        ghost
      >
        Invitar nuevos socios
      </Button>
    ),
    documents: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        onClick={() => onDocumentsClick()}
      >
        Documentos
      </Button>
    ),
    contracts: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        onClick={() => onContractsClick()}
        type={"primary"}
        ghost
      >
        Contratos
      </Button>
    ),
    settlement: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={onSettlementClick}
        ghost
      >
        Liquidación
      </Button>
    ),
    close: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        type={"primary"}
        onClick={() => onValidateDocumentsClick()}
        ghost
      >
        Finalizar
      </Button>
    ),
    cancel: (
      <Button
        size={"small"}
        key={getUniqueKey()}
        onClick={() => openConfirmationModal("cancel")}
      >
        Cancelar
      </Button>
    ),
    report: (
      <DownloadButton
        key={getUniqueKey()}
        tooltip={"Descargar Reporte"}
        url={`${BASE_API_URL}/transacciones/${id}/sabana/`}
        fileName={`reporte_transaccion_${id}.xslx`}
        className={"default"}
        shape={"default"}
        value={"Reporte"}
      />
    ),
  };

  useEffect(() => {
    getTransaction();
    sessionStorage.setItem('canRefresh', 'true');
  }, []);

  useEffect(
    () => {
      if (transaction && transaction.status_d6 !== 1 && transaction.status_d6 !== 3) {
        setConfig(transaction.status_d6);
      }
    },
    [transaction]
  );

  useEffect(
    () => {
      if (transaction && !refreshInterval) {
        refreshInterval = setInterval(() => {
          const canRefresh = sessionStorage.getItem('canRefresh')
          if (canRefresh === 'true') onRefresh();
        }, 30000);
      }
      if (transaction && transaction.status_d6 === 1) {
        const isCalculated = sessionStorage.getItem("isCalculated");
        if (!isCalculated) {
          setConfig(transaction.status_d6);
          setPageLoading(false);
        }
      }
      return () => clearInterval(refreshInterval);
    },
    [transaction]
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && transaction &&
        <>
          <PageHeader
            title={`Detalle Transacción ${transaction?.id}`}
            onBack={() => navigate("/pages/transactions/overview")}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderButtons}>
            <Descriptions size="small">
              <Descriptions.Item label="Empresa">
                {transaction?.empresa}
              </Descriptions.Item>
              <Descriptions.Item label="Precio">
                {maskBigNumber(transaction?.precio, "currency")}
              </Descriptions.Item>
              {transaction?.notificacion_venta_plazo_vencimiento_fh &&
                <Descriptions.Item label="Plazo para notificar">
                  {getDiffDates(transaction?.notificacion_venta_plazo_vencimiento_fh) || "Vencido"}
                </Descriptions.Item>
              }
              <Descriptions.Item label="Condiciones">
                <Tooltip title={transaction?.condiciones}>
                  <Paragraph ellipsis className="my0" style={{maxWidth: '210px'}}>
                    {transaction?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card className="mt1">
            <Steps current={currentStep} size="small" labelPlacement="vertical">
              {steps.map(item => (<Step key={item.title} title={item.title} />))}
            </Steps>
            <div className="tableContainer">
              <Table
                columns={columns}
                className="mt1"
                dataSource={transaction.detalles_transaccion}
                loading={isRefreshing}
                summary={getTableSummary}
                bordered
                size="small"
                rowKey={"id"}
                scroll={{x: 'calc(700px + 50%)'}}
              />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
       <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getTransaction}
          exitPath={"/pages/dashboard"}
        />
      }
      {confirmationModalState &&
        <Modal
          title="Confirmación"
          visible={confirmationModalState.visible}
          maskClosable={false}
          onOk={() => onActionModal()}
          okText={"Aceptar"}
          onCancel={() => onActionModal('close')}
        >
          <Text>
            {confirmationModalState.text}
          </Text>
        </Modal>
      }
      <Modal
        title="Cargando"
        visible={isActionLoading}
        maskClosable={false}
        footer={null}
        closable={false}
        centered
      >
        <LoadingOutlined />
        <Text className="ml1">
          Espere un momento por favor ...
        </Text>
      </Modal>
    </>
  );
};

export default TransactionDetail;