import { useState } from 'react';
import { Tooltip, Button, notification } from 'antd';
import { saveAs } from 'file-saver';
import http from "../../services/http.service";
import { BASE_API_URL } from '../../constants';
import { getUniqueKey } from '../../shared/utils';

/**
 * Download Button
 * Component that extract the file endpoint from URL and generate a signed url
 * @param { tooltip } props: tooltip text
 * @param { url } props: https://dev-api.accionistasintercam.com.mx/documento-link/3331/
 * @param { icon } props: Ant Design Icon <FilePdfOutlined />
 * @param { fileName } props: file name
 * @returns {Component}
 */
const DownloadButton = (props) => {
  const [loading, setLoading] = useState(false)

  const onDownloadClick = async (url) => {
    try {
      if (!url) return;

      const endpoint = url.split(`${BASE_API_URL}/`).join('');
      setLoading(true);

      const response = await http({ endpoint });

      setLoading(false);
      saveAs(response, props.fileName)
    } catch (error) {
      setLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  return (
    <Tooltip
      placement="top"
      title={props.tooltip || 'Descargar'}
      key={getUniqueKey()}>
        <Button
          key={getUniqueKey()}
          size={props.size || "small"}
          onClick={() => onDownloadClick(props.url)}
          shape={props.shape || "circle"}
          icon={props.icon}
          loading={loading}
          className={props.className || "noBorderColor bgTransparent"}
        >
          {props.value || ''}
        </Button>
    </Tooltip>
  );
}

export default DownloadButton;