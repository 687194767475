import {
  useState,
  useEffect
} from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import http from "../../../services/http.service";
import PageLoader from "../../../components/page-loader/PageLoader";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import {
  PageHeader,
  Badge,
  Descriptions,
  Tooltip,
  Table,
  Space,
  Button,
  notification
} from "antd";

const EventVotes = () => {
  const [activity, setActivity] = useState(undefined);
  const [event, setEvent] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [votes, setVotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let { eventId, activityId } = useParams();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
    {
      path: `/pages/events/${eventId}/manage`,
      breadcrumbName: `${event?.nombre_evento}`,
    },
    {
      path: `/pages/events/${eventId}/activity/${activityId}/votes`,
      breadcrumbName: "Votos",
    },
  ];

  const getData = async () => {
    try {
      setError(undefined);
      setLoading(true);

      const response = await http({ endpoint: `eventos/${eventId}/` });

      const selectedActivity = response.agenda.find((current) => current.id == activityId);
      let selectedVotes = [];

      selectedActivity.votantes.a_favor.forEach((current) => selectedVotes.push({ id: current.pk, nombre: current.nombre_rs, resultado: "A Favor" }));
      selectedActivity.votantes.en_contra.forEach((current) => selectedVotes.push({ id: current.pk, nombre: current.nombre_rs, resultado: "En Contra" }));
      selectedActivity.votantes.abstencion.forEach((current) => selectedVotes.push({ id: current.pk, nombre: current.nombre_rs, resultado: "Abstención" }));
      selectedActivity.votantes.pendiente.forEach((current) => selectedVotes.push({ id: current.pk, nombre: current.nombre_rs, resultado: "Pendiente" }));

      setEvent(response);
      setVotes(selectedVotes);
      setActivity(selectedActivity);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onVoteClick = async (shareholderId, status) => {
    try {
      const request ={
        endpoint: `eventos/${eventId}/participantes/${shareholderId}/votaciones/${activityId}/`,
        method: "PATCH",
        data: { accion: status }
      };

      await http(request);

      getData();
    } catch (error) {
      notification.error({ message: "¡Ups!", description: "No se pudo votar.", });
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip placement='topLeft' title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Votación",
      dataIndex: "id",
      render: (cell, row) => (
        <Space>
          <>
            <Button
              type={(row.resultado === 'A Favor') ? 'primary' : 'default'}
              size={"small"}
              onClick={() => onVoteClick(cell, "A FAVOR")}>
              A Favor
            </Button>
            <Button
              type={(row.resultado === 'En Contra') ? 'primary' : 'default'}
              size={"small"}
              onClick={() => onVoteClick(cell, "EN CONTRA")}>
              En Contra
            </Button>
            <Button
              type={(row.resultado === 'Abstención') ? 'primary' : 'default'}
              size={"small"}
              onClick={() => onVoteClick(cell, "ABSTENCION")}>
              Abstención
            </Button>
          </>
        </Space>
      )
    },
  ];

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error &&
        <>
          <PageHeader
            title={activity?.descripcion}
            breadcrumb={{ routes, itemRender }}
            onBack={() => navigate(-1)}
          >
            <Descriptions size="small" column={{xs: 1, sm: 2, lg: 4, xl: 4}}>
              <Descriptions.Item>
                  <Badge color={"blue"} text={`Pendientes: ${activity?.resultado_votacion?.pendiente}`} /> 
              </Descriptions.Item>
              <Descriptions.Item>
                <Badge color={"green"} text={`A Favor: ${activity?.resultado_votacion?.a_favor}`} />
              </Descriptions.Item>
              <Descriptions.Item>
                <Badge color={"red"} text={`En Contra: ${activity?.resultado_votacion?.en_contra}`} />
              </Descriptions.Item>
              <Descriptions.Item>
                <Badge color={"yellow"} text={`Abstención: ${activity?.resultado_votacion?.abstencion}`} />
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <div className="tableContainer">
            <Table
              columns={columns}
              dataSource={votes}
              size={"small"}
              rowKey={"id"}
            />
          </div>
        </>
      }
      {!loading && error && (
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getData}
          exitPath={`/pages/events/${eventId}/manage`}
        />
      )}
    </>
  );
};

export default EventVotes