import { useEffect, useState } from "react";
import { useNavigate, useParams  } from "react-router-dom";
import {
  PageHeader,
  Button,
  Space,
  Card,
  Descriptions,
  Tooltip,
  Typography,
  Modal,
  notification
} from "antd";
import {
  FilePdfOutlined,
  FileProtectOutlined,
  MailOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";

import http from "../../../services/http.service";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import { maskBigNumber } from "../../../shared/utils";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import {
  SignDocumentStatusBadge,
  DownloadButton,
  PageLoader,
  CustomTable,
  ErrorResult
} from "../../../components";

const TransactionDocuments = () => {

  const GENERATE_DEFAULT_MODAL_STATE = { text: "", visible: false };

  const [isPageLoading, setPageLoading] = useState(true);
  const [isGenerating, setGenerating] = useState(GENERATE_DEFAULT_MODAL_STATE);
  const [pageError, setPageError] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const navigate = useNavigate();
  const { id } = useParams();

  const { Text, Paragraph } = Typography;
  const { confirm } = Modal;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/transactions/overview",
      breadcrumbName: "Transacciones",
    },
    {
      path: `/pages/transactions/${id}/details`,
      breadcrumbName: `Detalles`,
    },
    {
      path: `/pages/transactions/${id}/documents`,
      breadcrumbName: "Documentos",
    }
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      filterName: "id",
      width: 100
    },
    {
      title: "Documento",
      dataIndex: "nombre_documento",
      filterName: "nombre_documento",
      ellipsis: { showTitle: false },
      render: (cell) => (<Tooltip title={cell}>{cell}</Tooltip>),
    },
    {
      title: "Firmante 1 (Vendedor)",
      dataIndex: "vendedor_accionista_id_fk",
      filterName: "vendedor_accionista_id_fk",
      ellipsis: { showTitle: false },
      render: (_, row) => (
        <Tooltip title={row?.vendedor_accionista_id_fk?.nombre_rs}>
          <Text>
            {row?.vendedor_accionista_id_fk?.nombre_rs}
          </Text>
        </Tooltip>
      )
    },
    {
      title: "Firmante 2 (Comprador)",
      dataIndex: "comprador_accionista_id_fk",
      filterName: "comprador_accionista_id_fk",
      ellipsis: { showTitle: false },
      render: (_, row) => (
        <Tooltip title={row?.comprador_accionista_id_fk?.nombre_rs}>
          <Text>
            {row?.comprador_accionista_id_fk?.nombre_rs}
          </Text>
        </Tooltip>
      )
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      width: 100,
      align: "center",
      render: (cell) => ((cell) ? <Text>{maskBigNumber(cell)}</Text> : "")
    },
    {
      title: "Pendientes",
      dataIndex: "id",
      align: "center",
      render: (_, row) => (
        <>
          {row?.pendientes_x_firmar?.accionista &&
            <Tooltip title={row?.pendientes_x_firmar?.accionista || ""}>
              <Text>
                Firmante 1
              </Text>
            </Tooltip>
          }
          {row?.pendientes_x_firmar?.vendedor &&
            <>
              <Tooltip title={row?.pendientes_x_firmar?.vendedor || ""}>
                <Text>
                  Firmante 1
                </Text>
              </Tooltip>
              <br />
            </>
          }
          {row?.pendientes_x_firmar?.comprador &&
            <Tooltip title={row?.pendientes_x_firmar?.comprador || ""}>
              <Text>
                Firmante 2
              </Text>
            </Tooltip>
          }
        </>
      )
    },
    {
      title: "Mancomunada",
      dataIndex: "tiene_mancomunados",
      width: 115,
      align: "center",
      render: (cell) => ((cell) ? <Text>Si</Text> : <Text>No</Text>)
    },
    {
      title: "Estatus",
      dataIndex: "status_firmas",
      width: 120,
      align: "center",
      render: (cell) => <SignDocumentStatusBadge status={cell} />
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 100,
      render: (_, row) => (
        <Space size={"small"}>
          {row?.link  && (
            <DownloadButton
              tooltip="Descargar PDF"
              url={row.link}
              fileName={row.nombre_archivo}
              icon={<FilePdfOutlined />}
            />
          )}
          {row?.xml_link && (
            <DownloadButton
              tooltip="Descargar XML"
              url={row.xml_link}
              fileName={row.nombre_archivo_xml}
              icon={<FileProtectOutlined />}
            />
          )}
          {row?.tipo_documento_d8 === "LIQUIDACION" &&
            <DownloadButton
              tooltip="Descargar PDF"
              url={row.link}
              fileName={row.nombre_archivo}
              icon={<FilePdfOutlined />}
            />
          }
          {row?.status_firmas !== "FIRMADO" && row?.tiene_mancomunados &&
            <Tooltip placement="top" title={"Reenviar correo"}>
              <Button
                size={"small"}
                onClick={() => confirm({
                  title: 'Confirmación',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Se reenviarán los correos.',
                  okText: 'Reenviar',
                  cancelText: 'Cancelar',
                  onOk() { onResendEmail(row) }
                })}
                shape="circle"
                icon={<MailOutlined />}
                className="noBorderColor bgTransparent"
              />
            </Tooltip>
          }
        </Space>
      ),
    }
  ];

  const onResendEmail = async (row) => {
    try{
      let data = "";
      if (row?.pendientes_x_firmar?.comprador_mancomunado) data = "comprador_mancomunado";
      if (row?.pendientes_x_firmar?.vendedor_mancomunado) data = "vendedor_mancomunado";

      const request = {
        endpoint: `documentos/${row.id}/reenviar-correo-firma/`,
        method: "POST",
        data: { firmante: data }
      };

      setGenerating({ text: "Enviando correos", visible: true });

      await http(request);

      setGenerating(GENERATE_DEFAULT_MODAL_STATE);

      notification.success({ message: '¡Correcto!', description: 'Enviado correctamente.' });
    } catch (error) {
      setGenerating(GENERATE_DEFAULT_MODAL_STATE);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  const getDocuments = async (props = null) => {
    try {
      let endpoint = `transacciones/${id}/documentos/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `transacciones/${id}/documentos/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getDocuments,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {
      setPageError({ message: "No se pudieron obtener los documentos."});
    }
  }

  const getData = async () => {
    try {
      setPageLoading(true);
      setPageError(undefined);

      const request = {
        endpoint: `transacciones/${id}/detalle/`,
        method: "GET"
      };

      const response = await  http(request);

      setTransaction(response);
      setPageLoading(false);
      getDocuments();
    } catch (error) {
      setPageError(error);
      setPageLoading(false);
    }
  };

  useEffect(() => { getData(); }, []);

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && transaction &&
        <>
          <PageHeader
            title={"Documentos"}
            onBack={() => navigate(-1)}
            breadcrumb={{ routes, itemRender }}
          >
            <Descriptions size="small">
              <Descriptions.Item label="Transacción">
                <Text>
                  {transaction?.id}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Empresa">
                <Text>
                  {transaction?.empresa}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Precio">
                <Text>
                  {maskBigNumber(transaction?.precio, "currency")}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Condiciones">
                <Tooltip title={transaction?.condiciones}>
                  <Paragraph ellipsis className="my0" style={{maxWidth: '210px'}}>
                    {transaction?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card className="mt1">
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={`/pages/transactions/${id}/details`}
        />
      }
      <Modal
        title="Cargando"
        visible={isGenerating.visible}
        maskClosable={false}
        footer={null}
        closable={false}
        centered
      >
        <LoadingOutlined />
        <Text className="ml1">
          {isGenerating.text}
        </Text>
      </Modal>
    </>
  )
}

export default TransactionDocuments;
