import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import http from '../../../services/http.service';
import PageLoader from "../../../components/page-loader/PageLoader";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { EditOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Tooltip,
  Card,
  Button
} from 'antd';

const CompanyTemplates = () => {

  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();
  const { id } = useParams();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${id}/details`,
      breadcrumbName: `${company?.nombre_corto}`,
    },
    {
      path: `/pages/companies/${id}/templates`,
      breadcrumbName: 'Formatos',
    }
  ];

  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo_documento',
      width: 80
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      ellipsis: { showTitle: false },
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: 'Notas',
      dataIndex: 'notas',
      ellipsis: { showTitle: false },
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      align: "center",
      render: (cell) => (
        <Tooltip title={"Editar"}>
          <Button
            size={"small"}
            className="noBorderColor bgTransparent"
            onClick={() => navigate(`/pages/companies/${id}/templates/${cell}/update`)}
            shape={"circle"}
            icon={<EditOutlined />}
          />
        </Tooltip>
      ),
    },
  ];

  const getCompany = async () => {
    try {
      const response = await http({ endpoint: `empresas/${id}/` });
      setCompany(response);
    } catch(error) {
      setPageError(error);
    }
  }

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = `machotes/?empresa=${id}&ordering=codigo_documento`;
      let queryParams = [
        `empresa=${id}`,
        "ordering=codigo_documento",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint })

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `machotes/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  useEffect(
    () => {
      getCompany();
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company &&
        <>
          <PageHeader
            title={`${company?.razon_social}`}
            onBack={() => navigate(`/pages/companies/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
          />
          <Card className={"mt1"}>
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
    </>
  )
}

export default CompanyTemplates;