import { notification } from "antd";
import { getUserTimezone } from "../shared/utils";
import http from "../services/http.service";
import {
  useState,
  useEffect,
  createContext
} from "react";
import {
  STORAGE_CONFIG_NAME,
  STORAGE_TOKEN_NAME
} from "../constants";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(undefined);

  const getProfile = async () => {

    try {

      let modules = {};
      const promises = [
        http({ endpoint: "usuarios/perfil/" }),
        http({ endpoint: "usuarios/modulos/" })
      ];
      
      const [profileResponse, moduleResponse] = await Promise.all(promises);

      moduleResponse.forEach((module) => modules[module] = true);

      const timezone = getUserTimezone();

      const config = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG_NAME));

      let shareholder = null;

      const shareholders = profileResponse?.accionistas.map(
        (shareholder) => {
          return {
            id: shareholder.id,
            name: shareholder.nombre_rs,
            type: shareholder.tipo_accionista_d2,
            rfc: shareholder.rfc_firma
          }
        }
      );

      if (config && config.shareholderId) {
        const results = shareholders.filter((c) => parseInt(c.id) === parseInt(config.shareholderId));
        if (results.length > 0) shareholder = results[0];
      }

      let roles = {}

      profileResponse?.rol.forEach((role) => roles[role.rol] = true);

      const profile = {
        id: profileResponse?.id,
        firstName: profileResponse?.first_name,
        lastName: profileResponse?.last_name,
        email: profileResponse?.email,
        timezone: timezone,
        shareholders,
        shareholder,
        roles,
        modules
      };

      setUser(profile);

    } catch (error) {

      setUser(undefined);

      if (error.status === 401) {

        sessionStorage.removeItem(STORAGE_TOKEN_NAME);
        setUser(undefined);
        window.location.reload(false);

      } else {

        notification.error({
          message: '¡Ups!',
          description: error.message,
        });

      }

    }

  };

  useEffect(
    () => {
      const token = sessionStorage.getItem(STORAGE_TOKEN_NAME);
      if (token) getProfile();
    },
    []
  );

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export {
  AuthProvider
}

export default AuthContext;