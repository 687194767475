import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { RightOutlined } from "@ant-design/icons";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import { formatDate, maskBigNumber } from "../../../shared/utils";
import {
  PageHeader,
  Button,
  Tooltip,
  Card,
  Typography
} from "antd";

const TransactionsOverview = () => {

  const [pageError, setPageError] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const { Paragraph } = Typography;

  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/transactions/overview",
      breadcrumbName: "Transacciones",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      filterName: "id",
      width: 100
    },
    {
      title: "Compañía",
      dataIndex: "empresa",
      filterName: "empresa",
      ellipsis: { showTitle: false },
      width: 180,
      render: (cell) => (
        <Tooltip title={cell?.nombre_corto}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '180px'}}>
            {cell?.nombre_corto}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_d6",
      filterName: "status_d6",
      width: 150,
      render: (cell) => (
        <Tooltip title={cell?.nombre}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '150px'}}>
            {cell?.nombre}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Precio",
      dataIndex: "precio",
      filterName: "precio",
      width: 130,
      render: (cell) => (<span>{maskBigNumber(cell, "currency")}</span>)
    },
    {
      title: "Fecha Inicio",
      dataIndex: "inicio_fh",
      filterName: "inicio_fh",
      width: 120,
      render: (cell) => ((cell) ? <span>{formatDate(cell)}</span> : null)
    },
    {
      title: "Fecha Fin",
      dataIndex: "fin_fh",
      filterName: "fin_fh",
      width: 120,
      render: (cell) => ((cell) ? <span>{formatDate(cell)}</span> : null)
    },
    {
      title: "Condiciones",
      dataIndex: "condiciones",
      ellipsis: { showTitle: false },
      width: 180,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '180px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 75,
      align: "center",
      render: (cell) => (
        <Tooltip title={"Detalle"}>
          <Button
            size={"small"}
            onClick={() => navigate(`/pages/transactions/${cell}/details`)}
            shape='circle'
            className="noBorderColor bgTransparent"
            icon={<RightOutlined />}
          />
        </Tooltip>
      ),
    },
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = "transacciones/?ordering=-id&limit=10&offset=0";
      let queryParams = [
        "ordering=-id",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "transacciones/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {

      setPageError(error);

    }

  };

  const pageHeaderExtras = (
    <Button
      onClick={() => navigate("/pages/transactions/create")}
      type='primary'
      size={"small"}
      ghost
    >
      Nueva
    </Button>
  );

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      <PageHeader
        title='Listado de transacciones'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
        extra={pageHeaderExtras}
      />
      {!pageError && (
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={customTableConfig} />
          </div>
        </Card>
      )}
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  );
};

export default TransactionsOverview;