import {
  useState,
  useEffect
} from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import {
  PageHeader,
  Button,
  Tag,
  Tooltip,
  Space,
  Card
} from "antd";
import {
  RightOutlined,
  StockOutlined
} from "@ant-design/icons";

const CompanyOverview = () => {

  const [pageError, setPageError] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/companies/overview",
      breadcrumbName: "Compañías",
    }
  ];

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre_corto",
      filterName: "nombre_corto",
      search: true,
      ellipsis: { showTitle: false },
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Razón Social",
      dataIndex: "razon_social",
      filterName: "razon_social",
      search: true,
      ellipsis: { showTitle: false },
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_d1",
      width: 100,
      align: "center",
      render: cell => (
        (cell?.nombre === "Activo")
          ?
            <Tag color="green">{cell?.nombre}</Tag>
          :
            <Tag>{cell?.nombre}</Tag>)
    },
    {
      title: "",
      dataIndex: "id",
      width: 120,
      align: "center",
      render: (cell) => (
        <Space>
          <Tooltip title={"Posición Actual"}>
            <Button
              size={"small"}
              className="noBorderColor bgTransparent"
              onClick={() => navigate(`/pages/companies/${cell}/stocks`)}
              shape={"circle"}
              icon={<StockOutlined />}
            />
          </Tooltip>
          <Tooltip title={"Detalle"}>
            <Button
              size={"small"}
              className="noBorderColor bgTransparent"
              onClick={() => navigate(`/pages/companies/${cell}/details`)}
              shape={"circle"}
              icon={<RightOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = "empresas/?ordering=nombre_corto&offset=0&limit=10";
      let queryParams = [
        "ordering=nombre_corto",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "empresas/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {

      setPageError(error);

    }

  };

  const pageHeaderExtras = (
    <Button
      onClick={() => navigate("/pages/companies/create")}
      type="primary"
      size={"small"}
      ghost
    >
      Nueva
    </Button>
  );

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      <PageHeader
        title="Listado de compañías"
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
        extra={pageHeaderExtras}
      />
      {!pageError &&
        <Card className={"mt1"}>
          <div className="tableContainer">
            <CustomTable config={customTableConfig} />
          </div>
        </Card>
      }
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  )
}

export default CompanyOverview;