import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import PageLoader from "../../../components/page-loader/PageLoader";
import http from "../../../services/http.service";
import ErrorResult from "../../../components/error-result/ErrorResult";
import {
  COMMON_REQUIRED_FORM_RULE,
  INPUT_NUMBER_FORMAT
} from "../../../constants";
import {
  maskBigNumber,
  formatDateTime
} from "../../../shared/utils";
import {
  MinusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {
  PageHeader,
  Button,
  Card,
  Descriptions,
  Form,
  Select,
  notification,
  Row,
  Col,
  InputNumber,
  Tooltip,
  Typography
} from "antd";

const TransactionInvite = () => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(undefined);
  const [transaction, setTransaction] = useState({});
  const [shareholders, setShareholders] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const { Option } = Select;
  const { Paragraph } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: `/pages/transactions/overview`,
      breadcrumbName: "Transacciones",
    },
    {
      path: `/pages/transactions/${id}/details`,
      breadcrumbName: `Detalle`,
    },
    {
      path: `/pages/transactions/${id}/invite`,
      breadcrumbName: "Invitar",
    }
  ];

  const onSubmitClick = async () => {

    try {

      await form.validateFields();

      const selectedShareholders = form.getFieldValue("shareholders");

      let sum = 0;
      let max = transaction.acciones_disponibles_nuevos_socios;
      selectedShareholders.forEach((shareholder) => sum += shareholder.stocks);

      if (sum > max) {

        notification.warning({
          message: "¡Ups!",
          description: "La suma de las acciones a repartir excede las disponibles."
        });

        return;

      }

      setSaving(true);

      let promises = [];

      selectedShareholders.forEach((current) => {
        const data = { accionista_id: current.id, numero_de_acciones: current.stocks };
        promises.push(http({ endpoint: `transacciones/${id}/nuevo-socio/`, method: "POST", data }));
      });

      await Promise.all(promises);

      setSaving(false);

      notification.success({
        message: "¡Correcto!",
        description: "Invitación fué realizada correctamente."
      });

      navigate(`/pages/transactions/${id}/details`);    

    } catch (error) {

      if (error?.errorFields) return false;

      setSaving(false);

      notification.error({
        message: "¡Ups!",
        description: error.message
      });

    }

  }

  const getData = async () => {

    try {
      
      setLoading(true);
      setError(undefined);

      const promises = [
        http({ endpoint: `transacciones/${id}/detalle/` }),
        http({ endpoint: `accionistas/?ordering=nombre_rs&status_d1=A&no_page=true` }),
      ];

      const [
        transactionResponse,
        shareholderResponse
      ] = await Promise.all(promises);

      const newShareholders = shareholderResponse.filter((shareholder) => {
        const found = transactionResponse.detalles_transaccion.some((detail) => (detail.accionista.id === shareholder.id));
        return (found) ? false : true;
      });

      setShareholders(newShareholders);
      setTransaction(transactionResponse);
      
      form.setFieldsValue({ "shareholders": [{id: null, stock: null}]});

      setLoading(false);

    } catch (error) {

      setError(error);
      setLoading(false);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  )
  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error && transaction &&
        <>
          <PageHeader
            title={"Nuevos Socios"}
            onBack={() => navigate(`/pages/transactions/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
            extra={
              <Button
                size={"small"}
                type="primary"
                onClick={onSubmitClick}
                loading={saving}
                ghost
              >
                {!saving && <span>Invitar</span>}
                {saving && <span>Invitando</span>}
              </Button>
            }
          >            
            <Descriptions size="small" column={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
              <Descriptions.Item label={"Empresa"}>
                {transaction?.empresa}
              </Descriptions.Item>
              <Descriptions.Item label={"Acciones disponibles"}>
                {maskBigNumber(transaction?.acciones_disponibles_nuevos_socios)}
              </Descriptions.Item>
              <Descriptions.Item label={"Condiciones"}>
                <Tooltip title={transaction?.condiciones}>
                  <Paragraph ellipsis className="my0" style={{maxWidth: '210px'}}>
                    {transaction?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label={"Fecha y Hora"}>
                {formatDateTime(transaction?.inicio_fh)}
              </Descriptions.Item>
              <Descriptions.Item label={"Precio"}>
                {maskBigNumber(transaction?.precio, "currency")}
              </Descriptions.Item>
              <Descriptions.Item label={""}>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card className="mt1">
            <Form form={form} layout={"vertical"}>
              <Form.List name="shareholders">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={24}>
                        <Col sm={8}>
                          <Form.Item
                            {...restField}
                            label='Expediente'
                            name={[name, 'id']}
                            rules={COMMON_REQUIRED_FORM_RULE}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => `${option.key}`.toLowerCase().includes(input.toLowerCase())}
                            >
                              {
                                shareholders.map(({id, nombre_rs}) => {
                                  return (
                                    <Option key={`${nombre_rs}-${id}`} value={id}>
                                      {nombre_rs}
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col sm={4}>
                          <Form.Item {...restField} label='Acciones' name={[name, 'stocks']} rules={COMMON_REQUIRED_FORM_RULE}>
                            <InputNumber
                              className={"w100"}
                              formatter={value => maskBigNumber(value)}
                              parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                              min={1}
                              max={transaction?.acciones_disponibles_nuevos_socios}
                              controls={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={4} style={{ display: "flex", alignItems: "center"}}>
                          <Form.Item {...restField} name={[name, 'action']} style={{marginBottom: 0}}>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <Row gutter={24}>
                      <Col sm={12}>
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            disabled={fields.length === shareholders.length}
                            icon={<PlusOutlined />}>
                            Agregar
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Form>
          </Card>
        </>
      }
      {!loading && error &&
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getData}
          exitPath={`/pages/transactions/${id}/details`}
        />
      }
    </>
  )
}

export default TransactionInvite;