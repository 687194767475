import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorResult, CustomTable } from "../../../components";
import http from "../../../services/http.service";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { EditOutlined } from "@ant-design/icons";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import {
  Card,
  PageHeader,
  Typography,
  Tooltip,
  Button,
  Modal,
  Form,
  InputNumber,
  Input,
  notification,
  Select
} from "antd";

const SettingsAlerts = () => {

  const [pageError, setPageError] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [modalConfig, setModalConfig] = useState({ isVisible: false, isLoading: false });
  const [form] = Form.useForm();
  const { Paragraph, Text } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;
  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/settings/overview",
      breadcrumbName: "Configuraciones",
    },
    {
      path: "/pages/settings/alerts",
      breadcrumbName: "Alertas",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 100
    },
    {
      title: "Limite Porcentaje",
      dataIndex: "limite_porcentaje",
      filterName: "limite_porcentaje",
      ellipsis: { showTitle: false },
      width: 150,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '150px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Mensaje",
      dataIndex: "mensaje_alerta",
      width: 300,
      render: (cell) => (
        <Tooltip title={cell}>
          <Paragraph ellipsis className="my0" style={{maxWidth: '300px'}}>
            {cell}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_d1",
      width: 80,
      render: (cell) => (
        <>
          {cell === "A" && <Text>Activa</Text>}
          {cell === "I" && <Text>Inactiva</Text>}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 80,
      align: "center",
      render: (cell, row) => (
        <Tooltip title={"Editar"}>
          <Button
            size={"small"}
            className="noBorderColor bgTransparent"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue({ percent: row.limite_porcentaje, message: row.mensaje_alerta, status: row.status_d1 });
              setModalConfig({ isVisible: true, isLoading: false, id: cell })
            }}
          />
        </Tooltip>
      ),
    },
  ];

  const onCreateClick = async () => {
    try {
      const values = form.getFieldsValue();
      const payload = {
        status_d1: 'A',
        limite_porcentaje: values.percent,
        mensaje_alerta: values.message
      };

      setModalConfig({ isVisible: true, isLoading: true });

      const request = {
        endpoint: "alertas-config/",
        method: "POST",
        data: payload,
      };

      await http(request);

      notification.success({
        message: '¡Correcto!',
        description: 'Creada correctamente.',
      });

      setModalConfig({ isVisible: false, isLoading: false });
    } catch (error) {
      setModalConfig({ isVisible: true, isLoading: false });
      notification.error({
        message: '¡Ups!',
        description: error.message,
      });
    }

  }

  const onEditClick = async () => {
    try {
      const values = form.getFieldsValue();
      const payload = {
        status_d1: values.status,
        limite_porcentaje: values.percent,
        mensaje_alerta: values.message
      };

      const request = {
        endpoint: `alertas-config/${modalConfig.id}/`,
        method: "PATCH",
        data: payload,
      };

      setModalConfig({...modalConfig, isLoading: true });

      await http(request);

      notification.success({
        message: '¡Correcto!',
        description: 'Actualizada correctamente.',
      });

      setModalConfig({ isVisible: false, isLoading: false });
      form.resetFields();
      getData(customTableConfig);
    } catch (error) {
      setModalConfig({...modalConfig, isLoading: false });
      notification.error({ message: '¡Ups!', description: error.message });
    }

  }

  const getData = async (props = null) => {
    try {
      setPageError(undefined);
      let endpoint = "alertas-config/?ordering=limite_porcentaje&limit=10&offset=0";
      let queryParams = [
        "ordering=limite_porcentaje",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "alertas-config/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {
      setPageError(error);
    }
  };

  useEffect(() => { getData(); }, []);

  return (
    <>
      <PageHeader
        title='Configuración de alertas'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/settings/overview")}
        extra={
          <Button
            type='primary'
            ghost
            size="small"
            onClick={() => setModalConfig({ isVisible: true, isLoading: false })}
          >
            Crear
          </Button>
        }
      />
      {!pageError && (
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={customTableConfig} />
          </div>
        </Card>
      )}
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/settings/overview"}
        />
      )}
      {modalConfig && modalConfig.isVisible &&
        <Modal
          title={(modalConfig?.id) ? "Editar Alerta" : "Crear Alerta"}
          visible={modalConfig.isVisible}
          maskClosable={false}
          onOk={(modalConfig?.id) ? onEditClick : onCreateClick}
          okText={(modalConfig?.id) ? "Actualizar" : "Crear"}
          confirmLoading={modalConfig.isLoading}
          onCancel={() => {
            setModalConfig({ isVisible: false, isLoading: false });
            form.resetFields();
          }}
        >
          <Form form={form} layout='vertical'>
            <Form.Item name="percent" label="Procentaje Limite" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                max={100}
                className={"w100"}
                controls={false}
                formatter={(value) => `${parseFloat(value || 0).toFixed(2)} %`}
                parser={(value) => value?.replace(' %', '')}
              />
            </Form.Item>
            <Form.Item name="message" label="Mensaje" rules={[{ required: true }]}>
              <TextArea
                showCount
                autoSize={false}
                rows={2}
                maxLength={250}
              />
            </Form.Item>
            <Form.Item name="status" label="Estatus" initialValue={"A"} rules={[{ required: true }]}>
              <Select>
                <Option value="A">Activa</Option>
                <Option value="I">Inactiva</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      }
    </>
  );
};

export default SettingsAlerts;