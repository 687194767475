import { Navigate } from "react-router-dom";
import { STORAGE_TOKEN_NAME } from "../constants";
import useWindowSize from "../hooks/useWindowSize";

export const PublicRoute = ({ children }) => {

  const size = useWindowSize();
  const token = sessionStorage.getItem(STORAGE_TOKEN_NAME);

  if (size.width <= 1000) {

    return <Navigate to='/not-supported' />

  } else {

    return token ? <Navigate to='/pages/dashboard' /> : children;

  }

};
