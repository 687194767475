import {
  useState,
  useEffect
} from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import http from "../../../services/http.service";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { DownloadButton, PageLoader} from "../../../components";
import { FilePdfOutlined } from "@ant-design/icons";
import { formatDateTime } from "../../../shared/utils";
import useAuth from "../../../hooks/useAuth";
import {
  PageHeader,
  Button,
  Space,
  Tooltip,
  Result,
  Typography,
  Tag,
  Table,
  Descriptions,
  Card,
  notification
} from "antd";

const ShareholderEvent = () => {

  const [event, setEvent] = useState();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [canVote, setCanVote] = useState(true);

  const navigate = useNavigate();

  let { id } = useParams();
  const { user } = useAuth();

  const { Text, Paragraph } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: `/pages/shareholders/${id}/events`,
      breadcrumbName: "Evento",
    },
  ];

  const onVoteClick = async (activityId, status) => {

    try {
      
      const request ={
        endpoint: `eventos/${id}/participantes/${user?.shareholder?.id}/votaciones/${activityId}/`,
        method: "PATCH",
        data: {
          accion: status
        }
      };

      await http(request);

      getEvent(true);

    } catch (error) {

      notification.error({
        message: "¡Ups!",
        description: "No se pudo votar.",
      });

    }

  };

  const getEvent = async (onRefresh = false) => {

    try {

      if (!onRefresh) {
        setError(undefined);
        setLoading(true);
      }

      const endpoint = `eventos/${id}/`;

      const response = await http({ endpoint });

      if (response.status_evento_d10 === "CM") navigate("/pages/shareholders/events/historic");

      const checkins = response.asistentes.filter((asistente) => asistente.checkin_status === "S");
      response.total_asistentes = checkins.length;

      response.agenda.forEach((activity) => {
        if (activity.se_vota) {
          const voto_a_favor = activity.votantes.a_favor.some((votante) => votante.pk === user?.shareholder?.id);
          const voto_en_contra = activity.votantes.en_contra.some((votante) => votante.pk === user?.shareholder?.id);
          const voto_abstencion = activity.votantes.abstencion.some((votante) => votante.pk === user?.shareholder?.id);
          if (voto_a_favor) activity.ya_voto = "A Favor";
          if (voto_en_contra) activity.ya_voto = "En Contra";
          if (voto_abstencion) activity.ya_voto = "Abstención";
        }
      });

      response.asistentes.some(({accionista}) => {
        if (accionista.pk === user.shareholder.id) {
          setCanVote(accionista.vota);
          return true;
        }
      });

      setEvent(response);
      setLoading(false);

    } catch (error) {

      setLoading(false);
      setError(error);

    }

  };

  const columns = [
    {
      title: "Actividad",
      dataIndex: "descripcion",
      ellipsis: { showTitle: false },
      width: 200,
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status_punto_agenda_d12",
      width: 140,
      render: (cell) => {
        if (cell === "C") {
          return <Tag color="green">Completado</Tag>
        } else if (cell === "R") {
          return <Tag color="blue">En Proceso</Tag>
        } else if (cell === "O") {
          return <Tag>Omitido</Tag>
        } else if (cell === "V") {
          return <Tag color="geekblue">En Votación</Tag>
        } else {
          return <Tag>Pendiente</Tag>
        }
      }
    },
    {
      title: "Votación",
      dataIndex: "id",
      width: 200,
      render: (cell, row) => (
        (canVote)
          ?
            <Space>
              {row.status_punto_agenda_d12 === "V" &&
                <>
                  <Button
                    type={(row.ya_voto === 'A Favor') ? 'primary' : 'default'}
                    size={"small"}
                    onClick={() => onVoteClick(cell, "A FAVOR")}>
                    A Favor
                  </Button>
                  <Button
                    type={(row.ya_voto === 'En Contra') ? 'primary' : 'default'}
                    size={"small"}
                    onClick={() => onVoteClick(cell, "EN CONTRA")}>
                    En Contra
                  </Button>
                  <Button
                    type={(row.ya_voto === 'Abstención') ? 'primary' : 'default'}
                    size={"small"}
                    onClick={() => onVoteClick(cell, "ABSTENCION")}>
                    Abstención
                  </Button>
                </>
              }
            </Space>
          :
            null
      )
    },
    {
      title: "Anexos",
      dataIndex: "id",
      width: 100,
      align: "center",
      render: (_, row) => (
        <Space size={"large"}>
          {row?.referencia_s3_archivo &&
            <DownloadButton
              tooltip="Descargar"
              url={row.link}
              fileName={row?.description}
              icon={<FilePdfOutlined />}
            />
          }
        </Space>
      ),
    },
  ];

  useEffect(
    () => {
      const interval = setInterval(() => {
        getEvent(true);
      }, 3000);
      return () => clearInterval(interval);
    },
    []
  );

  useEffect(
    () => {
      getEvent();
    },
    []
  );

  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error &&
        <>
          <PageHeader
            title={`${event?.nombre_evento}`}
            breadcrumb={{ routes, itemRender }}
            onBack={() => navigate("/pages/dashboard")}
          >
            <Descriptions size="small" column={{xs: 1, sm: 1, lg: 3, xl: 3}}>
              <Descriptions.Item label="Compañía">
                <Text>
                  {event?.empresa?.nombre_corto}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Fecha">
                  {!event?.inicio_real_fh && (
                    <Text>
                      {formatDateTime(event?.inicio_plan_fh)} 
                    </Text>
                  )}
                  {event?.inicio_real_fh && (
                    <Text>
                      {formatDateTime(event?.inicio_real_fh)} 
                    </Text>
                  )}
              </Descriptions.Item>
              <Descriptions.Item label="Asistentes">
                <Text>
                  {event?.total_asistentes}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Nombre">
                <Text>
                  {event?.tipo_evento_d3?.nombre}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Tipo">
                {event?.virtual_presencial && <Text>Presencial</Text>}
                {!event?.virtual_presencial && <Text>Virtual</Text>}
              </Descriptions.Item>
              {event?.ubicacion &&
                <Descriptions.Item label="Ubicación">
                  <Text>{event?.ubicacion}</Text>
                </Descriptions.Item>
              }
              {event?.plataforma && 
                <Descriptions.Item label="Plataforma">
                  <Text>{event?.plataforma}</Text>
                </Descriptions.Item>
              }
              <Descriptions.Item label="Quorum">
                <Text>
                  {event?.quorum}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Invitados">
                <Text>
                  {event?.numero_de_invitados}
                </Text>
              </Descriptions.Item>
              {event?.link && 
                <Descriptions.Item label="Link">
                  <Paragraph style={{ width: "calc(100% - 1rem)" }} copyable ellipsis>
                    {event?.link}
                  </Paragraph>
                </Descriptions.Item>
              }
            </Descriptions>
          </PageHeader>
          <Card title="Agenda" className="mt1">
            <Table
              columns={columns}
              dataSource={event?.agenda}
              size={"small"}
              rowKey={"id"}
            />
          </Card>
        </>
      }
      {!loading && error && (
        <Result
          status='500'
          title='¡Ups!'
          subTitle={`${error?.message}`}
          extra={[
            <Button
              key={"exitBtn"}
              size={"small"}
              onClick={() => navigate("/pages/events/overview")}
            >
              Salir
            </Button>,
            <Button
              key={"retryBtn"}
              size={"small"}
              onClick={() => getEvent()}
              type='primary'
            >
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default ShareholderEvent