import {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_REQUIRED_FORM_RULE } from "../../../constants";
import http from "../../../services/http.service";
import ErrorResult from "../../../components/error-result/ErrorResult";
import {
  getMoment,
  maskBigNumber
} from "../../../shared/utils";
import {
  INPUT_NUMBER_FORMAT
} from "../../../constants";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  InputNumber,
  notification
} from "antd";

export const TransactionForm = ({ parentName, isSubmitted, setSubmitted, }) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [companies, setCompanies] = useState([]);

  const navigate = useNavigate();

  const { Option } = Select;

  const onSubmitClick = async () => {

    try {

      if (!isSubmitted) return;
      
      await form.validateFields();

      setSubmitted(true);

      const now = getMoment().format("YYYY-MM-DD");
      const values = form.getFieldsValue();

      const payload = {
        precio: parseFloat(values.price),
        inicio_fh: now,
        empresa: values.company,
        condiciones: values.conditions,
      };

      if (parentName === "create") {

        await http({
          endpoint: "transacciones/",
          method: "POST",
          data: payload,
        });

      }

      setSubmitted(false);

      notification.success({
        message: "¡Correcto!",
        description: "Transacción creada correctamente.",
      });

      if (parentName === "create") navigate("/pages/transactions/overview");

    } catch (error) {

      setSubmitted(false);

      if (error?.errorFields) return false;

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const onCompanyChange = async (value) => {

    const selectedCompany = companies.find((company) => (company.id === value));
    form.setFieldsValue({ "price":  selectedCompany?.valoracion_empresa?.precio});

  };

  const getCompanies = async () => {

    try {

      setError(undefined);
      setLoading(true);

      const response = await http({ endpoint: "empresas/?status_d1=A&no_page=true" });

      setCompanies(response);
      setLoading(false);

    } catch (error) {

      setLoading(false);
      setError(error);

    }

  };

  useEffect(
    () => {
      getCompanies();
    },
    []
  );

  useEffect(
    () => {
      onSubmitClick();
    },
    [isSubmitted]
  );

  return (
    <>
      {!error && (
        <Form
          layout="vertical"
          form={form}
          className="bgWhite mt1 p2"
        >
          <Row gutter={24}>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Compañía"
                name="company"
                rules={COMMON_REQUIRED_FORM_RULE}
                hasFeedback
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onCompanyChange}
                  loading={loading}
                  filterOption={(input, option) => `${option.key}`.toLowerCase().includes(input.toLowerCase())}
                >
                  {companies?.map(({ id, nombre_corto }) => (
                    <Option key={`${nombre_corto}-${id}`} value={id}>
                      {nombre_corto || ""}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item
                label="Precio:"
                name="price"
                initialValue={0}
                rules={COMMON_REQUIRED_FORM_RULE}
                hasFeedback
              >
                <InputNumber
                  addonBefore={"$"}
                  min={0}
                  formatter={value => maskBigNumber(value)}
                  controls={false}
                  parser={value => value?.replace(INPUT_NUMBER_FORMAT, "")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Condiciones:"
                name="conditions"
                initialValue={""}
                hasFeedback
              >
                <Input type={"text"} maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      {error && (
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getCompanies}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  );
};
