import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import http from '../../../services/http.service';
import GeneralForm from "../forms/CompanyGeneralForm";
import { CompanyFormProvider } from "../../../context/CompanyFormProvider";
import { itemRender } from '../../../shared/helpers/breadcrumb';
import PageLoader from '../../../components/page-loader/PageLoader';
import ErrorResult from '../../../components/error-result/ErrorResult';
import { PageHeader, Card, notification } from 'antd';

const CompanyUpdate = () => {
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const navigate = useNavigate();
  const { id } = useParams();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${id}/details`,
      breadcrumbName: `${company?.nombre_corto}`,
    },
    {
      path: '/pages/companies/update',
      breadcrumbName: 'Editar',
    }
  ];

  const onFinish = async (values) => {
    try {
      let request = {
        endpoint: `empresas/${id}/`,
        method: "PUT",
        data: {
          ...values,
          valoracion_empresa: values.valoracion_x_accion
        }
      };

      await http(request);
      
      notification.success({ message: '¡Correcto!', description: 'Compañía guardada correctamente.' });
      navigate(`/pages/companies/${id}/details`);
    } catch(error) {
      notification.error({ message: '¡Ups!', description: error.message });
      return Promise.reject(error.message);
    }
  };

  const getCompany = async () => {
    try {
      setPageLoading(true);
      setPageError(undefined);

      const response = await http({ endpoint: `empresas/${id}/` });

      setCompany(response);
      setPageLoading(false);
    } catch(error) {
      setPageError(error);
      setPageLoading(false);
    }
  };

  useEffect(
    () => {
      getCompany();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company &&
        <CompanyFormProvider>
          <PageHeader
            title="Editar Compañía"
            onBack={() => navigate(`/pages/companies/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
          />
          <Card className="mt1">
            <GeneralForm onFinish={onFinish} from={"update"} company={company} />
          </Card>
        </CompanyFormProvider>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getCompany}
          exitPath={"/pages/companies/overview"}
        />
      }
    </>
  )
}

export default CompanyUpdate;