import {
  useState,
  useEffect
} from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import {
  PageHeader,
  Button,
  Tooltip,
  Card,
  Typography,
  Tag,
  Space,
  notification
} from "antd";
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  EditOutlined
} from "@ant-design/icons";

const UserOverview = () => {

  const [pageError, setPageError] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const navigate = useNavigate();

  const { Text } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/users/overview",
      breadcrumbName: "Usuarios",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      filterName: "id",
      width: 100
    },
    {
      title: "Nombre",
      dataIndex: "first_name",
      filterName: "first_name",
      search: true,
      ellipsis: { showTitle: false },
      width: 150,
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Apellido",
      dataIndex: "last_name",
      filterName: "last_name",
      search: true,
      ellipsis: { showTitle: false },
      width: 150,
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      filterName: "email",
      search: true,
      ellipsis: { showTitle: false },
      width: 180,
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Roles",
      dataIndex: "rol",
      width: 100,
      render: (roles) => (
        <Space direction="vertical">
          {roles.map(({id,rol}) => {
            return (
              <Text key={`${rol}-${id}`}>
                {rol}
              </Text>
            )
          })}
        </Space>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "is_active",
      width: 80,
      render: (cell) => (
        <Tag color={(cell) ? "green" : "default"} >
          {(cell) ? "Activo" : "Desactivado"}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 120,
      align: "center",
      render: (cell, row) => (
        <Space direction="horizontal">
          {row.is_active &&
            <Tooltip title={"Desactivar"}>
              <Button
                size={"small"}
                onClick={() => setSelectedUser({id: cell, isActive: false})}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<CloudDownloadOutlined />}
              />
            </Tooltip>
          }
          {!row.is_active &&
            <Tooltip title={"Activar"}>
              <Button
                size={"small"}
                onClick={() => setSelectedUser({id: cell, isActive: true})}
                shape='circle'
                className="noBorderColor bgTransparent"
                icon={<CloudUploadOutlined />}
              />
            </Tooltip>
          }
          <Tooltip title={"Editar"}>
            <Button
              size={"small"}
              onClick={() => navigate(`/pages/users/${cell}/update`)}
              shape='circle'
              className="noBorderColor bgTransparent"
              icon={<EditOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onUpdateStatusClick = async () => {

    try {

      const request = {
        endpoint: `usuarios/${selectedUser.id}/`,
        method: "PATCH",
        data: {
          is_active: selectedUser.isActive
        }
      };

      await http(request);

      const props = {
        url: customTableConfig.url,
        queryParams: customTableConfig.queryParams
      };

      getData(props);

      const description = `Usuario ${(selectedUser.isActive) ? "activado" : "desactivado"} correctamente.`

      notification.success({
        message: "¡Correcto!",
        description,
      });

      setSelectedUser(undefined);

    } catch (error) {

      setSelectedUser(undefined);

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = "usuarios/?ordering=first_name&offset=0&limit=10";
      let queryParams = [
        "ordering=first_name",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "usuarios/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {

      setPageError(error);

    }

  };

  const pageHeaderExtras = (
    <Button
      onClick={() => navigate("/pages/users/create")}
      type='primary'
      size={"small"}
      ghost
    >
      Nuevo
    </Button>
  );

  useEffect(
    () => {
      getData();
    },
    []
  );

  useEffect(
    () => {
      if (selectedUser) onUpdateStatusClick();
    },
    [selectedUser]
  );

  return (
    <>
      <PageHeader
        title='Listado de usuarios'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
        extra={pageHeaderExtras}
      />
      {!pageError && (
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={customTableConfig} />
          </div>
        </Card>
      )}
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  );
};

export default UserOverview;