import { Navigate, useLocation } from "react-router-dom";
import { STORAGE_TOKEN_NAME } from "../constants";
import useWindowSize from "../hooks/useWindowSize";

export const PrivateRoute = ({ children }) => {

  const size = useWindowSize();
  const location = useLocation();
  const token = sessionStorage.getItem(STORAGE_TOKEN_NAME);

  if (size.width <= 1000) {

    return <Navigate to='/not-supported' />

  } else {

    return token ? children : <Navigate to='/auth/login' state={{ path: location.pathname }} replace />;
  
  }

};
