import {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import http from '../../../services/http.service';
import Form from "../form/UserForm";
import { itemRender } from '../../../shared/helpers/breadcrumb';
import PageLoader from '../../../components/page-loader/PageLoader';
import ErrorResult from '../../../components/error-result/ErrorResult';
import {
  PageHeader,
  Button
} from 'antd';

const UserUpdate = () => {

  const [isSubmitted, setSubmitted] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [user, setUser] = useState(undefined);

  const navigate = useNavigate();
  const { id } = useParams();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/users/overview',
      breadcrumbName: 'Usuarios',
    },
    {
      path: `/pages/users/${id}/update`,
      breadcrumbName: 'Editar',
    }
  ];

  const getUser = async () => {

    try {

      setPageLoading(true);
      setPageError(undefined);

      const response = await http({ endpoint: `usuarios/${id}/` });

      setUser(response);
      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  const pageHeaderExtra = (
    <Button
      type='primary'
      size="small"
      loading={isSubmitted}
      onClick={() => setSubmitted(true)}
      ghost
    >
      {!isSubmitted && <span>Guardar</span>}
      {isSubmitted && <span>Guardando</span>}
    </Button>
  );

  useEffect(
    () => {
      getUser();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && user &&
        <>
          <PageHeader
            title="Editar Usuario"
            onBack={() => navigate(`/pages/users/${id}/overview`)}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderExtra}
          />
          <Form
            user={user}
            isSubmitted={isSubmitted}
            setSubmitted={setSubmitted}
          />
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getUser}
          exitPath={"/pages/users/overview"}
        />
      }
    </>
  )
}

export default UserUpdate;