import {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import http from "../../services/http.service";
import CustomPositionStatistic from "../../components/custom-position-statistic/CustomPositionStatistic";
import ErrorResult from "../../components/error-result/ErrorResult";
import { formatDateTime } from "../../shared/utils";
import { RightOutlined } from "@ant-design/icons"
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Divider,
  Tag,
  Typography,
  Space,
  notification,
  Modal,
  Tooltip
} from "antd";

const Dashboard = () => {

  const DEFAULT_MODAL_STATE = {
    id: "",
    type: "",
    visible: false,
    text: ""
  };

  const [positions, setPositions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [confirmationModalState, setConfirmationModalState] = useState(DEFAULT_MODAL_STATE);

  const navigate = useNavigate();
  const { user } = useAuth();

  const { Text } = Typography;

  const notificationColumns = [
    {
      title: "Fecha",
      dataIndex: "fecha",
      render: cell => <span>{formatDateTime(cell)}</span>,
    },
    {
      title: "Mensaje",
      dataIndex: "mensaje",
    },
    {
      title: "Vencimiento",
      dataIndex: "vencimiento",
      render: cell => <span>{formatDateTime(cell)}</span>,
    },
    {
      title: "En",
      dataIndex: "en",
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      render: (_, row) => (
        (row.tipo === "A")
          ? <Button type="text" size={"small"} onClick={() => navigate(row?.url)} shape="circle"><RightOutlined /></Button>
          : ""
      ),
    },
  ];

  const eventColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre_evento",
      ellipsis: { showTitle: false },
      width: 130,
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      )
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      width: 130,
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      )
    },
    {
      title: "Tipo",
      dataIndex: "tipo_evento_d3",
      width: 130,
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell.nombre}>
          {cell.nombre}
        </Tooltip>
      )
    },
    {
      title: "Fecha",
      dataIndex: "inicio_plan_fh",
      width: 130,
      render: (_, row) => (
        (row.inicio_real_fh)
          ? <Text>{formatDateTime(row.inicio_real_fh)}</Text>
          : <Text>{formatDateTime(row.inicio_plan_fh)}</Text>
      )
    },
    {
      title: "Estatus",
      dataIndex: "status_evento_d10",
      width: 120,
      align: "center",
      render: (cell) => {
        if (cell === "PR") {
          return <Tag color="green">Programado</Tag>
        } else if (cell === "EN") {
          return <Tag color="blue">En Proceso</Tag>
        } else if (cell === "CA") {
          return <Tag color="volcano">Cancelado</Tag>
        } else if (cell === "CM") {
          return <Tag color="geekblue">Completado</Tag>
        } else if (cell === "BR") {
          return <Tag>Borrador</Tag>
        }
      }
    },
    {
      title: "Asistencia",
      dataIndex: "id",
      width: 120,
      align: "center",
      render: (cell, row) => (
        <Space>
          {row.confirmacion_asistencia_status_d11 !== "N/A" &&
            <Text>
              {row.confirmacion_asistencia_status_d11}
            </Text>
          }
          {row.status_evento_d10 === "PR" && row.confirmacion_asistencia_status_d11 === "N/A" &&
            <Space direction={"vertical"} align={"center"}>
              <Button
                size={"small"}
                style={{width: "100px"}}
                onClick={() => setConfirmationModalState({id: cell, type: "CONFIRMADO", visible: true, text: "Se confirmará tu asistencia al evento."})}  
              >
                Confirmar
              </Button>
              <Button
                size={"small"}
                style={{width: "100px"}}
                onClick={() => setConfirmationModalState({id: cell, type: "RECHAZADO", visible: true, text: "Se rechazará tu asistencia al evento."})}
              >
                Rechazar
              </Button>
            </Space>
          }
        </Space>
      )
    },
    {
      title: "",
      width: 120,
      align: "center",
      dataIndex: "id",
      render: (cell, row) => {
        return <>
          {row.status_evento_d10 === "EN" && row.checkin_status === null &&
            <Button
              size={"small"}
              style={{width: "100px"}}
              onClick={() => {
                setConfirmationModalState({id: cell, type: "CHECK-IN", visible: true, text: "A continuación se hará check-in al evento."});
              }
            }
            >
              Check-in
            </Button>
          }
          {row.status_evento_d10 === "EN" && row.checkin_status === "S" &&
            <Button
              size={"small"}
              style={{width: "100px"}}
              onClick={() => {
                navigate(`/pages/shareholders/${cell}/events`);
              }
            }
            >
              Entrar
            </Button>
          }
        </>
      }
    }
  ];

  const onShareholderEventResponse = async () => {

    try {

      const request = {
        endpoint: `eventos/${confirmationModalState?.id}/participantes/${user?.shareholder?.id}/status/`,
        method: "PATCH",
        data: {
          status: confirmationModalState?.type,
          razon: ""
        }
      };

      await http(request);

      notification.success({
        message: "¡Correcto!",
        description: "Actualizado correctamente.",
      });

      if (confirmationModalState.type === "CHECK-IN") navigate(`/pages/shareholders/${confirmationModalState.id}/events`);

      setConfirmationModalState(DEFAULT_MODAL_STATE);

      const { results } = await http({ endpoint: `accionistas/${user?.shareholder?.id}/eventos/?status_evento_d10=PR,EN`});

      setEvents(results);

    } catch (error) {
      
      setConfirmationModalState(DEFAULT_MODAL_STATE);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const getData = async (refreshing = false) => {

    try {

      if (!refreshing) {
        setError(undefined);
        setLoading(true);
      }

      let requests = [
        http({ endpoint: `usuarios/notificaciones/?visible=true`}),
      ];

      if (user.roles["USUARIO"]) {
        requests.push(http({ endpoint: `accionistas/${user?.shareholder?.id}/posicion/`}));
        requests.push(http({ endpoint: `accionistas/${user?.shareholder?.id}/eventos/?status_evento_d10=PR,EN`}));
      }

      const responses = await Promise.all(requests);

      if (user.roles["USUARIO"]) {
        setPositions(responses[1].results);
        setEvents(responses[2].results);
      }
  
      setNotifications(responses[0].results);
      setLoading(false);

    } catch (error) {

      setError(error);
      setLoading(false);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  useEffect(
    () => {
      const interval = setInterval(() => {
        getData(true);
      }, 7000);
      return () => clearInterval(interval);
    },
    []
  );

  return (
    <>
      {!error &&
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={(positions.length === 0) ? 24 : 14}
            lg={(positions.length === 0) ? 24 : 16}
          >
            <Card title={"Notificaciones"}>
              <div className="tableContainer">
                <Table
                  dataSource={notifications}
                  columns={notificationColumns}
                  rowKey={"id"}
                  loading={loading}
                  size={"small"}
                />
              </div>
            </Card>
            {user.roles["USUARIO"] &&
              <Card
                title={"Eventos"}
                className="mt1"
                extra={
                  <Button
                    key="pageHeaderHistoricBtn"
                    type="primary"
                    size={"small"}
                    onClick={() => navigate(`/pages/shareholders/events/historic`)}
                    ghost
                  >
                    Histórico
                  </Button>
                }
              >
                <div className="tableContainer">
                  <Table
                    dataSource={events}
                    columns={eventColumns}
                    loading={loading}
                    size={"small"}
                    rowKey={"id"}
                  />
                </div>
              </Card>
            }
          </Col>
          <Col
            xs={(positions.length === 0) ? 0 : 24}
            sm={(positions.length === 0) ? 0 : 24}
            md={(positions.length === 0) ? 0 : 10}
            lg={(positions.length === 0) ? 0 : 8}
          >
            <Card
              title={"Posición Actual"}
              loading={loading}
              bodyStyle={{ padding: "12px", overflowY: "auto", maxHeight: "660px" }}
            >
              {positions?.map((position, index) => {
                  return (
                    <div key={position.posicion_id}>
                      <CustomPositionStatistic {...position} />
                      {index !== positions.length - 1 && <Divider className="mt1 mb2" />}
                    </div>
                  )
                }
              )}
            </Card>
          </Col>
        </Row>
      }
      {error &&
        <ErrorResult
          message={`${error?.message}`}
          onRetry={() => getData(true)}
          exitPath={"/pages/dashboard"}
        />
      }
      <Modal
        title="Confirmación"
        visible={confirmationModalState?.visible}
        onOk={() => onShareholderEventResponse()}
        maskClosable={false}
        onCancel={() => setConfirmationModalState(DEFAULT_MODAL_STATE)}
      >
        <Text>
          {confirmationModalState?.text}
        </Text>
      </Modal>
    </>
  )
}

export default Dashboard;