import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import Form from "../form/EventForm";
import PageLoader from "../../../components/page-loader/PageLoader";
import http from '../../../services/http.service';
import ErrorResult from '../../../components/error-result/ErrorResult';
import { PageHeader, Button } from 'antd';

const EventUpdate = () => {

  const [isSubmitted, setSubmitted] = useState({ clicked: false, button: ""});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [event, setEvent] = useState(undefined);
  const [pageHeaderButtons, setPageHeaderButtons] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
    {
      path: `/pages/events/${id}/edit`,
      breadcrumbName: "Editar",
    },
  ];

  const pageHeaderExtras = {
    draft: (
      <Button
        size="small"
        disabled={isSubmitted.clicked}
        loading={isSubmitted.clicked && isSubmitted.button === "draft"}
        key={"eventDraftBtn"}
        onClick={() => setSubmitted({clicked: true, button: "draft"})}
      >
        Guardar Borrador
      </Button>
    ),
    update: (
      <Button
        type="primary"
        ghost
        size="small"
        key={"eventCreateBtn"}
        disabled={isSubmitted.clicked}
        loading={isSubmitted.clicked && isSubmitted.button === "create"}
        onClick={() => setSubmitted({clicked: true, button: "create"})}
      >
        Guardar & Programar
      </Button>
    )
  };

  const getEvent = async () => {

    try {

      setLoading(true);
      setError(undefined);

      const response = await http({ endpoint: `eventos/${id}/` });

      if (response.status_evento_d10 === "BR") setPageHeaderButtons([pageHeaderExtras.draft,pageHeaderExtras.update]);
      if (response.status_evento_d10 === "PR") setPageHeaderButtons([pageHeaderExtras.update]);

      setEvent(response);
      setLoading(false);

    } catch(error) {

      setError(error);
      setLoading(false);

    }

  };

  useEffect(
    () => {
      if (event) {
        if (event.status_evento_d10 === "BR") setPageHeaderButtons([pageHeaderExtras.draft,pageHeaderExtras.update]);
        if (event.status_evento_d10 === "PR") setPageHeaderButtons([pageHeaderExtras.update]);
      }
    },
    [isSubmitted]
  );

  useEffect(
    () => {
      getEvent();
    },
    []
  );

  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error && event &&
        <>
          <PageHeader
            title="Editar Evento"
            onBack={() => navigate("/pages/events/overview")}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderButtons}
          />
          <Form
            parentName={"update"}
            event={event}
            isSubmitted={isSubmitted}
            setSubmitted={setSubmitted}
          />
        </>
      }
      {!loading && error &&
        <ErrorResult
          message={`${error?.message}`}
          onRetry={getEvent}
          exitPath={"/pages/dashboard"}
        />
      }
    </>
  );
};

export default EventUpdate;