import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../styles/SaleRequest.module.css";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import PageLoader from "../../../components/page-loader/PageLoader";
import useAuth from "../../../hooks/useAuth";
import http from "../../../services/http.service";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { INPUT_NUMBER_FORMAT } from "../../../constants";
import { maskBigNumber, maskPercentage } from "../../../shared/utils";
import {
  PageHeader,
  Descriptions,
  Button,
  InputNumber,
  notification,
  Typography,
  Modal,
  Tooltip
} from "antd";

const SaleRequest = () => {

  const [form, setForm] = useState({});
  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitted, setSubmitted]  = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [pageError, setPageError] = useState(undefined);
  const [position, setPosition] = useState(undefined);
  const [sellStockQuantity, setSellStockQuantity] = useState(0);

  const navigate = useNavigate();
  const { transactionId } = useParams();
  const { user } = useAuth();

  const { Text, Paragraph } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: `/pages/sale-request/${transactionId}`,
      breadcrumbName: "Intención Venta",
    },
  ];

  const onModalOkClick = async () => {
    
    try {
      
      if (sellStockQuantity === 0) throw Error({ message: "Al menos se debe de vender 1 acción."});

      setModalVisible(false);
      setSubmitted(true);

      const request = {
        endpoint: `transacciones/${transactionId}/intenciones-venta/`,
        method: "POST",
        data: {
          accionista: user?.shareholder?.id,
          venta_acciones: sellStockQuantity
        }
      };

      const response = await http(request);

      setSubmitted(false);

      notification.success({
        message: "¡Correcto!",
        description: "Solicitud creada correctamente.",
      });

      if (position.v_firma_digital && position.v_firma_obligatoria) {
        const { accionista, id_operacion_firma_digital, id } = response;
        const queryParams = `transactionId=${transactionId}&rfc=${accionista?.rfc_firma}&operationId=${id_operacion_firma_digital}&transactionDetailId=${id}&messageId=1025`;
        navigate(`/pages/shareholders/sign-documents?${queryParams}`);
      } else {
        navigate("/pages/dashboard");
      }
    } catch (error) {
      
      setSubmitted(false);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const onSellChange = (value) => {

    if (!value) {

      const formValues = {
        ...form,
        sellStockPercent: 0,
        sellStockAmount: 0,
        commissionAmount: 0,
        commissionPercent: (position?.venta_comision_porcentaje / 100).toFixed(6),
        isrAmount: 0,
        isrPercent: (position?.venta_isr_porcentaje / 100).toFixed(6),
        sellTotal: 0,
        finalStockQuantity: form.currentStockQuantity,
        finalStockPercent: form.currentStockPercent,
        finalAmount: 0
      };

      setSellStockQuantity(0);
      setForm(formValues);

    } else {

      setSellStockQuantity(value);

      let formValues = {...form};

      formValues.sellStockPercent = ((value * formValues.currentStockPercent) / formValues.currentStockQuantity).toFixed(6);
      formValues.sellStockAmount = (value * formValues.stockPrice).toFixed(2);
      formValues.commissionAmount = ((parseFloat(formValues.sellStockAmount) * parseFloat(formValues.commissionPercent)) * -1).toFixed(2);
      formValues.isrAmount = ((parseFloat(formValues.sellStockAmount) * parseFloat(formValues.isrPercent)) * -1).toFixed(2);
      formValues.sellTotal = (parseFloat(formValues.sellStockAmount) + parseFloat(formValues.commissionAmount) + parseFloat(formValues.isrAmount)).toFixed(2);
      formValues.finalStockQuantity = parseFloat(formValues.currentStockQuantity) - parseFloat(value);
      formValues.finalStockPercent = (parseFloat(formValues.currentStockPercent) - parseFloat(formValues.sellStockPercent)).toFixed(6);
      formValues.finalAmount = (parseFloat(formValues.finalStockQuantity) * formValues.stockPrice).toFixed(2);

      setForm(formValues);

    }

  };

  const getData = async () => {

    try {

      setPageLoading(true);
      setPageError(undefined);

      const { results } = await http({ endpoint: `accionistas/${user?.shareholder?.id}/posicion/`});

      const positions = results.filter((current) => (parseInt(current.transaccion_id) === parseInt(transactionId)));
      
      if (positions.length === 0) throw { message: "Oferta no encontrada" };
      
      const current = positions[0];

      setForm({
        stockPrice: current?.precio_transaccion,
        currentStockQuantity: current?.acciones,
        currentStockPercent: (current?.posicion_inicial_porcentaje),
        currentAmount: current?.total,
        sellStockPercent: 0,
        sellStockAmount: 0,
        commissionAmount: 0,
        commissionPercent: (current?.venta_comision_porcentaje) / 100,
        isrAmount: 0,
        isrPercent: (current?.venta_isr_porcentaje) / 100,
        sellTotal: 0,
        finalStockQuantity: current?.acciones,
        finalStockPercent: current?.posicion_inicial_porcentaje,
        finalAmount: 0
      });

      setPosition(current);
      setPageLoading(false);

    } catch (error) {
      
      setPageLoading(false);
      setPageError(error);

    }
  
  };

  const pageHeaderExtra = (
    <Button
      onClick={() => setModalVisible(true)}
      loading={isSubmitted}
      disabled={sellStockQuantity <= 0}
      type="primary"
      size="small"
      ghost
      className="w100 mt1"
    >
      {!isSubmitted && <span>Crear</span>}
      {isSubmitted && <span>Creando</span>}
    </Button>
  )

  useEffect(
    () => {
      getData();
    },
    []
  )
  
  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && position &&
        <PageHeader
          title={"Intención de venta"}
          onBack={() => navigate("/pages/dashboard")}
          breadcrumb={{ routes, itemRender }}
          extra={pageHeaderExtra}
        >
          <Descriptions size="small">
            <Descriptions.Item label="Empresa">
              <Text>
                {position?.empresa}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Precio">
              <Text>
                {maskBigNumber(position?.precio_transaccion, "currency")}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Condiciones">
              <Tooltip title={position?.condiciones}>
                <Paragraph className="my0" ellipsis style={{maxWidth: '210px'}}>
                  {position?.condiciones}
                </Paragraph>
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      }
      {!isPageLoading && form && position &&
        <div className="bgWhite mt1" style={{ borderRadius: "5px"}}>
          <div className={styles.formContainer}>
            <div className={styles.formColumn}>
              <Text strong type="secondary" className={styles.header}>
                Posición Inicial
              </Text>
              <div className={styles.formRow}>
                <Text italic type="secondary" className="mr1">
                  Acciones:
                </Text>
                <Text strong className={styles.formQuantityInput}>
                  {maskBigNumber(form.currentStockQuantity)}
                </Text>
                <Text strong className={styles.formPercentInput}>
                  {maskPercentage(form.currentStockPercent)}
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text italic type="secondary">
                  Subtotal
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text italic type="secondary">
                  Comisión por operación {position?.venta_comision_porcentaje}%
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text italic type="secondary">
                  Retención de ISR {position.venta_isr_porcentaje}%
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text italic type="secondary">
                  Total:
                </Text>
              </div>
            </div>
            <div className={styles.formColumn}>
              <Text strong type="secondary" className={styles.header}>
                A Vender
              </Text>
              <div className={styles.formRow}>
                <InputNumber 
                  formatter={value => maskBigNumber(value)}
                  parser={value => value?.replace(INPUT_NUMBER_FORMAT, "")}
                  value={sellStockQuantity}
                  className={styles.formQuantityInput}
                  max={form.currentStockQuantity}
                  min={0}
                  controls={false}
                  onChange={onSellChange}
                  style={{ textAlign: "center"}}
                  autoFocus
                />
                <Text strong className={styles.formPercentInput}>
                  <strong>
                    {maskPercentage(form.sellStockPercent)}
                  </strong>
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text strong className={styles.formCurrencyInput}>
                  {maskBigNumber(form.sellStockAmount, "currency", 2)}
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text strong className={styles.formCurrencyInput}>
                  {maskBigNumber(form.commissionAmount, "currency", 2)}
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text strong className={styles.formCurrencyInput}>
                  {maskBigNumber(form.isrAmount, "currency", 2)}
                </Text>
              </div>
              <div className={styles.formRow}>
                <Text strong className={styles.formCurrencyInput}>
                  {maskBigNumber(form.sellTotal, "currency", 2)}
                </Text>
              </div>
            </div>
            <div className={styles.formColumn}>
              <Text strong type="secondary" className={styles.header}>
                Posición Final
              </Text>
              <div className={styles.formRow}>
                <Text strong className={styles.formQuantityInput}>
                  {maskBigNumber(form.finalStockQuantity)}
                </Text>
                <Text strong className={styles.formPercentInput}>
                  {maskPercentage(form.finalStockPercent)}
                </Text>
              </div>
            </div>
          </div>
        </div>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
      <Modal
        title="Confirmación"
        visible={isModalVisible}
        maskClosable={false}
        onOk={onModalOkClick}
        okText={"Aceptar"}
        onCancel={() => setModalVisible(false)}
      >
        <Text>
          Se procederá a la creación de la intención de venta.
        </Text>
      </Modal>
    </>
  )
}

export default SaleRequest;