import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { maskBigNumber } from "../../shared/utils";
import styles from "../styles/CustomPositionStatistic.module.css";

const CustomPositionStatistic = (props) => {

  const navigate = useNavigate();
  const { Text, Title } = Typography;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.row}>
          <div className="dFlex flexColumn w100">
            <Text ellipsis italic type="secondary">
              Empresa
            </Text>
            <Title ellipsis level={5}>
              {props?.empresa} 
            </Title>
          </div>
          <div className="dFlex flexColumn w100">
            <Text ellipsis italic type="secondary">
              Acciones
            </Text>
            <Title ellipsis level={5}>
              {maskBigNumber(props?.acciones)}
            </Title>
          </div>
        </div>
        <div className={styles.row}>
          <div className="dFlex flexColumn w100">
            <Text ellipsis italic type="secondary">
              Valor
            </Text>
            <Title ellipsis level={5}>
              {maskBigNumber(props?.precio, "currency")}
            </Title>
          </div>
          <div className="dFlex flexColumn w100">
            <Text ellipsis italic type="secondary">
              Total
            </Text>
            <Title ellipsis level={5}>
              {maskBigNumber(props?.total, "currency", 2)}
            </Title>
          </div>
        </div>
        {props.abierto_a_venta &&
          <div className={styles.row}>
            <Button
                size="small"
                block
                onClick={() => navigate(`/pages/shareholders/sale/request/transaction/${props?.transaccion_id}`)}
              >
                Vender
              </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default CustomPositionStatistic