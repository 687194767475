import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import Form from "../form/EventForm";
import { PageHeader, Button } from "antd";

const EventCreate = () => {

  const [isSubmitted, setSubmitted] = useState({ clicked: false, button: ""});
  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
    {
      path: "/pages/events/create",
      breadcrumbName: "Nuevo",
    },
  ];

  const pageHeaderExtras = [
    <Button
      size="small"
      disabled={isSubmitted.clicked}
      loading={isSubmitted.clicked && isSubmitted.button === "draft"}
      key={"eventDraftBtn"}
      onClick={() => setSubmitted({clicked: true, button: "draft"})}
    >
      Guardar Borrador
    </Button>,
    <Button
      type="primary"
      ghost
      size="small"
      key={"eventCreateBtn"}
      disabled={isSubmitted.clicked}
      loading={isSubmitted.clicked && isSubmitted.button === "create"}
      onClick={() => setSubmitted({clicked: true, button: "create"})}
    >
      Crear & Programar
    </Button>
  ];

  return (
    <>
      <PageHeader
        title="Nuevo Evento"
        onBack={() => navigate("/pages/events/overview")}
        breadcrumb={{ routes, itemRender }}
        extra={pageHeaderExtras}
      />
      <Form
        parentName={"create"}
        isSubmitted={isSubmitted}
        setSubmitted={setSubmitted}
      />
    </>
  );
};

export default EventCreate;