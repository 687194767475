import {
  useState
} from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  itemRender
} from "../../../shared/helpers/breadcrumb";
import {
  COMMON_REQUIRED_FORM_RULE,
  COMMON_PASSWORD_RULE
} from "../../../constants";
import http from "../../../services/http.service";
import {
  PageHeader,
  Card,
  Form,
  Row,
  Col,
  Input,
  notification,
  Button
} from "antd";
import {
  EyeTwoTone,
  EyeInvisibleOutlined
} from "@ant-design/icons";

const SettingsChangePassword = () => {

  const [form] = Form.useForm();
  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/settings/overview",
      breadcrumbName: "Configuraciones",
    },
    {
      path: "/pages/settings/change/password",
      breadcrumbName: "Cambiar Contraseña",
    },
  ];

  const onSubmitClick = async () => {

    try {

      await form.validateFields();

      setSubmitted(true);

      const values = form.getFieldsValue();

      const payload = {
        old_password: values.current,
        new_password: values.new,
      };

      const request = {
        endpoint: "usuarios/change-password/",
        method: "PUT",
        data: payload,
      };

      await http(request);

      setSubmitted(false);

      notification.success({
        message: "¡Correcto!",
        description: "Contraseña actualizada correctamente.",
      });

      navigate("/pages/settings/overview");

    } catch (error) {

      setSubmitted(false);

      if (error?.errorFields) return false;

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const pageHeaderExtras = (
    <Button
      onClick={() => onSubmitClick()}
      type='primary'
      size={"small"}
      ghost
      loading={isSubmitted}
    >
      Actualizar
    </Button>
  );

  return (
    <>
      <PageHeader
        title='Cambiar Contraseña'
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/settings/overview")}
        extra={pageHeaderExtras}
      />
      <Card className="mt1">
        <Form
          layout='vertical'
          form={form}
          className='bgWhite'
        >
          <Row gutter={24}>
            <Col xs={24} sm={8}>
              <Form.Item
                label='Actual'
                name='current'
                rules={COMMON_REQUIRED_FORM_RULE}
                hasFeedback
              >
                <Input.Password
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label='Nueva'
                name='new'
                rules={COMMON_PASSWORD_RULE}
                hasFeedback
              >
                <Input.Password
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default SettingsChangePassword;