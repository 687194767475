import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../../services/http.service";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import PageLoader from "../../../components/page-loader/PageLoader";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { ActionButton, ConfirmationButton } from "../../../components";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import { formatDateTime, getUniqueKey, maskPercentage } from "../../../shared/utils";
import {
  PageHeader,
  Tag,
  Tooltip,
  Descriptions,
  Typography,
  Card,
  Space
} from "antd";


const EventGuest = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [event, setEvent] = useState(undefined);

  const { Text, Paragraph } = Typography;
  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/events/overview",
      breadcrumbName: "Eventos",
    },
    {
      path: "/pages/events/overview?flag=true",
      breadcrumbName: `${event?.nombre_evento}`,
    },
    {
      path: `/pages/events/${id}/guests`,
      breadcrumbName: "Asistentes",
    },
  ];

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre_rs",
      filterName: "nombre_rs",
      search: true,
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Rol",
      dataIndex: "rol",
      ellipsis: { showTitle: false },
      render: (cell) => {
        const title = cell.tipo ? `${cell.rol} (${cell.tipo})` : `${cell.rol}`;
        return <Tooltip title={title}>
          {title}
        </Tooltip>
      },
    },
    {
      title: "Porcentaje",
      dataIndex: "porcentaje_de_participacion",
      width: 120,
      render: (cell) => (maskPercentage(cell) ),
    },
    {
      title: "Confirmación",
      dataIndex: "confirmacion_asistencia_status_d11",
      width: 120,
      align: "center",
      render: (cell) => {
        if (cell === "C") {
          return <Tag color={"green"}>Confirmado</Tag>
        } else if (cell === "R") {
          return <Tag color={"error"}>Rechazado</Tag>
        } else {
          return <Tag color={"processing"}>Pendiente</Tag>
        }
      }
    },
    {
      title: "Check-in",
      dataIndex: "checkin",
      width: 120,
      align: "center",
      render: (cell) => {
        if (cell) {
          return <Tag color={"green"}>Si</Tag>
        } else {
          return <Tag>No</Tag>
        }
      }
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      render: (cell, row) => (
        <Space>
          {row.checkin_status !== "S" && row?.status_evento === "EN" && (
            <ConfirmationButton
              key={getUniqueKey()}
              size={"small"}
              shape={"default"}
              className={"default"}
              value={"Check-In"}
              confirmContent={'A continuación se realizará el check-in del convocado.'}
              onFinish={() => getData()}
              request={{
                endpoint: `eventos/${id}/participantes/${row.accionista}/status/`,
                method: "PATCH",
                data: { status: "CHECK-IN", razon: "" }
              }}
            />
          )}
          {row?.status_evento !== "CM" && (
             <ActionButton
             tooltip={"Reenviar invitación"}
             value={"Reenviar Invitación"}
             className={"default"}
             size={"small"}
             shape={"default"}
             successMessage={"Correo enviado correctamente."}
             request={{
               endpoint:`eventos/${id}/reenviar-correo/`,
               method: 'PUT',
               data: { id_asistente_evento: cell }
             }}
           /> 
          )}
        </Space>
      )
    }
  ];

  const getData = async (props = null) => {
    try {
      setPageError(undefined);

      let endpoint = `eventos/${id}/asistentes/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const requests = [
        http({ endpoint }),
        http({ endpoint: `eventos/${id}/` }),
      ];

      const [
        guestResponse,
        eventResponse
      ] = await Promise.all(requests);

      setEvent(eventResponse);
      setPageLoading(false);

      const results = guestResponse.results.map((result) => ({ ...result, status_evento: eventResponse.status_evento_d10 }))

      setCustomTableConfig({
        data: results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `eventos/${id}/asistentes/`,
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: guestResponse?.count || 0,
          next: guestResponse?.next || "",
          prev: guestResponse?.previous || ""
        }
      });

    } catch (error) {
      setPageError(error);
    }
  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && event &&
        <>
          <PageHeader
            title='Convocados'
            breadcrumb={{ routes, itemRender }}
            onBack={() => navigate(-1)}
          >
            <Descriptions size="small" column={{xs: 1, sm: 1, lg: 3, xl: 3}}>
              <Descriptions.Item label="Compañía">
                <Text>
                  {event?.empresa?.nombre_corto}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Tipo">
                <Text>
                  {event?.tipo_evento_d3?.nombre}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Modalidad">
                {event?.virtual_presencial && <Text>Presencial</Text>}
                {!event?.virtual_presencial && <Text>Virtual</Text>}
              </Descriptions.Item>
              <Descriptions.Item label="Fecha">
                {!event?.inicio_real_fh && (
                  <Text>
                    {formatDateTime(event?.inicio_plan_fh)} 
                  </Text>
                )}
                {event?.inicio_real_fh && (
                  <Text>
                    {formatDateTime(event?.inicio_real_fh)} 
                  </Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Asistentes">
                <Text>
                  {event?.numero_de_asistentes}
                </Text>
              </Descriptions.Item>
              {event?.ubicacion &&
                <Descriptions.Item label="Ubicación">
                  <Text>{event?.ubicacion}</Text>
                </Descriptions.Item>
              }
              {event?.plataforma && 
                <Descriptions.Item label="Plataforma">
                  <Text>{event?.plataforma}</Text>
                </Descriptions.Item>
              }
              <Descriptions.Item label="Quorum">
                <Text>
                  {event?.quorum}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Invitados">
                <Text>
                  {event?.numero_de_invitados}
                </Text>
              </Descriptions.Item>
              {( event?.status_evento_d10 !== "CM" && event?.status_evento_d10 !== "EN") &&
                <Descriptions.Item label="">
                  <Text>
                  </Text>
                </Descriptions.Item>
              }
              {event?.link && 
                <Descriptions.Item label="Link">
                  <Paragraph style={{ width: "calc(100% - 1rem)" }} copyable ellipsis>
                    {event?.link}
                  </Paragraph>
                </Descriptions.Item>
              }
            </Descriptions>
          </PageHeader>
          <Card className="mt1">
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/events/overview"}
        />
      )}
    </>
  );
};

export default EventGuest