import { useEffect, useState } from "react";
import {
  Outlet,
  Link,
  useNavigate
} from "react-router-dom";
import styles from "../styles/BaseLayout.module.css";
import NavbarLogo from "../../shared/img/navbar-logo.png";
import DrawerLogo from "../../shared/img/logo.png";
import useAuth from "../../hooks/useAuth";
import { DRAWER_MODULES, STORAGE_TOKEN_NAME } from "../../constants";
import {
  Layout,
  Space,
  Divider,
  Button,
  Drawer,
  Menu
} from "antd";
import {
  UserOutlined,
  MenuOutlined,
  SettingOutlined
} from "@ant-design/icons";

const BaseLayout = () => {

  const [isMenuVisible, setMenuVisible] = useState(false);
  const [modules, setModules] = useState([]);

  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const { Header, Content } = Layout;

  const onMenuClick = (event) => {
    setMenuVisible(false);
    navigate(event.key);
  }

  const onLogoutClick = () => {
    sessionStorage.removeItem(STORAGE_TOKEN_NAME);
    setUser(undefined);
    window.location.reload(false);
  };

  useEffect(
    () => {
      const modulesFiltered = DRAWER_MODULES.filter((module) => (user.modules[module.id]));
      setModules(modulesFiltered);
    },
    [user.modules]
  );

  return (
    <Layout>
      <Header>
        <Link to='/pages/dashboard'>
          <img
            src={NavbarLogo}
            alt='intercam'
            width={131}
            height={19}
          />
        </Link>
        <Space split={<Divider type='vertical' />}>
          <div className={styles.navbarBtn}>
            <UserOutlined />
            <span className="ml1">{user?.firstName} {user?.lastName}</span>
          </div>
          <Button
            shape='circle'
            className={styles.navbarBtn}
            icon={<SettingOutlined />}
            onClick={() => navigate("/pages/settings/overview")}
          />
          <Button
            shape='circle'
            className={styles.navbarBtn}
            icon={<MenuOutlined />}
            onClick={() => setMenuVisible(true)}
          />
        </Space>
      </Header>
      <Content className='p4'>
        <div className='site-layout-content'>
          <Outlet />
        </div>
      </Content>
      <Drawer
        placement='right'
        closable={false}
        onClose={() => setMenuVisible(false)}
        visible={isMenuVisible}
        key={"menu-drawer"}>
        <div className={styles.drawerHeader}>
          <img src={DrawerLogo} alt='logo' />
          <p>
            {user?.firstName} {user?.lastName}
          </p>
          <Button
            className={"mAuto dBlock"}
            onClick={onLogoutClick}
            size={"small"}
            shape={"round"}
          >
            Cerrar Sesión
          </Button>
          <Divider style={{ margin: "12px 0"}} />
        </div>
        <Menu
          onClick={onMenuClick}
          mode='inline'
          selectable={false}
          items={modules}
        />
      </Drawer>
    </Layout>
  );
};

export default BaseLayout;
