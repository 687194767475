import {
  EMAIL_PATTERN,
  ALPHA_PATTERN,
  NUMERIC_PATTERN,
  CURP_PATTERN,
  RFC_PATTERN,
  ALPHA_NUMERIC_PATTERN
} from "../../../../constants";

export const TYPE_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  }
];

export const NAME_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: ALPHA_NUMERIC_PATTERN,
    message: "Solo se aceptan letras.",
  },
];

export const BIRTH_DATE_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  }
];

export const NATIONALITY_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: ALPHA_PATTERN,
    message: "Solo se aceptan letras.",
  },
];

export const CURP_FORM_RULE = [
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: CURP_PATTERN,
    message: "Formato incorrecto.",
  },
];

export const RFC_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: RFC_PATTERN,
    message: "Formato incorrecto.",
  },
];

export const TELEPHONE_FORM_RULE = [
  {
    validator(_, value) {
      if (!value) return Promise.reject(new Error("Campo requerido."));
      const sanitizedValue = value.split(".").join("");
      const regex = new RegExp(NUMERIC_PATTERN);
      if (!sanitizedValue) return Promise.reject(new Error("Campo requerido."));
      else if (sanitizedValue.length < 10) return Promise.reject(new Error("Mínimo 10 números."));
      else if (!regex.test(sanitizedValue)) return Promise.reject(new Error("Solo se aceptan números."));
      else return Promise.resolve();
    },
  },
];

export const EMAIL_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    whitespace: false,
    message: "No se acepta solo espacios.",
  },
  {
    pattern: EMAIL_PATTERN,
    message: "Formato incorrecto.",
  },
];