import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import {
  EMAIL_FORM_RULE,
  FIRST_NAME_FORM_RULE,
  LAST_NAME_FORM_RULE,
  ROLES_FORM_RULE
} from "./rules";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  notification,
  Card
} from "antd";

const UserForm = ({ isSubmitted, setSubmitted, user}) => {

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { Option } = Select;

  const onSubmitClick = async () => {

    try {

      if (!isSubmitted) return;
      
      await form.validateFields();

      setSubmitted(true);

      const values = form.getFieldsValue();

      const payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        tipo: values.roles,
        email: values.email
      };

      let request = {};

      if (!user) {

        request = {
          endpoint: "usuarios/register/",
          method: "POST",
          data: payload,
        };

      }

      if (user) {

        request = {
          endpoint: `usuarios/${user.id}/`,
          method: "PUT",
          data: payload,
        };

      }

      await http(request);

      setSubmitted(false);

      notification.success({
        message: "¡Correcto!",
        description: "Usuario guardado correctamente.",
      });

      navigate("/pages/users/overview");

    } catch (error) {

      setSubmitted(false);

      if (error?.errorFields) return false;

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const setValues = () => {

    form.setFieldsValue({
      "firstName": user.first_name,
      "lastName": user.last_name,
      "email": user.email,
      "roles": user.rol.map((role) => role.rol)
    });

  }

  useEffect(
    () => {
      onSubmitClick();
    },
    [isSubmitted]
  );

  useEffect(
    () => {
      if (user) setValues();
    },
    []
  );

  return (
    <>
      <Card className="mt1">
        <Form
          layout='vertical'
          form={form}
        >
          <Row gutter={24}>
            <Col xs={24} sm={8}>
              <Form.Item
                label='Nombre(s):'
                name='firstName'
                rules={FIRST_NAME_FORM_RULE}
                hasFeedback
              >
                <Input type={"text"} maxLength={25} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label='Apellido(s):'
                name='lastName'
                rules={LAST_NAME_FORM_RULE}
                hasFeedback
              >
                <Input type={"text"} maxLength={25} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label='Correo electrónico:'
                name='email'
                rules={EMAIL_FORM_RULE}
                hasFeedback
              >
                <Input type={"email"} maxLength={45} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label='Roles'
                name='roles'
                rules={ROLES_FORM_RULE}
                hasFeedback
              >
                <Select mode="multiple">
                  <Option value={"USUARIO"}>
                    Usuario
                  </Option>
                  <Option value={"ADMIN"}>
                    Administrador
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default UserForm;
