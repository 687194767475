import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { DownloadButton, PageLoader } from "../../../components";
import http from "../../../services/http.service";
import { maskBigNumber } from "../../../shared/utils";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import {
  PageHeader,
  Result,
  Button,
  Card,
  Table,
  Space,
  Descriptions,
  Tooltip,
  Typography
} from "antd";

const TransactionSettlement = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [documents, setDocuments] = useState([]);
  const [transaction, setTransaction] = useState();
  const navigate = useNavigate();
  let { id } = useParams();
  const { Paragraph } = Typography;
  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: `/pages/transactions/overview`,
      breadcrumbName: "Transacciones",
    },
    {
      path: `/pages/transactions/${id}/details`,
      breadcrumbName: `Detalle`,
    },
    {
      path: `/pages/transactions/${id}/settlement`,
      breadcrumbName: "Liquidación",
    }
  ];

  const columns = [
    {
      title: "Etiqueta",
      dataIndex: "nombre_documento"
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 250,
      render: (_, row) => (
        <Space size={"middle"}>
          <DownloadButton
            tooltip="Descargar"
            url={row.link}
            fileName={row.nombre_archivo}
            icon={<DownloadOutlined />}
          />
        </Space>
      ),
    }
  ];

  const getData = async () => {
    try {
      setLoading(true);
      setError(undefined);

      const promises = [
        http({ endpoint: `transacciones/${id}/detalle/`}),
        http({ endpoint: `transacciones/${id}/instrucciones_de_liquidación/` })
      ];

      const [
        detailResponse,
        settlementResponse
      ] = await Promise.all(promises);

      setTransaction(detailResponse);
      setDocuments([settlementResponse]);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => { getData(); }, []);

  return (
    <>
      {loading && !error && <PageLoader />}
      {!loading && !error &&
        <>
          <PageHeader
            title={"Documento con instrucciones de liquidación"}
            onBack={() => navigate(`/pages/transactions/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
          >
            <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2, lg: 3}}>
              <Descriptions.Item label="Empresa">
                {transaction?.empresa}
              </Descriptions.Item>
              <Descriptions.Item label="Transacción">
                {transaction?.id}
              </Descriptions.Item>
              <Descriptions.Item label="Condiciones">
                <Tooltip title={transaction?.condiciones}>
                  <Paragraph ellipsis className="my0" style={{maxWidth: '210px'}}>
                    {transaction?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label="Serie / Clase">
                {transaction?.serie_clase}
              </Descriptions.Item>
              <Descriptions.Item label="Precio">
                {maskBigNumber(transaction?.precio, "currency")}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>      
          <Card className="mt1">
            <Table
              columns={columns}
              dataSource={documents}
              size={"small"}
              rowKey="id"
            />
          </Card>
        </>
      }
      {!loading && error &&
        <Result
          status="500"
          title="¡Ups!"
          subTitle={`${error?.message}`}
          extra={[
            <Button key={"resultExitBtn"} onClick={() => navigate(`/pages/transactions/${id}/details`)}>Salir</Button>,
            <Button  key={"resultRetryBtn"} onClick={() => getData()} type="primary">Reintentar</Button>
          ]}
        />
      }
    </>
  )
}

export default TransactionSettlement