import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import http from '../../../../services/http.service';
import { itemRender } from '../../../../shared/helpers/breadcrumb';
import PageLoader from '../../../../components/page-loader/PageLoader';
import ErrorResult from '../../../../components/error-result/ErrorResult';
import { Editor, EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  PageHeader,
  Button,
  Card,
  notification,
} from 'antd';

const CompanyTemplateUpdate = () => {

  const [isSubmitted, setSubmitted] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [template, setTemplate] = useState(undefined);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const navigate = useNavigate();

  const { companyId, templateId } = useParams();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${companyId}/details`,
      breadcrumbName: `${company?.nombre_corto}`,
    },
    {
      path: `/pages/companies/${companyId}/templates`,
      breadcrumbName: 'Formatos',
    },
    {
      path: `/pages/companies/${companyId}/templates/${template?.codigo_documento}/update`,
      breadcrumbName: `${template?.codigo_documento}`,
    }
  ];

  const onSubmit = async () => {

    try {

      setSubmitted(true);

      const value = editorState.getCurrentContent().getPlainText();
      const request = {
        endpoint: `machotes/${templateId}/`,
        method: 'PATCH',
        data: { machote_html: value }
      };

      await http(request);

      setSubmitted(false);

      notification.success({
        message: '¡Correcto!',
        description: 'Machote actualizado correctamente.',
      });

    } catch(error) {

      setSubmitted(false);
      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  const getData = async () => {

    try {

      setPageLoading(true);
      setPageError(undefined);

      const promises = [
        http({ endpoint: `empresas/${companyId}/` }),
        http({ endpoint: `machotes/${templateId}/` })
      ]
      const [companyResponse, templateResponse] = await Promise.all(promises);

      setCompany(companyResponse);
      setTemplate(templateResponse);
      setPageLoading(false);
      setEditorState(() => EditorState.createWithContent(ContentState.createFromText(templateResponse.machote_html)))

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company && template &&
        <>
          <PageHeader
            title={`${template.nombre}`}
            onBack={() => navigate(`/pages/companies/${companyId}/templates`)}
            breadcrumb={{ routes, itemRender }}
            extra={
              <Button
                type='primary'
                ghost
                size="small"
                loading={isSubmitted}
                onClick={() => onSubmit()}
              >
                {!isSubmitted && <span>Guardar</span>}
                {isSubmitted && <span>Guardando</span>}
              </Button>
            }
          />
          <Card className={"mt1"}>
            <Editor editorState={editorState} onChange={setEditorState} />
            {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={`/pages/companies/${companyId}/templates`}
        />
      }
    </>
  )
}

export default CompanyTemplateUpdate;