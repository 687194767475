import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import http from '../../../services/http.service';
import PageLoader from "../../../components/page-loader/PageLoader";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import { maskBigNumber, maskPercentage } from "../../../shared/utils";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { RightOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Button,
  Typography,
  Tooltip,
  Card
} from 'antd';

const CompanyStocks = () => {

  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);

  const navigate = useNavigate();
  const { companyId } = useParams();
  
  const { Text } = Typography;

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/companies/overview',
      breadcrumbName: 'Compañías',
    },
    {
      path: `/pages/companies/${companyId}/details`,
      breadcrumbName: `${company?.nombre_corto}`,
    },
    {
      path: `/pages/companies/${companyId}/stocks`,
      breadcrumbName: 'Posición Actual',
    }
  ];

  const columns = [
    {
      title: 'Expediente',
      dataIndex: 'nombre_rs',
      filterName: 'accionista',
      search: true,
      ellipsis: { showTitle: false },
      render: cell => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: 'Posición Inicial',
      dataIndex: 'posicion_inicial',
      render: (cell, row) => (
        <>
          <Text className='mr1'>
            {maskBigNumber(cell)}
          </Text>
          <Text type="secondary">
            <small>
              {maskPercentage(row.porcentaje_inicial)}
            </small>
          </Text>
        </>
      )
    },
    {
      title: 'Posición Actual',
      dataIndex: 'posicion_final',
      render: (cell, row) => (
        <>
          <Text className='mr1'>
            {maskBigNumber(cell)}
          </Text>
          <Text type="secondary">
            <small>
              {maskPercentage(row.porcentaje_final)}
            </small>
          </Text>
        </>
      )
    },
    {
      title: '',
      dataIndex: 'accionista_id',
      width: 80,
      align: "center",
      render: (cell) => (
        <Tooltip title={"Detalle"}>
          <Button
            size={"small"}
            className="noBorderColor bgTransparent"
            onClick={() => navigate(`/pages/companies/${companyId}/stocks/${cell}/details`)}
            shape={"circle"}
            icon={<RightOutlined />}
          />
        </Tooltip>
      ),
    },
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = `empresas/${companyId}/posicion-actual/`;
      let queryParams = [];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint })

      setCompany(response);

      setCustomTableConfig({
        data:response.posicion_actual,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: `empresas/${companyId}/posicion-actual/`,
        url: endpoint,
        rowKey: "accionista_id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && company &&
        <>
          <PageHeader
            title={`${company?.razon_social}`}
            subTitle={`${maskBigNumber(company.total_acciones)} acciones`}
            onBack={() => navigate(`/pages/companies/overview`)}
            breadcrumb={{ routes, itemRender }}
          />
          <Card className={"mt1"}>
            <div className="tableContainer">
              <CustomTable config={customTableConfig} />
            </div>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
    </>
  )
}

export default CompanyStocks;