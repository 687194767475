import { Routes, Route } from "react-router-dom";

import BaseLayout from "../components/base-layout/BaseLayout";
import NotFound from "../pages/not-found/NotFound";
import Dashboard from "../pages/dashboard/Dashboard";
import PageLoader from "../components/page-loader/PageLoader";

import CompanyCreate from "../pages/companies/create/CompanyCreate";
import CompanyDetails from "../pages/companies/details/CompanyDetails";
import CompanyOverview from "../pages/companies/overview/CompanyOverview";
import CompanyStocks from "../pages/companies/stocks/CompanyStocks";
import CompanyUpdate from "../pages/companies/update/CompanyUpdate";
import CompanyStocksDetails from "../pages/companies/stocks/details/CompanyStocksDetails";
import CompanyTemplates from "../pages/companies/templates/CompanyTemplates";
import CompanyTemplateUpdate from "../pages/companies/templates/update/CompanyTemplateUpdate"

import ShareholderCreate from "../pages/shareholders/create/ShareholderCreate";
import ShareholderDetails from "../pages/shareholders/details/ShareholderDetails";
import ShareholderOverview from "../pages/shareholders/overview/ShareholderOverview";
import ShareholderUpdate from "../pages/shareholders/update/ShareholderUpdate";
import SaleRequest from "../pages/shareholders/sale-request/SaleRequest";
import PurchaseOffer from "../pages/shareholders/purchase-offer/PurchaseOffer";
import PurchaseOfferInvite from "../pages/shareholders/purchase-offer-invite/PurchaseOfferInvite";
import ExtraStockAllocation from "../pages/shareholders/extra-stocks/ExtraStockAllocation";
import ShareholderEvent from "../pages/shareholders/events/ShareholderEvent";

import TransactionsOverview from "../pages/transactions/overview/TransactionOverview";
import TransactionAlert from "../pages/transactions/alert/TransactionAlert.jsx";
import TransactionCreate from "../pages/transactions/create/TransactionCreate";
import TransactionDocuments from "../pages/transactions/documents/TransactionDocuments";
import TransactionDetail from "../pages/transactions/details/TransactionDetail";
import TransactionInvite from "../pages/transactions/invite/TransactionInvite";
import TransactionSettlement from "../pages/transactions/settlement/TransactionSettlement";
import TransactionSignDocuments from "../pages/transactions/sign-documents/TransactionSignDocuments";
import TransactionContracts from "../pages/transactions/contracts/TransactionContracts";
import TransactionSignOffers from "../pages/transactions/sign-offers/TransactionSignOffers";

import EventOverview from "../pages/events/overview/EventOverview";
import EventCreate from "../pages/events/create/EventCreate";
import EventUpdate from "../pages/events/update/EventUpdate";
import EventGuest from "../pages/events/guests/EventGuest";
import EventManage from "../pages/events/manage/EventManage";
import EventMemorandum from "../pages/events/memorandum/EventMemorandum";
import EventVotes from "../pages/events/votes/EventVotes";

import useAuth from "../hooks/useAuth";
import { ModuleGuard } from "./ModuleGuard";

import ShareholderEventHistoric from "../pages/shareholders/events/historic/ShareholderEventHistoric";
import ShareholderEventMemorandum from "../pages/shareholders/events/memorandum/ShareholderEventMemorandum";
import ShareholderDocumentVariables from "../pages/shareholders/document-variables/ShareholderDocumentVariables";
import ShareholderDocumentVariableUpdate from "../pages/shareholders/update/ShareholderDocumentVariableUpdate";
import ShareholderSignDocuments from "../pages/shareholders/sign-documents/ShareholderSignDocuments";

import UserOverview from "../pages/users/overview/UserOverview";
import UserCreate from "../pages/users/create/UserCreate";
import UserUpdate from "../pages/users/update/UserUpdate";

import SettingsOverview from "../pages/settings/overview/SettingsOverview";
import SettingsChangePassword from "../pages/settings/change-password/SettingsChangePassword";
import SettingsLiterax from "../pages/settings/literax/SettingsLiterax";
import SettingsAlerts from "../pages/settings/alerts/SettingsAlerts.jsx";
import SettingsParams from "../pages/settings/params/SettingsParams.jsx";
import CompanyIncreaseStocks from "../pages/companies/stocks/increase/CompanyIncreaseStocks.jsx";
import CompanyIncreaseStocksDetails from "../pages/companies/stocks/increase/details/CompanyIncreaseStocksDetails.jsx";

export const PagesRoutes = () => {

  const { user } = useAuth();

  return (
    <>
      {!user && <PageLoader />}
      {user &&
        <div className="container">
          <Routes>
            <Route
              path="/pages"
              element={<BaseLayout />}
            >
              <Route
                path="dashboard"
                element={<Dashboard />}
              />
              <Route path="events">
                <Route path="overview" element={<EventOverview />} />
                <Route path="create" element={<EventCreate />} />
                <Route path=":id/update" element={<EventUpdate />} />
                <Route path=":id/guests" element={<EventGuest />} />
                <Route path=":id/manage" element={<EventManage />} />
                <Route path=":id/memorandum" element={<EventMemorandum />} />
                <Route path=":eventId/activity/:activityId/votes" element={<EventVotes />} />
              </Route>
              <Route path="shareholders">
                <Route
                  path="overview"
                  element={
                    <ModuleGuard module={"1100"}>
                      <ShareholderOverview />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="create"
                  element={
                    <ModuleGuard module={"1100"}>
                      <ShareholderCreate />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/details"
                  element={
                    <ModuleGuard module={"1101"}>
                      <ShareholderDetails />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/documents/variables"
                  element={
                    <ModuleGuard module={"1101"}>
                      <ShareholderDocumentVariables />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":shareholderId/documents/variables/:fieldId/update"
                  element={
                    <ModuleGuard module={"1101"}>
                      <ShareholderDocumentVariableUpdate />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/update"
                  element={
                    <ModuleGuard module={"1100"}>
                      <ShareholderUpdate />
                    </ModuleGuard>
                  }
                />
                <Route path=":id/events" element={<ShareholderEvent />} />
                <Route path="events/historic" element={<ShareholderEventHistoric />} />
                <Route path="events/:id/memorandum" element={<ShareholderEventMemorandum />} />
                <Route
                  path="sale/request/transaction/:transactionId"
                  element={
                    <ModuleGuard module={"3010"}>
                      <SaleRequest />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="purchase/offer/transaction/:transactionId"
                  element={
                    <ModuleGuard module={"3040"}>
                      <PurchaseOffer />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="extra/stock/allocation/transaction/:transactionId"
                  element={
                    <ModuleGuard module={"3040"}>
                      <ExtraStockAllocation />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="sign-documents"
                  element={<ShareholderSignDocuments />}
                />
                <Route
                  path="transaction/:transactionId/invite"
                  element={<PurchaseOfferInvite />}
                />
              </Route>
              <Route path="transactions">
                <Route
                  path="overview"
                  element={
                    <ModuleGuard module={"3005"}>
                      <TransactionsOverview />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="create" element={
                    <ModuleGuard module={"3005"}>
                      <TransactionCreate />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/details/:detailId/alerts" element={
                    <ModuleGuard module={"3090"}>
                      <TransactionAlert />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/documents"
                  element={
                    <ModuleGuard module={"3070"}>
                      <TransactionDocuments />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/contracts"
                  element={
                    <ModuleGuard module={"3070"}>
                      <TransactionContracts />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="sign-documents"
                  element={
                    <ModuleGuard module={"3007"}>
                      <TransactionSignDocuments />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":transactionId/sign-offer/:shareholderId"
                  element={
                    <ModuleGuard module={"3007"}>
                      <TransactionSignOffers />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/details"
                  element={
                    <ModuleGuard module={"3007"}>
                      <TransactionDetail />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/invite"
                  element={
                    <ModuleGuard module={"3060"}>
                      <TransactionInvite />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/settlement"
                  element={
                    <ModuleGuard module={"3080"}>
                      <TransactionSettlement />
                    </ModuleGuard>
                  }
                />
              </Route>
              <Route path="companies">
                <Route
                  path="overview"
                  element={
                    <ModuleGuard module={"1200"}>
                      <CompanyOverview />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/details"
                  element={
                    <ModuleGuard module={"1201"}>
                      <CompanyDetails />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/increase-in-capital"
                  element={
                    <ModuleGuard module={"1201"}>
                      <CompanyIncreaseStocks />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":companyId/increase-in-capital/:stockDraftId/details"
                  element={
                    <ModuleGuard module={"1201"}>
                      <CompanyIncreaseStocksDetails />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/templates"
                  element={
                    <ModuleGuard module={"1201"}>
                      <CompanyTemplates />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":companyId/templates/:templateId/update"
                  element={
                    <ModuleGuard module={"1201"}>
                      <CompanyTemplateUpdate />
                    </ModuleGuard>
                  }
                />
                <Route
                  path="create"
                  element={
                    <ModuleGuard module={"1200"}>
                      <CompanyCreate />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":id/update"
                  element={
                    <ModuleGuard module={"1200"}>
                      <CompanyUpdate />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":companyId/stocks"
                  element={
                    <ModuleGuard module={"1200"}>
                      <CompanyStocks />
                    </ModuleGuard>
                  }
                />
                <Route
                  path=":companyId/stocks/:shareholderId/details"
                  element={
                    <ModuleGuard module={"1200"}>
                      <CompanyStocksDetails />
                    </ModuleGuard>
                  }
                />
              </Route>
              <Route path="users">
                <Route
                  path="overview"
                  element={<UserOverview />}
                />
                <Route
                  path="create"
                  element={<UserCreate />}
                />
                <Route
                  path=":id/update"
                  element={<UserUpdate />}
                />
              </Route>
              <Route path="settings">
                <Route
                  path="overview"
                  element={<SettingsOverview />}
                />
                <Route
                  path="change/password"
                  element={<SettingsChangePassword />}
                />
                <Route
                  path="literax"
                  element={<SettingsLiterax />}
                />
                <Route
                  path="alerts"
                  element={<SettingsAlerts />}
                />
                <Route
                  path="params"
                  element={<SettingsParams />}
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      }
    </>
  );
};
