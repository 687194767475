import {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import http from '../../../services/http.service';
import Form from "../form/ShareholderDocumentVariableForm";
import PageLoader from '../../../components/page-loader/PageLoader';
import { itemRender } from '../../../shared/helpers/breadcrumb';
import ErrorResult from '../../../components/error-result/ErrorResult';
import {
  PageHeader,
  Button
} from 'antd';

const ShareholderDocumentVariableUpdate = () => {

  const [isSubmitted, setSubmitted] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [shareholder, setShareholder] = useState(undefined);
  const [variable, setVariable] = useState(undefined);

  const navigate = useNavigate();
  const { shareholderId, fieldId } = useParams();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/shareholders/overview',
      breadcrumbName: 'Participantes',
    },
    {
      path: `/pages/shareholders/${shareholderId}/details`,
      breadcrumbName: `${shareholder?.nombre_rs}`,
    },
    {
      path: `/pages/shareholders/${shareholderId}/documents/variables`,
      breadcrumbName: 'Datos para documentos',
    },
    {
      path: `/pages/shareholders/${shareholderId}/documents/variables/update`,
      breadcrumbName: 'Editar',
    }
  ];

  const getData = async () => {

    try {

      setPageLoading(true);
      setPageError(undefined);

      const requests = [
        http({ endpoint: `accionistas/${shareholderId}/datos-para-documentos/${fieldId}/` }),
        http({ endpoint: `accionistas/${shareholderId}/` })
      ]

      const [
        variableResponse,
        shareholderResponse
      ] = await Promise.all(requests);


      setVariable({...variableResponse, shareholderId, fieldId });
      setShareholder(shareholderResponse);
      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && variable &&
        <>
          <PageHeader
            title={"Editar datos"}
            onBack={() => navigate(`/pages/shareholders/${shareholderId}/documents/variables`)}
            breadcrumb={{ routes, itemRender }}
            extra={
              <Button
                type='primary'
                ghost
                size="small"
                loading={isSubmitted}
                onClick={() => setSubmitted(true)}
              >
                {!isSubmitted && <span>Guardar</span>}
                {isSubmitted && <span>Guardando</span>}
              </Button>
            }
          />
          <Form
            parentName={"update"}
            variable={variable}
            isSubmitted={isSubmitted}
            setSubmitted={setSubmitted}
          />
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={`/pages/shareholders/${shareholderId}/documents/variables`}
        />
      }
    </>
  )
}

export default ShareholderDocumentVariableUpdate;