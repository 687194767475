import { useState } from 'react';
import { Tooltip, Button, notification, Modal } from 'antd';
import http from "../../services/http.service";
import { getUniqueKey } from '../../shared/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * Delete Button
 * Component that extract the file endpoint from URL and generate a signed url
 * @param { tooltip } props: tooltip text
 * @param { endpoint } props: alertas-config/
 * @param { icon } props: Ant Design Icon <FilePdfOutlined />
 * @param { confirmTitle, confirmContent, confirmText, confirmCancelText } props: modal
 * @returns {Component}
 */
const DeleteButton = (props) => {
  const [loading, setLoading] = useState(false);
  const { confirm } = Modal;

  const onDeleteClick = async () => {
    try {
      if (!props.endpoint) return;

      setLoading(true);

      const request = {
        endpoint: props.endpoint,
        method: 'DELETE'
      }

      await http(request);

      setLoading(false);
      notification.success({ message: '¡Correcto!', description: "Eliminado correctamente." });

      if (props?.onFinish) props.onFinish()
    } catch (error) {
      setLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  return (
    <Tooltip
      placement="top"
      title={props.tooltip || 'Eliminar'}
      key={getUniqueKey()}>
        <Button
          key={getUniqueKey()}
          size={props.size || "small"}
          shape={props.shape || "circle"}
          icon={props.icon}
          loading={loading}
          className={props.className || "noBorderColor bgTransparent"}
          onClick={() => {
            confirm({
              title: props?.confirmTitle || '¿Usted esta seguro?',
              icon: <ExclamationCircleOutlined />,
              content:  props?.confirmContent || `Se eliminará el registro permanentemente.`,
              okText: props?.confirmText || 'Aceptar',
              okType: 'danger',
              cancelText:  props?.confirmCancelText ||'Cancelar',
              onOk() { onDeleteClick() },
            })
          }}
        >
          {props.value || ''}
        </Button>
    </Tooltip>
  );
}

export default DeleteButton;