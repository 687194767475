import {
  useNavigate,
  useParams
} from "react-router-dom";
import {
  useEffect,
  useState
} from "react";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import PageLoader from "../../../components/page-loader/PageLoader";
import http from "../../../services/http.service";
import useAuth from "../../../hooks/useAuth";
import ErrorResult from "../../../components/error-result/ErrorResult";
import {
  formatDateTime,
  maskBigNumber
} from "../../../shared/utils";
import {
  PageHeader,
  Descriptions,
  Tooltip,
  Typography
} from "antd";

const ExtraStockAllocation = () => {

  const [isPageLoading, setPageLoading] = useState(false);
  const [pageError, setPageError] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [detail, setDetail] = useState(undefined);
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const { user } = useAuth();

  const { Paragraph } = Typography;
  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: `/pages/extra-stocks-allocation/${transactionId}`,
      breadcrumbName: "Acciones Adicionales",
    }
  ];

  const getTransaction = async () => {

    try {
      
      setPageLoading(true);
      setPageError(undefined);

      const response = await http({ endpoint: `transacciones/${transactionId}/detalle/` });
  
      const details = response.detalles_transaccion.filter((current) => (current?.accionista?.id === user?.shareholder?.id));

      if (details && details.length > 0) {
        setDetail(details[0]);
      } else {
        throw { message: "Información no encontrada." }
      }

      setTransaction(response);
      setPageLoading(false);

    } catch (error) {

      setPageLoading(false);
      setPageError(error);

    }

  };

  useEffect(
    () => {
      getTransaction();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && transaction && detail &&
        <>
          <PageHeader
            title={"Acciones Adicionales"}
            subTitle={`fueron asignadas el ${formatDateTime(detail.extra_registro_fh)}`}
            onBack={() => navigate("/pages/dashboard")}
            breadcrumb={{ routes, itemRender }}
          >            
            <Descriptions size="small" column={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
              <Descriptions.Item label={"Compañía"}>
                {transaction.empresa}
              </Descriptions.Item>
              <Descriptions.Item label={"Solicitadas"}>
                {maskBigNumber(detail.derecho_solicita_extra)}
              </Descriptions.Item>
              <Descriptions.Item label={"Precio"}>
                {maskBigNumber(transaction.precio, "currency")}
              </Descriptions.Item>
              <Descriptions.Item label={"Codiciones"}>
                <Tooltip title={transaction?.condiciones}>
                  <Paragraph className="my0" ellipsis style={{maxWidth: '210px'}}>
                    {transaction?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label={"Asignadas"}>
                {maskBigNumber(detail.extra_asignado)}
              </Descriptions.Item> 
              <Descriptions.Item label={"Total"}>
                {maskBigNumber(detail.extra_total, "currency", 2)}
              </Descriptions.Item> 
            </Descriptions>
          </PageHeader>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getTransaction}
          exitPath={"/pages/dashboard"}
        />
      }
    </>
  )
}

export default ExtraStockAllocation