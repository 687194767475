import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import http from "../../../services/http.service";
import { DownloadButton, ErrorResult, PageLoader } from "../../../components"
import {
  maskBigNumber,
  formatDate,
  formatDateTime,
  maskTelephone,
  maskTelephoneField,
  maskPercentage
} from "../../../shared/utils";
import {
  COMMON_REQUIRED_FORM_RULE,
  COMMON_ACCEPT_FILE_FORMATS,
  COMMON_EMAIL_FORM_RULE,
  COMMON_TELEPHONE_FORM_RULE,
  COMMON_ALPHA_FORM_RULE
} from "../../../constants";
import {
  PageHeader,
  Button,
  Card,
  Table,
  Descriptions,
  Tag,
  Space,
  Modal,
  Form,
  Input,
  notification,
  Checkbox,
  Upload,
  Tooltip,
  Select,
  Typography,
  Collapse
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined
} from "@ant-design/icons";

const ShareholderDetails = () => {

  const USER_DEFAULT_MODAL_STATE = {
    createVisible: false,
    createLoading: false,
    deleteVisible: false,
    deleteLoading: false
  };

  const REPRESENTATIVE_DEFAULT_MODAL_STATE = {
    createVisible: false,
    createLoading: false,
    editVisible: false,
    editLoading: false,
    deleteVisible: false,
    deleteLoading: false
  };

  const DOCUMENT_DEFAULT_MODAL_STATE = {
    createVisible: false,
    createLoading: false,
    deleteVisible: false,
    deleteLoading: false
  };

  const [pageError, setPageError] = useState(undefined);
  const [shareholder, setShareholder] = useState(undefined);

  const [representativeForm] = Form.useForm();
  const [userForm] = Form.useForm();
  const [documentForm] = Form.useForm();

  const [representativeModalState, setRepresentativeModalState] = useState(REPRESENTATIVE_DEFAULT_MODAL_STATE);
  const [userModalState, setUserModalState] = useState(USER_DEFAULT_MODAL_STATE);
  const [documentModalState, setDocumentModalState] = useState(DOCUMENT_DEFAULT_MODAL_STATE);

  const [selectedRepresentative, setSelectedRepresentative] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState([]);

  const [users, setUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [representatives, setRepresentatives] = useState([]);

  const [isPageLoading, setPageLoading] = useState(true);
  const [isPositionTableLoading, setPositionTableLoading] = useState(true);
  const [isDocumentTableLoading, setDocumentTableLoading] = useState(true);
  const [isRepresentativeTableLoading, setRepresentativeTableLoading] = useState(true);
  const [isUserTableLoading, setUserTableLoading] = useState(true);
  const [canRepresentativeBePrimary, setCanRepresentativeBePrimary] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();

  const { Option } = Select;
  const { Text, Paragraph } = Typography;
  const { Panel } = Collapse;

  const getAvailableUsers = async () => {

    try {

      const endpoint = "usuarios/?no_page=true";

      const response = await http({ endpoint });

      const filtered = response.filter(({ accionistas }) => accionistas.length === 0);

      setAvailableUsers(filtered);

    } catch (error) {

      notification.error({
        message: "¡Ups!",
        description: "No se pudo recuperar los usuarios disponibles.",
      });

    }

  };

  const getPositions = async () => {

    try {

      setPositionTableLoading(true);

      const endpoint = `accionistas/${id}/posicion/?no_page=true`;

      const response = await http({ endpoint });

      setPositions(response);
      setPositionTableLoading(false);

    } catch (error) {

      setPositionTableLoading(false);

      notification.error({
        message: "¡Ups!",
        description: "No se pudo recuperar las posiciones.",
      });

    }

  };

  const getDocuments = async () => {

    try {

      setDocumentTableLoading(true);

      const endpoint = `documentos-personales/${id}/?no_page=true`;

      const documents = await http({ endpoint });

      setDocuments(documents);
      setDocumentTableLoading(false);

    } catch (error) {

      setDocumentTableLoading(false);

      notification.error({
        message: "¡Ups!",
        description: "No se pudo recuperar los documentos.",
      });

    }

  };

  const getRepresentatives = async () => {

    try {

      setRepresentativeTableLoading(true);

      const endpoint = `representante-legal/?accionista=${id}&no_page=true`;

      const representatives = await http({ endpoint });

      const isAnyOnePrimary = representatives.some((representative) => representative.principal);

      if (isAnyOnePrimary) setCanRepresentativeBePrimary(false);

      setRepresentatives(representatives);
      setRepresentativeTableLoading(false);

    } catch (error) {

      setRepresentativeTableLoading(false);

      notification.error({
        message: "¡Ups!",
        description: "No se pudo recuperar los representantes legales.",
      });

    }

  };

  const getUsers = async () => {

    try {

      setUserTableLoading(true);

      const endpoint = `usuarios-autorizados/?accionista=${id}&no_page=true`;

      const users = await http({ endpoint });

      setUsers(users);
      setUserTableLoading(false);

    } catch (error) {

      setUserTableLoading(false);

      notification.error({
        message: "¡Ups!",
        description: "No se pudo recuperar los usuarios autorizados.",
      });

    }

  };

  const getShareholder = async () => {

    try {

      setPageLoading(true);
      setPageError(undefined);

      const endpoint = `accionistas/${id}/`;

      const response = await http({ endpoint });

      getPositions();
      getDocuments();
      getUsers();
      getAvailableUsers();

      if (response.tipo_accionista_d2.valor != "PF") getRepresentatives();

      setShareholder(response);
      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  const onRepresentativeTelephoneChange = (e) => {

    e.preventDefault();
    const value = e.target.value;
    const newValue = maskTelephoneField(value);
    representativeForm.setFieldsValue({ "telephone": newValue });

  }

  const onRepresentativeCreateOkClick = async () => {

    try {

      await representativeForm.validateFields();

      setRepresentativeModalState({ ...representativeModalState, createLoading: true });

      const telephone = representativeForm.getFieldValue("telephone");

      const payload = {
        nombre: representativeForm.getFieldValue("name"),
        principal: representativeForm.getFieldValue("isPrimary"),
        telefono: telephone.split(".").join(""),
        correo: representativeForm.getFieldValue("email"),
        accionista: parseInt(id, 10),
        usuario: 1,
        status_d1: "A"
      };

      const request = {
        endpoint: "representante-legal/",
        method: "POST",
        data: payload
      };

      await http(request);

      setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);

      representativeForm.resetFields();

      notification.success({
        message: "¡Correcto!",
        description: "Representante Legal creado correctamente.",
      });

      getRepresentatives();

    } catch(error) {

      if (error?.errorFields) return false;

      setRepresentativeModalState({ ...representativeModalState, createLoading: false });

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const onRepresentativeEditOkClick = async () => {

    try {

      await representativeForm.validateFields();

      setRepresentativeModalState({...representativeModalState, editLoading: true });

      const telephone = representativeForm.getFieldValue("telephone");

      const payload = {
        nombre: representativeForm.getFieldValue("name"),
        principal: representativeForm.getFieldValue("isPrimary"),
        telefono: telephone.split(".").join(""),
        correo: representativeForm.getFieldValue("email"),
        accionista: parseInt(id, 10),
        usuario: 1,
        status_d1: "A"
      };

      const request = {
        endpoint: `representante-legal/${selectedRepresentative.id}/`,
        method: "PUT",
        data: payload
      };

      await http(request);

      setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);
      setSelectedRepresentative(undefined);

      representativeForm.resetFields();

      notification.success({
        message: "¡Correcto!",
        description: "Representante legal editado correctamente.",
      });

      getRepresentatives();

    } catch(error) {

      if (error?.errorFields) return false;

      setRepresentativeModalState({...representativeModalState, editLoading: false });

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const onRepresentativeDeleteOkClick = async () => {

    try {

      setRepresentativeModalState({...representativeModalState, deleteLoading: true });

      const request = {
        endpoint: `representante-legal/${selectedRepresentative.id}/`,
        method: "DELETE"
      };

      await http(request);

      setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);
      setSelectedRepresentative(undefined);

      notification.success({
        message: "¡Correcto!",
        description: "Representante legal eliminado correctamente.",
      });

      getRepresentatives();

    } catch (error) {

      setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  }

  const onUserCreateOkClick = async () => {

    try {

      await userForm.validateFields();

      setUserModalState({
        ...userModalState,
        createLoading: true
      });

      const payload = {
        usuario: userForm.getFieldValue("user"),
        accionista: id,
        principal: false
      };

      const request = {
        endpoint: "/usuarios-autorizados/",
        method: "POST",
        data: payload
      };

      await http(request);

      setUserModalState(USER_DEFAULT_MODAL_STATE);

      userForm.resetFields();

      notification.success({
        message: "¡Correcto!",
        description: "Usuario agregado correctamente.",
      });

      getAvailableUsers();
      getUsers();

    } catch(error) {

      if (error?.errorFields) return false;

      setUserModalState({
        ...userModalState,
        createLoading: false
      });

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  };

  const onUserDeleteOkClick = async () => {

    try {

      setUserModalState({
        ...representativeModalState,
        deleteLoading: true
      });

      const request = {
        endpoint: `usuarios-autorizados/${selectedUser.id}/`,
        method: "DELETE"
      };

      await http(request);

      setUserModalState(USER_DEFAULT_MODAL_STATE);
      setSelectedUser(undefined);

      notification.success({
        message: "¡Correcto!",
        description: "Usuario eliminado correctamente.",
      });

      getUsers();

    } catch (error) {

      setUserModalState(USER_DEFAULT_MODAL_STATE);

      notification.error({
        message: "¡Ups!",
        description: error.message,
      });

    }

  }

  const onDocumentCreateOkClick = async () => {

    try {

      await documentForm.validateFields();

      setDocumentModalState({
        ...documentModalState,
        createLoading: true
      });

      const formData = new FormData();
      const file = selectedFile[0];
      const name = documentForm.getFieldValue("name");

      formData.append("tipo_documento_d8", 30);
      formData.append("nombre_documento", name);
      formData.append("accionista", id);
      formData.append('nombre_archivo', file);

      const request = {
        endpoint: `documentos-personales/${id}/`,
        method: "POST",
        data: formData
      };

      await http(request);

      setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);

      documentForm.resetFields();

      notification.success({
        message: '¡Correcto!',
        description: 'Documento creado correctamente.',
      });

      getDocuments();

    } catch(error) {

      if (error?.errorFields) return false;

      setDocumentModalState({
        ...documentModalState,
        createLoading: false
      });

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  const onDeleteDocumentClick = async () => {

    try {

      setDocumentModalState({
        ...selectedDocument,
        deleteLoading: true
      });

      const request = {
        endpoint: `documentos-personales/${id}/${selectedDocument.id}/`,
        method: "DELETE"
      };

      await http(request);

      setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);

      notification.success({
        message: '¡Correcto!',
        description: 'Documento eliminado correctamente.',
      });

      getDocuments();

    } catch(error) {

      setDocumentModalState({
        ...selectedDocument,
        deleteLoading: false
      });

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  }

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e?.fileList;
  };

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/shareholders/overview",
      breadcrumbName: "Participantes",
    },
    {
      path: `/pages/shareholders/${id}/update`,
      breadcrumbName: `${shareholder?.nombre_rs || id}`,
    },
    {
      path: `/pages/shareholders/${id}/details`,
      breadcrumbName: "Detalle",
    }
  ];

  const positionColumns = [
    {
      title: "Empresa",
      dataIndex: "empresa"
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      align: "center",
      width: 150,
      render: (cell) => (
        <Text>
          {maskBigNumber(cell)}
        </Text>
      )
    },
    {
      title: "Precio",
      dataIndex: "precio",
      align: "center",
      width: 150,
      render: (cell) => (
        <Text>
          {maskBigNumber(cell, "currency")}
        </Text>
      )
    },
    {
      title: "Porcentaje",
      dataIndex: "posicion_inicial_porcentaje",
      align: "center",
      width: 120,
      render: (cell) => (
        <Text>
          {maskPercentage(cell)}
        </Text>
      )
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "center",
      width: 200,
      render: (cell) => (
        <Text>
          {maskBigNumber(cell, "currency", 2)}
        </Text>
      )
    }
  ];

  const documentColumns = [
    {
      title: "Etiqueta",
      dataIndex: "nombre_documento",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre_archivo",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Creado en",
      dataIndex: "creado_fh",
      width: 130,
      align: "center",
      render: (cell) => (
        <Text>
          {formatDateTime(cell)}
        </Text>
      )
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 160,
      render: (_, row) => (
        <Space size={"small"}>
          <DownloadButton
            tooltip="Descargar"
            url={row.link}
            fileName={row.nombre_archivo}
            icon={<DownloadOutlined />}
          />
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              icon={<DeleteOutlined />}
              onClick={
                () => {
                  setSelectedDocument(row);
                  setDocumentModalState({
                    ...documentModalState,
                    deleteVisible: true
                  });
                }
              }
            />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const representativesColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={cell}>
          {cell}
        </Tooltip>
      ),
    },
    {
      title: "Correo",
      dataIndex: "correo",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Paragraph className="my0" copyable>
          {cell}
        </Paragraph>
      ),
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      width: 130,
      align: "center",
      render: (cell) => (
        <Text>
          {maskTelephone(cell)}
        </Text>
      )
    },
    {
      title: "Principal",
      dataIndex: "principal",
      width: 80,
      align: "center",
      render: cell => (
        (cell)
          ?
            <Tag color="green">Si</Tag>
          :
            <Tag>No</Tag>
      )
    },
    {
      title: "Estatus",
      dataIndex: "status_d1",
      width: 100,
      align: "center",
      render: cell => ((cell?.nombre === "Activo") ? <Tag color="green">Activo</Tag> : <Tag>{cell?.nombre}</Tag>),
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 120,
      render: (_, row) => (
        <Space size={"small"}>
          <Tooltip title={"Editar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              icon={<EditOutlined />}
              onClick={
                () => {
                  setSelectedRepresentative(row);
                  setRepresentativeModalState({
                    ...representativeModalState,
                    editVisible: true
                  });
                }
              }
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              icon={<DeleteOutlined />}
              onClick={
                () => {
                  setSelectedRepresentative(row);
                  setRepresentativeModalState({
                    ...representativeModalState,
                    deleteVisible: true
                  });
                }
              }
            />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const userColumns = [
    {
      title: "Nombre",
      dataIndex: "usuario",
      ellipsis: { showTitle: false },
      render: (cell) => (
        <Tooltip title={`${cell.first_name} ${cell.last_name}`}>
          <Text>
            {`${cell.first_name} ${cell.last_name}`}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: "usuario",
      render: (cell) => (
        <Paragraph className="my0" copyable>
          {cell.email}
        </Paragraph>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 80,
      render: (_, row) => (
        <Space size={"middle"}>
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              icon={<DeleteOutlined />}
              onClick={
                () => {
                  setSelectedUser(row);
                  setUserModalState({
                    ...userModalState,
                    deleteVisible: true
                  });
                }
              }
            />
          </Tooltip>
        </Space>
      ),
    }
  ];

  const documentProps = {
    onRemove: (file) => {
      const index = selectedFile.indexOf(file);
      const newFileList = selectedFile.slice();
      newFileList.splice(index, 1);
      setSelectedFile(newFileList);
    },
    beforeUpload: (file) => {
      setSelectedFile([file]);
      return false;
    },
    selectedFile,
  };

  const pageHeaderExtras = [
    <Button
      onClick={() => navigate(`/pages/shareholders/${id}/documents/variables`)}
      size="small"
      key={"variableBtn"}
    >
      Datos para documentos
    </Button>,
    <Button
      onClick={() => navigate(`/pages/shareholders/${id}/update`)}
      key={"updateBtn"}
      size="small"
      type="primary"
      ghost
    >
      Editar
    </Button>
  ];

  const documentCardExtra = (
    <Button
      onClick={() => setDocumentModalState({...documentModalState, createVisible: true})}
      type="primary"
      size="small"
      ghost
    >
      Agregar
    </Button>
  );

  const representativeCardExtra = (
    <Button
      onClick={() => setRepresentativeModalState({...representativeModalState, createVisible: true})}
      type="primary"
      size="small"
      ghost
    >
      Agregar
    </Button>
  );

  const userCardExtra = (
    <Button
      onClick={() => setUserModalState({...userModalState, createVisible: true})}
      type="primary"
      size="small"
      ghost
    >
      Agregar
    </Button>
  )

  useEffect(
    () => {
      getShareholder();
    },
    []
  );

  useEffect(
    () => {
      if (selectedRepresentative) {
        representativeForm.setFieldsValue({
          "name": selectedRepresentative?.nombre || "",
          "email": selectedRepresentative?.correo || "",
          "telephone": selectedRepresentative?.telefono || "",
          "isPrimary": selectedRepresentative?.principal
        });
      } else {
        representativeForm.resetFields();
      }
    },
    [selectedRepresentative]
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && shareholder &&
        <>
          <PageHeader
            title={shareholder.nombre_rs}
            onBack={() => navigate("/pages/shareholders/overview")}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderExtras}
          >
            <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3}}>
              <Descriptions.Item label="Correo Electrónico">
                <Paragraph className="my0" copyable>
                  {shareholder?.correo || ""}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="Tipo">
                <Text>
                  {shareholder?.tipo_accionista_d2?.nombre || ""}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Estatus">
                <Tag color={(shareholder?.status_d1?.nombre === "Activo" ? "green" : "default")}>
                  {shareholder?.status_d1?.nombre}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Teléfono">
                <Text>
                  {maskTelephone(shareholder?.telefono || "")}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="CURP">
                <Paragraph className="my0" copyable>
                  {shareholder?.curp || ""}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="Contrato Liquidación">
                <Text>
                  {shareholder?.contrato_liquidacion}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label={(shareholder?.tipo_accionista_d2?.valor === "PF") ? "Fecha Nacimiento" : "Fecha Constitución"}>
                <Text>
                  {formatDate(shareholder?.fecha_nacimiento)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="RFC / RFC Firma">
                <Paragraph className="my0">
                  {shareholder?.rfc} / {shareholder?.rfc_firma}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="Notificaciones">
                <Tag color={(shareholder?.notificaciones_correo) ? "green" : "default"}>
                  Mail
                </Tag>
                <Tag color={(shareholder?.notificaciones_sms) ? "green" : "default"}>
                  SMS
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Nacionalidad">
                <Text>
                  {shareholder?.nacionalidad}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Retención ISR">
                <Text>
                  {maskPercentage(shareholder?.retencion_porcentaje)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="¿Firma Digital?">
                {shareholder?.usa_firma_digital &&
                  <Tag color={"green"}>
                    Si
                  </Tag>
                }
                {!shareholder?.usa_firma_digital &&
                  <Tag>
                    No
                  </Tag>
                }
              </Descriptions.Item>
              <Descriptions.Item label="Notas">
                <Text ellipsis>
                  {shareholder?.notas || ""}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Comisión">
                <Text>
                  {maskPercentage(shareholder?.comision_porcentaje)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="¿Firma mancomunada?">
                {shareholder?.firma_mancomunada &&
                  <Tag color={"green"}>
                    Si
                  </Tag>
                }
                {!shareholder?.firma_mancomunada &&
                  <Tag>
                    No
                  </Tag>
                }
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          {shareholder?.firma_mancomunada &&
            <Collapse className="mt1" bordered={false}>
              <Panel header={<Text strong>Firma mancomunada</Text>}>
                <Descriptions>
                  <Descriptions.Item label="Nombre">{shareholder.firma_mancomunada_nombre}</Descriptions.Item>
                  <Descriptions.Item label="RFC">{shareholder.firma_mancomunada_rfc}</Descriptions.Item>
                  <Descriptions.Item label="Correo Electrónico">{shareholder.firma_mancomunada_correo}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
          }
          <Card
            title="Posición Actual"
            bordered={false}
            className="mt1"
          >
            <Table
              columns={positionColumns}
              dataSource={positions}
              loading={isPositionTableLoading}
              size={"small"}
              rowKey="posicion_id"
            />
          </Card>
          <Card
            title="Documentos"
            bordered={false}
            className="mt1"
            extra={documentCardExtra}
          >
            <Table
              columns={documentColumns}
              dataSource={documents}
              loading={isDocumentTableLoading}
              size={"small"}
              rowKey="id"
            />
          </Card>
          {shareholder.tipo_accionista_d2.valor !== "PF" &&
            <Card
              title="Representantes Legales"
              bordered={false}
              className="mt1"
              extra={representativeCardExtra}
            >
              <Table
                columns={representativesColumns}
                dataSource={representatives}
                loading={isRepresentativeTableLoading}
                size={"small"}
                rowKey="id"
              />
            </Card>
          }
          <Card
            title="Usuarios Autorizados"
            bordered={false}
            className="mt1"
            extra={(users.length > 0) ? null : userCardExtra}
          >
            <Table
              columns={userColumns}
              dataSource={users}
              loading={isUserTableLoading}
              size={"small"}
              rowKey="id"
            />
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getShareholder}
          exitPath={"/pages/dashboard"}
        />
      }
      {userForm &&
        <Modal
          title="Agregar Usuario Autorizado"
          visible={userModalState.createVisible}
          onOk={onUserCreateOkClick}
          confirmLoading={userModalState.createLoading}
          maskClosable={false}
          onCancel={
            () => {
              userForm?.resetFields();
              setUserModalState(USER_DEFAULT_MODAL_STATE);
            }
          }
        >
          <Form
            layout='vertical'
            form={userForm}
          >
            <Form.Item
              label="Usuario"
              name="user"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={
                  (input, option) => (option?.key ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {
                  availableUsers.map((user) => {
                    return (
                      <Option
                        key={`${user.first_name}-${user.last_name}-${user.id}`}
                        value={user.id}
                      >
                        {user.first_name} {user.last_name}
                      </Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      }
      {representativeForm &&
        <Modal
          title="Nuevo Representante Legal"
          visible={representativeModalState.createVisible}
          onOk={onRepresentativeCreateOkClick}
          confirmLoading={representativeModalState.createLoading}
          maskClosable={false}
          onCancel={
            () => {
              representativeForm?.resetFields();
              setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);
            }
          }
        >
          <Form
            layout="vertical"
            form={representativeForm}
          >
            <Form.Item
              label="Nombre"
              name="name"
              rules={COMMON_ALPHA_FORM_RULE}
              hasFeedback
            >
              <Input type={"text"} autoFocus/>
            </Form.Item>
            <Form.Item
              label="Correo Electrónico"
              name="email"
              rules={COMMON_EMAIL_FORM_RULE}
              hasFeedback
            >
              <Input type={"email"}/>
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="telephone"
              rules={COMMON_TELEPHONE_FORM_RULE}
              hasFeedback
            >
              <Input type={"tel"} onChange={onRepresentativeTelephoneChange}/>
            </Form.Item>
            <Form.Item
              name="isPrimary"
              valuePropName="checked"
              hasFeedback
            >
              <Checkbox disabled={!canRepresentativeBePrimary}>
                Principal
              </Checkbox>
            </Form.Item>
          </Form>
        </Modal>
      }
      {representativeForm && shareholder &&
        <Modal
          title="Editar Representante Legal"
          visible={representativeModalState.editVisible}
          onOk={onRepresentativeEditOkClick}
          confirmLoading={representativeModalState.editLoading}
          maskClosable={false}
          onCancel={
            () => {
              representativeForm.resetFields();
              setSelectedRepresentative(undefined);
              setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);
            }
          }
        >
          <Form layout="vertical" form={representativeForm}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={COMMON_ALPHA_FORM_RULE}
              hasFeedback
            >
              <Input type={"text"} autoFocus/>
            </Form.Item>
            <Form.Item
              label="Correo Electrónico"
              name="email"
              rules={COMMON_EMAIL_FORM_RULE}
              hasFeedback
            >
              <Input type={"email"}/>
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="telephone"
              rules={COMMON_TELEPHONE_FORM_RULE}
              hasFeedback
            >
              <Input type={"tel"} onChange={onRepresentativeTelephoneChange}/>
            </Form.Item>
            <Form.Item
              name="isPrimary"
              valuePropName="checked"
              hasFeedback
            >
              <Checkbox disabled={!canRepresentativeBePrimary}>
                Principal
              </Checkbox>
            </Form.Item>
          </Form>
        </Modal>
      }
      {shareholder &&
        <Modal
          title="Eliminar Representante Legal"
          visible={representativeModalState.deleteVisible}
          onOk={onRepresentativeDeleteOkClick}
          confirmLoading={representativeModalState.deleteLoading}
          maskClosable={false}
          onCancel={
            () => {
              setRepresentativeModalState(REPRESENTATIVE_DEFAULT_MODAL_STATE);
              setSelectedRepresentative(undefined);
            }
          }
        >
          <Text>
            ¿Estas seguro(a) de remover a {selectedRepresentative?.nombre}?
          </Text>
        </Modal>
      }
      {shareholder &&
        <Modal
          title="Eliminar Usuario Autorizado"
          visible={userModalState.deleteVisible}
          onOk={onUserDeleteOkClick}
          confirmLoading={userModalState.deleteLoading}
          maskClosable={false}
          onCancel={
            () => {
              setUserModalState(USER_DEFAULT_MODAL_STATE);
              setSelectedUser(undefined);
            }
          }
        >
          <Text>
            ¿Estas seguro(a) de remover a {selectedUser?.usuario?.first_name} {selectedUser?.usuario?.last_name}?
          </Text>
        </Modal>
      }
      {documentForm &&
        <Modal
          title="Subir Documento"
          visible={documentModalState.createVisible}
          onOk={onDocumentCreateOkClick}
          confirmLoading={documentModalState.createLoading}
          maskClosable={false}
          onCancel={
            () => {
              documentForm.resetFields();
              setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);
            }
          }
        >
          <Form layout="vertical" form={documentForm}>
            <Form.Item
              label="Etiqueta"
              name="name"
              initialValue={""}
            >
              <Input type={"text"} />
            </Form.Item>
            <Form.Item
              label="Archivo"
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={COMMON_REQUIRED_FORM_RULE}
            >
              <Upload
                {...documentProps}
                maxCount={1}
                accept={COMMON_ACCEPT_FILE_FORMATS}
              >
                <Button className="w100" icon={<UploadOutlined />}>
                  Seleccionar
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      }
      {selectedDocument &&
        <Modal
          title="Eliminar Documento"
          visible={documentModalState.deleteVisible}
          onOk={onDeleteDocumentClick}
          confirmLoading={documentModalState.deleteLoading}
          maskClosable={false}
          onCancel={
            () => {
              setDocumentModalState(DOCUMENT_DEFAULT_MODAL_STATE);
              setSelectedDocument(undefined);
            }
          }
        >
          <Text>
            ¿Estas seguro(a) de remover el documento "{selectedDocument?.nombre_documento}"?
          </Text>
        </Modal>
      }
    </>
  )
}

export default ShareholderDetails;