import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  InputNumber,
  DatePicker,
  Space,
} from 'antd';
import { getMoment, getUniqueKey } from "../../../shared/utils";
import useCompanyForm from "../../../hooks/useCompanyForm";
import { maskBigNumber } from "../../../shared/utils";
import {
  COMMON_REQUIRED_FORM_RULE,
  INPUT_NUMBER_FORMAT,
  DATE_FORMAT
} from "../../../constants";

const CompanyGeneralForm = ({ company, setStep, onSave, onFinish, from, draft, draftAssigned, setDraftAssigned }) => {
  const { values, setValues } = useCompanyForm();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const onSubmitClick = async () => {
    try {
      await form.validateFields();

      const formValues = form.getFieldsValue();
      const newValues = {
        ...values,
        empresa: {
          razon_social: formValues.businessName,
          nombre_corto: formValues.shortName,
          notas: formValues.notes,
          status_d1: "A",
        },
        valoracion_x_accion: {
          fecha: formValues.date,
          precio: formValues.price
        }
      };

      setValues(newValues);

      if (!company) setStep(1);
      if (company) onFinish(newValues);
    } catch (error) {
    }
  };

  const onSaveClick = async () => {
    try {
      const formValues = form.getFieldsValue();
      const newValues = {
        ...values,
        loading: false,
        empresa: {
          razon_social: formValues?.businessName,
          nombre_corto: formValues?.shortName,
          notas: formValues?.notes,
          status_d1: "A",
        },
        valoracion_x_accion: {
          fecha: formValues?.date,
          precio: formValues?.price
        }
      };

      setValues(newValues);
      setSaving(true);

      await onSave(newValues);

      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  useEffect(
    () => {
      if (company) {
        if (!values?.empresa?.razon_social) {
            form.setFieldsValue({
              businessName: company?.razon_social,
              shortName: company?.nombre_corto,
              price: company?.valoracion_empresa?.precio,
              date: getMoment(company?.valoracion_empresa?.fecha),
              notes: company?.notas,
            });
        }
      } else {
        form.setFieldsValue({
          businessName: values?.empresa?.razon_social,
          shortName: values?.empresa?.nombre_corto,
          price: values?.valoracion_x_accion?.precio,
          date: getMoment(values?.valoracion_x_accion?.fecha),
          notes: values?.empresa?.notas,
        });
      }
    },
    [values]
  );

  useEffect(
    () => {
      if (draft && !draftAssigned) {
        setValues({...draft, loading: false });
        setDraftAssigned(true)
      }
    },
    []
  )

  return (
    <>
      <Form layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Razón Social"
              name="businessName"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <Input
                type={"text"}
                maxLength={150}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Nombre Corto" name="shortName" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
              <Input type={"text"} maxLength={30} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Precio MID"
              name="price"
              rules={COMMON_REQUIRED_FORM_RULE}
              hasFeedback
            >
              <InputNumber
                className={"w100"}
                formatter={value => maskBigNumber(value)}
                parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                min={0}
                controls={false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Fecha de valoración" name="date" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
              <DatePicker
                format={DATE_FORMAT}
                className="w100"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24}>
            <Form.Item label="Notas" name="notes" hasFeedback>
              <Input.TextArea maxLength={250} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={6} offset={18} className="textEnd">
            <Space>
              {from === 'create' &&
                <Button
                  type='primary'
                  ghost
                  key={getUniqueKey()}
                  size="small"
                  loading={saving}
                  onClick={() => onSaveClick()}
                >
                  <span>Guardar</span>
                </Button>
              }
              <Button
                type='primary'
                ghost
                key={getUniqueKey()}
                size="small"
                loading={values.loading}
                onClick={() => onSubmitClick()}
              >
                {from === 'create' && <span>Siguiente</span>}
                {from === 'update' && <span>Guardar y Salir</span>}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default CompanyGeneralForm;