import axios from "axios";
import { BASE_API_URL, STORAGE_TOKEN_NAME } from "../constants";

const http = async ({ endpoint, method = 'GET', data = null, needAuthorization = true, headers = null }) => {

  try {

    const url = `${BASE_API_URL}/${endpoint}`;
    const token = sessionStorage.getItem(STORAGE_TOKEN_NAME);
    let config = {
      headers: headers ? headers : { "Content-Type": "application/json" }
    };

    if (needAuthorization) config.headers["Authorization"] = `Bearer ${token}`;

    let response = null;

    switch(method) {
      case 'GET':
        response = await axios.get(url, config);
        break;
      case 'POST':
        response = await axios.post(url, data, config);
      break;
      case 'PUT':
        response = await axios.put(url, data, config);
      break;
      case 'PATCH':
        response = await axios.patch(url, data, config);
      break;
      case 'DELETE':
        response = await axios.delete(url, config);
      break;
      default:
        break;
    }

    return Promise.resolve(response.data);

  } catch (error) {

    let message = "Parece que algo salió mal, intenta más tarde.";

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.length > 0 &&
      error.response.data.status !== 400
    ) {
      message = error.response.data.errors[0]?.detail
    }

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.length > 0 &&
      error.response.data.status === 400
    ) {
      message = `${error.response.data.errors[0]?.field}: ${error.response.data.errors[0]?.detail}`
    }

    return Promise.reject({
      status: error?.response?.status || 500,
      message
    });

  }

};

export default http;
