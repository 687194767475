import {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import http from '../../../services/http.service';
import Form from "../form/ShareholderForm";
import PageLoader from '../../../components/page-loader/PageLoader';
import ErrorResult from '../../../components/error-result/ErrorResult';
import { itemRender } from '../../../shared/helpers/breadcrumb';
import {
  PageHeader,
  Button
} from 'antd';

const ShareholderUpdate = () => {

  const [isSubmitted, setSubmitted] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(undefined);
  const [shareholder, setShareholder] = useState(undefined);

  const navigate = useNavigate();
  const { id } = useParams();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/shareholders/overview',
      breadcrumbName: 'Participantes',
    },
    {
      path: `/pages/shareholders/${id}/details`,
      breadcrumbName: shareholder?.nombre_rs,
    },
    {
      path: `/pages/shareholders/${id}/update`,
      breadcrumbName: 'Editar',
    }
  ];

  const getShareholder = async () => {

    try {

      setPageLoading(true);
      setPageError(undefined);

      const response = await http({ endpoint: `accionistas/${id}/` });

      setShareholder(response);
      setPageLoading(false);

    } catch(error) {

      setPageError(error);
      setPageLoading(false);

    }

  };

  const pageHeaderExtra = (
    <Button
      type='primary'
      ghost
      size="small"
      loading={isSubmitted}
      onClick={() => setSubmitted(true)}
    >
      {!isSubmitted && <span>Guardar</span>}
      {isSubmitted && <span>Guardando</span>}
    </Button>
  )

  useEffect(
    () => {
      getShareholder();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && shareholder &&
        <>
          <PageHeader
            title={"Editar expediente"}
            onBack={() => navigate(`/pages/shareholders/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderExtra}
          />
          <Form
            shareholder={shareholder}
            isSubmitted={isSubmitted}
            setSubmitted={setSubmitted}
          />
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getShareholder}
          exitPath={"/pages/shareholders/overview"}
        />
      }
    </>
  )
}

export default ShareholderUpdate;