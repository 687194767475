import {
  useState,
  useEffect
} from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/http.service";
import CustomTable from "../../../components/custom-table/CustomTable";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { RightOutlined } from "@ant-design/icons";
import { DEFAULT_CUSTOM_TABLE_CONFIG } from "../../../constants";
import {
  PageHeader,
  Button,
  Tag,
  Tooltip,
  Card,
  Typography
} from "antd";

const ShareholderOverview = () => {

  const [customTableConfig, setCustomTableConfig] = useState(DEFAULT_CUSTOM_TABLE_CONFIG);
  const [pageError, setPageError] = useState(undefined);

  const navigate = useNavigate();

  const { Text, Paragraph } = Typography;

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/shareholders/overview",
      breadcrumbName: "Participantes",
    }
  ];

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre_rs",
      ellipsis: { showTitle: false },
      filterName: "nombre_rs",
      search: true,
      render: cell => (
        <Tooltip title={cell}>
          <Text>
            {cell}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: "correo",
      filterName: "correo",
      search: true,
      ellipsis: { showTitle: false },
      render: cell =>
        (cell)
          ?
            <Paragraph className="my0" copyable>
              {cell}
            </Paragraph>
          :
            ""
    },
    {
      title: "Tipo",
      dataIndex: "tipo_accionista_d2",
      filterName: "tipo_accionista_d2",
      align: "center",
      width: 120,
      render: cell => (<span>{cell?.nombre}</span>),
    },
    {
      title: "Notificaciones",
      width: 130,
      align: "center",
      render: (_, row) => (
        <>
          <Tag color={(row.notificaciones_correo) ? "green" : "default"}>Correo</Tag>
          <Tag color={(row.notificaciones_sms) ? "green" : "default"}>SMS</Tag>
        </>
      )
    },
    {
      title: "Estatus",
      dataIndex: "status_d1",
      filterName: "status_d1",
      align: "center",
      width: 100,
      render: cell => (
        (cell?.nombre === "Activo")
          ?
            <Tag color="green">{cell?.nombre}</Tag>
          :
            <Tag>{cell?.nombre}</Tag>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 80,
      align: "center",
      render: (cell) => (
        <Tooltip title={"Detalle"}>
          <Button
            size={"small"}
            className="noBorderColor bgTransparent"
            onClick={() => navigate(`/pages/shareholders/${cell}/details`)}
            shape="circle"
            icon={<RightOutlined />}
          />
        </Tooltip>
      ),
    },
  ];

  const getData = async (props = null) => {

    try {

      setPageError(undefined);

      let endpoint = `accionistas/?ordering=nombre_rs&offset=0&limit=10`;
      let queryParams = [
        "ordering=nombre_rs",
        "offset=0",
        "limit=10"
      ];

      if (props) {
        endpoint = props.url;
        queryParams = props.queryParams;
      }

      const response = await http({ endpoint });

      setCustomTableConfig({
        data: response.results,
        columns: columns,
        queryParams,
        loading: false,
        updateFromTable: false,
        endpoint: "accionistas/",
        url: endpoint,
        rowKey: "id",
        onRefresh: getData,
        paginator: {
          ...DEFAULT_CUSTOM_TABLE_CONFIG.paginator,
          total: response?.count || 0,
          next: response?.next || "",
          prev: response?.previous || ""
        }
      });

    } catch (error) {

      setPageError(error);

    }

  };

  const pageHeaderExtras = (
    <Button
      onClick={() => navigate("/pages/shareholders/create")}
      size={"small"}
      type="primary"
      ghost
    >
      Nuevo
    </Button>
  );

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      <PageHeader
        title="Participantes"
        breadcrumb={{ routes, itemRender }}
        onBack={() => navigate("/pages/dashboard")}
        extra={pageHeaderExtras}
      />
      {!pageError &&
        <Card className="mt1">
          <div className="tableContainer">
            <CustomTable config={customTableConfig}/>
          </div>
        </Card>
      }
      {pageError && (
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      )}
    </>
  )
}

export default ShareholderOverview;