import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "../styles/ForgotPassword.module.css";
import http from "../../../services/http.service";
import { COMMON_PASSWORD_RULE } from "../../../constants";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  notification,
  Card,
  Typography
} from "antd";


const ResetPassword = () => {

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { Title, Text } = Typography;

  const onFinish = async (values) => {

    try {

      setLoading(true);

      const token = searchParams.get("token");
      const uid = searchParams.get("uidb64");

      const request = {
        endpoint: `usuarios/reset-password/${uid}/${token}/`,
        method: "PUT",
        needAuthorization: false,
        data: {
          new_password: values.password,
          new_password2: values.password
        }
      };

      await http(request);

      setLoading(false);

      notification.success({
        message: "¡Correcto!",
        description: "Contraseña actualizada correctamente.",
      });

      navigate("/auth/login");

    } catch (error) {

      setLoading(false);

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }

  };

  return (
    <div className={styles.container}>
      <Card className={styles.formCard}>
        <Title level={3}>
          Restablecer Contraseña
        </Title>
        <Text type="secondary">
          Favor ingresa tu nueva contraseña.
        </Text>
        <Form
          layout='vertical'
          className="mt2"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='Nueva Contraseña'
            name='password'
            rules={COMMON_PASSWORD_RULE}
            hasFeedback
          >
            <Input.Password
              minLength={8}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <div className={styles.actionContainer}>
            <Button
              type='default'
              htmlType='button'
              className={styles.actionBtn}
              onClick={() => navigate("/auth/login")}
            >
              Salir
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              className={styles.actionBtn}
            >
              {!loading && <span>Actualizar</span>}
              {loading && <span>Actualizando</span>}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;
