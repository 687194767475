import { useState } from 'react';
import { Tooltip, Button, notification } from 'antd';
import http from "../../services/http.service";
import { getUniqueKey } from '../../shared/utils';

/**
 * Action Button
 * Component that make an action
 * @param { tooltip } props: tooltip text
 * @param { request } props: HTTP Request
 * @param { icon } props: Ant Design Icon <FilePdfOutlined />
 * @param { confirmTitle, confirmContent, confirmText, confirmCancelText } props: modal
 * @returns {Component}
 */
const ActionButton = (props) => {
  const [loading, setLoading] = useState(false);

  const onActionClick = async () => {
    try {
      if (!props.request) return;

      setLoading(true);

      await http(props.request);

      setLoading(false);
      notification.success({ message: '¡Correcto!', description: props.successMessage || "Acción realizada correctamente." });

      if (props?.onFinish) props.onFinish()
    } catch (error) {
      setLoading(false);
      notification.error({ message: '¡Ups!', description: error.message });
    }
  }

  return (
    <Tooltip
      placement="top"
      title={props.tooltip || ''}
      key={getUniqueKey()}>
        <Button
          key={getUniqueKey()}
          size={props.size || "small"}
          shape={props.shape || "circle"}
          icon={props.icon}
          loading={loading}
          className={props.className || "noBorderColor bgTransparent"}
          onClick={() => onActionClick()}
        >
          {props.value || ''}
        </Button>
    </Tooltip>
  );
}

export default ActionButton;