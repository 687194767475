import { useState } from "react";
import { PageHeader, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import Form from "../form/ShareholderForm";

const ShareholderCreate = () => {

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: '/pages/shareholders/overview',
      breadcrumbName: 'Participantes',
    },
    {
      path: '/pages/shareholders/create',
      breadcrumbName: 'Nuevo',
    }
  ];

  return (
    <>
      <PageHeader
        title="Nuevo Participante"
        onBack={() => navigate("/pages/shareholders/overview")}
        breadcrumb={{ routes, itemRender }}
        extra={
          <Button
            type='primary'
            ghost
            size="small"
            loading={isSubmitted}
            onClick={() => setSubmitted(true)}>
            {!isSubmitted && <span>Guardar</span>}
            {isSubmitted && <span>Guardando</span>}
          </Button>
        }
      />
      <Form
        isSubmitted={isSubmitted}
        setSubmitted={setSubmitted}
      />
    </>
  )
}

export default ShareholderCreate;