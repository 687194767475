import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../styles/PurchaseOffer.module.css";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import http from "../../../services/http.service";
import useAuth from "../../../hooks/useAuth";
import PageLoader from "../../../components/page-loader/PageLoader";
import ErrorResult from "../../../components/error-result/ErrorResult";
import {
  INPUT_NUMBER_FORMAT
} from "../../../constants";
import {
  formatDateTime,
  maskBigNumber
} from '../../../shared/utils';
import {
  PageHeader,
  Descriptions,
  Button,
  InputNumber,
  Card,
  Form,
  Checkbox,
  Space,
  Modal,
  Typography,
  Tooltip,
  notification
} from 'antd';

const PurchaseOfferInvite = () => {

  const [transaction, setTransaction] = useState(undefined);
  const [pageError, setPageError] = useState(undefined);
  const [form] = Form.useForm();
  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitted, setSubmitted] = useState(false);
  const [checkboxGroup, setCheckboxGroup] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [modalConfig, setModalConfig] = useState({ isVisible: false, isLoading: false });

  const navigate = useNavigate();
  const { user } = useAuth();
  let { transactionId } = useParams();

  const { Text, Paragraph } = Typography;

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: `/pages/shareholders/transaction/${transactionId}/invite`,
      breadcrumbName: 'Oferta Compra',
    },
  ];

  const onCheckboxChange = (option, e) => {
    
    const isChecked = e.target.checked;

    setSelectedOption(option);

    if(option === "RENUNCIA" && isChecked) {
      setCheckboxGroup({
        firstOption: true,
        secondOption: false,
        thirdOption: false
      });
    }
    if(option === "COMPRA" && isChecked) {
      setCheckboxGroup({
        firstOption: false,
        secondOption: true,
        thirdOption: false
      });
    }
 
  };

  const onModalCancelClick = () => setModalConfig({isVisible: false, isLoading: false});

  const onModalOkClick = async () => {

    try {

      setModalConfig({ ...modalConfig, isLoading: true });
      setSubmitted(true);

      let purchaseQuantity = 0;
      let purchaseAmount = 0;

      if (selectedOption === "COMPRA") {
        purchaseQuantity = form.getFieldValue("stockQuantity");
        purchaseAmount = transaction.total_compra;
      }

      const data = {
        accionista_id: user.shareholder.id,
        transaccion_id: transactionId,
        nuevo_socio_resultado: selectedOption,
        derecho_compra: purchaseQuantity,
        derecho_total: purchaseAmount,
        derecho_solicita_extra: 0,
        firma: "secret"
      };

      const request = {
        endpoint: `accionistas/${user.shareholder.id}/invitaciones/${transactionId}/`,
        method: "PATCH",
        data
      };

      await http(request);

      setModalConfig({ isVisible: false, isLoading: false });

      notification.success({
        message: "¡Correcto!",
        description: "Respuesta registrada correctamente.",
      });

      navigate(`/pages/dashboard`);

    } catch (error) {

      setModalConfig({ isVisible: false, isLoading: false });
      setSubmitted(false);

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }
  };

  const getData = async () => {

    try {
      
      setPageLoading(true);
      setPageError(undefined);

      const request = {
        endpoint: `accionistas/${user.shareholder.id}/derechos-oferta/${transactionId}/`
      };

      const response = await http(request);

      const total_compra = (parseFloat(response.nuevo_socio_oferta) * parseFloat(response.transaccion.precio));

      setTransaction({...response, total_compra, total_compra_3: total_compra  });
      setPageLoading(false);

    } catch (error) {
      
      setPageLoading(false);
      setPageError(error);

    }
  };

  const onFormChange = (field) => {

    if (field.stockQuantity) {
      const total_compra = (parseFloat(transaction.transaccion.precio) * field.stockQuantity);
      setTransaction({...transaction, total_compra});
      form.setFieldsValue({ stockAmount: total_compra });
    }

    if (field.extraQuantity) {
      const total_extra = (parseFloat(transaction.transaccion.precio) * field.extraQuantity);
      setTransaction({...transaction, total_extra});
      form.setFieldsValue({ extraAmount: total_extra });
    }

  };

  const pageHeaderExtra = (
    <Button
      onClick={() => setModalConfig({isLoading: false, isVisible: true})}
      type="primary"
      size="small"
      ghost
      disabled={!checkboxGroup.firstOption && !checkboxGroup.secondOption && !checkboxGroup.thirdOption}
    >
      {!isSubmitted && <span>Aceptar</span>}
      {isSubmitted && <span>Aceptando</span>}
    </Button>
  )

  useEffect(
    () => {
      getData();
    },
    []
  );
  
  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && transaction &&
        <>
          <PageHeader
            title={"Usted tiene una oferta de compra"}
            onBack={() => navigate("/pages/dashboard")}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderExtra}
          >
            <Descriptions size="small" column={{ xs: 1, sm: 2, md: 2, lg: 3}}>
              <Descriptions.Item label="Acciones en venta">
                {maskBigNumber(transaction?.transaccion?.derecho_acciones_en_venta)}
              </Descriptions.Item>
              <Descriptions.Item label="Oferta recibida">
                {formatDateTime(transaction?.nuevo_socio_oferta_fh)}
              </Descriptions.Item>
              <Descriptions.Item label="Empresa">
                {transaction?.transaccion?.empresa?.razon_social}
              </Descriptions.Item>
              <Descriptions.Item label="Acciones">
                {maskBigNumber(transaction?.nuevo_socio_oferta)}
              </Descriptions.Item>
              <Descriptions.Item label="Vencimiento">
                {formatDateTime(transaction?.derecho_plazo_limite_fh)}
              </Descriptions.Item>
              <Descriptions.Item label="Condiciones">
                <Tooltip title={transaction?.transaccion?.condiciones}>
                  <Paragraph className="my0" ellipsis style={{maxWidth: '210px'}}>
                    {transaction?.transaccion?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label="Precio">
                {maskBigNumber(transaction?.transaccion?.precio, "currency")}
              </Descriptions.Item>
              <Descriptions.Item label="">
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card
            title="Usted tiene las siguientes opciones:"
            bordered={false}
            className="mt1"
          >
            <Form
              form={form}
              onValuesChange={onFormChange}
            >
              <div className={styles.optionContainer}>
                <div className={styles.optionRow}>
                  <div>
                    <Checkbox
                      checked={checkboxGroup?.firstOption}
                      onChange={(value) => onCheckboxChange("RENUNCIA", value)}
                    >
                      <strong>
                        - Opción 1:
                      </strong>
                    </Checkbox>
                  </div>
                  <div>
                    <span>
                      Rechazar la oferta de compra de <strong>{maskBigNumber(transaction.nuevo_socio_oferta)}</strong> acciones.
                    </span> 
                  </div>
                </div>
                <div className={styles.optionRow}>
                  <div>
                    <Checkbox
                      checked={checkboxGroup?.secondOption}
                      onChange={(value) => onCheckboxChange("COMPRA", value)}
                    >
                      <strong>
                        - Opción 2:
                      </strong>
                    </Checkbox>
                  </div>
                  <div>
                    <span>
                      Aceptar la oferta de compra de
                    </span> 
                  </div>
                  <div className="ml1">
                    <Form.Item
                      name="stockQuantity"
                      initialValue={transaction.nuevo_socio_oferta}
                      style={{marginBottom: 0}}
                    >
                      <InputNumber
                        formatter={value => maskBigNumber(value)}
                        parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                        min={1}
                        max={transaction.nuevo_socio_oferta}
                        controls={false}
                      />
                    </Form.Item>
                  </div>
                  <div className="ml1">
                    <span>
                      acciones, por un total de
                    </span>
                  </div>
                  <div className="ml1">
                    <strong>{maskBigNumber(transaction.total_compra, "currency", 2)}</strong>
                  </div>
                </div>
              </div>
            </Form>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/transactions/${}"}
        />
      }
      {modalConfig && modalConfig.isVisible &&
        <Modal
          title="Confirmación"
          visible={modalConfig.isVisible}
          maskClosable={false}
          onOk={onModalOkClick}
          okText={"Aceptar"}
          confirmLoading={modalConfig.isLoading}
          onCancel={onModalCancelClick}
        >
          <Space direction="vertical">
            <Text>
              A continuación se dará respuesta a la oferta de compra.
            </Text>
          </Space>
        </Modal>
      }
    </>
  )
}

export default PurchaseOfferInvite