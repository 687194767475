import { Badge } from "antd";

const SignDocumentStatusBadge = (props) => {

  const getBadge = (status) => {
    switch(status) {
      case "PENDIENTE":
        return <Badge status="processing" text={"Pendiente"} />
      case "FIRMADO":
        return <Badge status="success" text={"Firmado"} />
      case "3 FIRMAS":
        return <Badge status="success" text={"3 Firmas"} />
      case "2 FIRMAS":
        return <Badge status="success" text={"2 Firmas"} />
      case "1 FIRMA":
        return <Badge status="warning" text={"1 Firma"} />
      case "N/D":
        return null;
      default:
        return null
    }
  };

  return getBadge(props.status)
}

export default SignDocumentStatusBadge;