import { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { maskBigNumber } from "../../../shared/utils";
import { COMMON_REQUIRED_FORM_RULE,INPUT_NUMBER_FORMAT } from "../../../constants";
import { getUniqueKey } from "../../../shared/utils";
import http from "../../../services/http.service";
import useCompanyForm from "../../../hooks/useCompanyForm";
import {
  Col,
  Row,
  Form,
  Button,
  InputNumber,
  Divider,
  Table,
  Tooltip,
  Space,
  Typography,
  Select,
  notification,
} from 'antd';

const CompanyShareholdersForm = ({ setStep, onFinish, onSave }) => {
  const { values, setValues } = useCompanyForm();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [shareholders, setShareholders] = useState([]);
  const [series, setSeries] = useState([]);
  const [selectedShareholders, setSelectedShareholders] = useState([]);
  const [selectedShareholder, setSelectedShareholder] = useState(null);
  const [totalAssignedStocks, setTotalAssignedStocks] = useState([]);

  const { Text } = Typography;

  const onAddClick = async () => {
    try {
      await form.validateFields();

      const copyShareholders = [...selectedShareholders];
      const formValues = form.getFieldsValue();
      const { label } = shareholders.find((shareholder) => shareholder.value === formValues.shareholderId);

      updateStocks(formValues.classOrSerie, formValues.quantity, 'sum');

      const shareholder = {
        id: getUniqueKey(),
        nombre: label,
        accionista: formValues.shareholderId,
        serie_clase: formValues.classOrSerie,
        cantidad_inicial: formValues.quantity,
      };

      copyShareholders.push(shareholder);
      setSelectedShareholders(copyShareholders);
      form.resetFields();
    } catch (error) {
      notification.error({ message: '¡Ups!', description: error.message });
    }
  };

  const onEditClick = async () => {
    try {
      await form.validateFields();

      const formValues = form.getFieldsValue();
      const copyShareholders = [...selectedShareholders];
      const shareholderIndex = copyShareholders.findIndex((current) => current.id === selectedShareholder.id);
      const copyShareholder = copyShareholders[shareholderIndex];
      const { label } = shareholders.find((shareholder) => shareholder.value === formValues.shareholderId);

      if (shareholderIndex >= 0) {
        if (formValues.quantity > copyShareholder.cantidad_inicial) {
          const newTotal = formValues.quantity - copyShareholder.cantidad_inicial;
          updateStocks(formValues.classOrSerie, newTotal, 'sum');
        } else {
          const newTotal = copyShareholder.cantidad_inicial - formValues.quantity;
          updateStocks(formValues.classOrSerie, newTotal, 'subtract');
        }

        const shareholder = {
          id: selectedShareholder.id,
          nombre: label,
          accionista: formValues.shareholderId,
          serie_clase: formValues.classOrSerie,
          cantidad_inicial: formValues.quantity,
        };
  
        copyShareholders[shareholderIndex] = shareholder;
        setSelectedShareholders(copyShareholders);
      }

      form.resetFields();
      setSelectedShareholder(null);
    } catch (error) {
    }
  };

  const onDeleteClick = async (id) => {
    const copyShareholders = [...selectedShareholders];
    const shareholderIndex = copyShareholders.findIndex((current) => current.id === id);
    const { serie_clase, cantidad_inicial } = copyShareholders[shareholderIndex];
    updateStocks(serie_clase, cantidad_inicial, 'subtract');
    copyShareholders.splice(shareholderIndex, 1);
    setSelectedShareholders(copyShareholders);
  };

  const setShareholder = (row) => {
    setSelectedShareholder(row);
    form.setFieldsValue({
      classOrSerie: row.serie_clase,
      shareholderId: row.accionista,
      quantity: row.cantidad_inicial
    });
  }

  const updateStocks = (serie, quantity, action) => {
    const copyAssignedStocks = [...totalAssignedStocks];
    const selectedStock = copyAssignedStocks.find((stock) => stock.serie === serie);
    const selectedStockIndex = copyAssignedStocks.findIndex((stock) => stock.serie === serie);
    const newTotal = (action === 'sum') ? selectedStock.assigned + quantity : selectedStock.assigned - quantity;

    if (newTotal > selectedStock.total) {
      const diff = selectedStock.total - selectedStock.assigned;
      throw { message: `En la serie ó clase "${serie}" hay ${maskBigNumber(diff)} acciones pendientes por asignar.`};
    }

    copyAssignedStocks[selectedStockIndex] = { ...selectedStock, assigned: newTotal };
    setTotalAssignedStocks(copyAssignedStocks);
  }

  const onSaveClick = async () => {
    try {
      const copyShareholders = [...selectedShareholders];
      const newValues = { ...values, posicion_actual: copyShareholders };

      setValues(newValues);
      setSaving(true);

      await onSave(newValues);

      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  const onSubmitClick = async () => {
    try {
      const copyShareholders = [...selectedShareholders];
      const copyStocks = [...totalAssignedStocks];

      if (copyShareholders.length === 0) throw { message: 'No hay participantes agregados.'};

      const allStocksAssigned = copyStocks.every((stock) => stock.assigned === stock.total);

      if (!allStocksAssigned) throw { message: 'Aún hay acciones pendientes por asignar.'};

      const newValues = { ...values, posicion_actual: copyShareholders, loading: true };
      setValues(newValues);

      await onFinish(newValues);
    } catch (error) {
      notification.error({ message: '¡Ups!', description: error.message });
      const newValues = { ...values, loading: false };
      setValues(newValues);
    }
  };

  const columns = [
    {
      title: 'Expediente',
      dataIndex: 'nombre',
    },
    {
      title: 'Serie / Clase',
      dataIndex: 'serie_clase'
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad_inicial',
      render: (cell) => (<Text>{maskBigNumber(cell)}</Text>)
    },
    {
      title: "",
      dataIndex: "id",
      width: 120,
      align: "center",
      render: (cell, row) => (
        <Space size="small">
          <Tooltip title={"Editar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              onClick={() => setShareholder(row)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              size={"small"}
              shape="circle"
              className="noBorderColor bgTransparent"
              onClick={() => onDeleteClick(cell)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getData = async () => {
    try {
      const response = await http({ endpoint: `accionistas/?ordering=nombre_rs&status_d1=A&no_page=true` });
      const mapResponse = response.map(({ id, nombre_rs }) => ({ label: nombre_rs, value: id }));
      setShareholders(mapResponse);
    } catch (error) {
    }
  };

  useEffect(
    () => {
      getData();
    },
    []
  );

  useState(
    () => {
      const mapSeriesOptions = values.acciones_empresa.map((serie) => ({ label: serie.serie_clase, value: serie.serie_clase  }));

      if (values && values.posicion_actual.length === 0) {
        const mapSeriesStocks = values.acciones_empresa.map((serie) => ({ serie: serie.serie_clase, assigned: 0, total: serie.cantidad  }));
        setTotalAssignedStocks(mapSeriesStocks);
      } else {
        const mapSeriesStocks = values.acciones_empresa.map((serie) => {
          let serieStock = { serie: serie.serie_clase, assigned: 0, total: serie.cantidad };
          const items = values.posicion_actual.filter(current => current.serie_clase === serieStock.serie);
          items.forEach((item) => serieStock.assigned += item.cantidad_inicial);
          return serieStock;
        });
        setTotalAssignedStocks(mapSeriesStocks);
      }
     
      setSeries(mapSeriesOptions);
      setShareholders(values.posicion_actual);
      setSelectedShareholders(values.posicion_actual);
    },
    []
  );

  return (
    <>
      <Form form={form} layout={"vertical"}>
        <Row gutter={24}>
            <Col xs={24} sm={8}>
              <Form.Item label="Expediente" name="shareholderId" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
                <Select
                  loading={shareholders.length === 0}
                  showSearch
                  options={shareholders}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item label="Serie / Clase" name="classOrSerie" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
                <Select options={series} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="Cantidad" name="quantity" rules={COMMON_REQUIRED_FORM_RULE} hasFeedback>
                <InputNumber
                  className={"w100"}
                  formatter={value => maskBigNumber(value)}
                  parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                  min={0}
                  max={2147483647}
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4} className="dFlex justifyCenter alignItemsCenter">
              <Button onClick={() => selectedShareholder ? onEditClick() : onAddClick()} className="w100" style={{marginTop: '6px'}}>
                {!selectedShareholder && <span>Agregar</span>}
                {selectedShareholder && <span>Editar</span>}
              </Button>
            </Col>
          </Row>
          <Table columns={columns} dataSource={selectedShareholders} size="small" rowKey={'id'} />
          <Divider />
          <Row gutter={24}>
            <Col span={6}>
              <Button
                key={getUniqueKey()}
                size="small"
                onClick={() => setStep(1)}
              >
                Regresar
              </Button>
            </Col>
            <Col span={6} offset={12} className="textEnd">
              <Space>
                <Button
                  type='primary'
                  ghost
                  key={getUniqueKey()}
                  size="small"
                  disabled={selectedShareholders.length === 0}
                  loading={saving}
                  onClick={() => onSaveClick()}
                >
                  Guardar
                </Button>
                <Button
                  type='primary'
                  ghost
                  key={getUniqueKey()}
                  size="small"
                  disabled={selectedShareholders.length === 0}
                  loading={values.loading}
                  onClick={() => onSubmitClick()}
                >
                  Finalizar
                </Button>
              </Space>
            </Col>
          </Row>
      </Form>
    </>
  )
}

export default CompanyShareholdersForm;