import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import { PageHeader, Button } from "antd";
import Form from "../form/UserForm";

const UserCreate = () => {

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: "/pages/users/overview",
      breadcrumbName: "Usuarios",
    },
    {
      path: "/pages/users/create",
      breadcrumbName: "Nuevo",
    },
  ];

  const pageHeaderExtras = (
    <Button
      type='primary'
      ghost
      size="small"
      loading={isSubmitted}
      onClick={() => setSubmitted(true)}
    >
      {!isSubmitted && <span>Crear</span>}
      {isSubmitted && <span>Creando</span>}
    </Button>
  )

  return (
    <>
      <PageHeader
        title='Nuevo Usuario'
        onBack={() => navigate("/pages/users/overview")}
        breadcrumb={{ routes, itemRender }}
        extra={pageHeaderExtras}
      />
      <Form
        isSubmitted={isSubmitted}
        setSubmitted={setSubmitted}
      />
    </>
  );
};

export default UserCreate;