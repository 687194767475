import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import PageLoader from "../../../components/page-loader/PageLoader";
import { COMMON_REQUIRED_FORM_RULE } from "../../../constants";
import http from "../../../services/http.service";
import useAuth from "../../../hooks/useAuth";
import ErrorResult from "../../../components/error-result/ErrorResult";
import {
  formatDateTime,
  maskBigNumber,
  maskPercentage
} from "../../../shared/utils";
import {
  PageHeader,
  Button,
  Card,
  Descriptions,
  Form,
  Checkbox,
  Input,
  notification
} from "antd";

const TransactionAlert = () => {

  const [form] = Form.useForm();
  const [isPageLoading, setPageLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [pageError, setPageError] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [detail, setDetail] = useState(undefined);

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id, detailId } = useParams();

  const routes = [
    {
      path: "/pages/dashboard",
      breadcrumbName: "Inicio",
    },
    {
      path: `/pages/transactions/overview`,
      breadcrumbName: "Transacciones",
    },
    {
      path: `/pages/transactions/${id}/details`,
      breadcrumbName: `${id}`,
    },
    {
      path: `/pages/transactions/${id}/details/${detailId}/alerts`,
      breadcrumbName: "Alerta",
    }
  ];

  const onSubmitClick = async () => {

    try {

      await form.validateFields();

      setSubmitted(true);

      const payload = {
        alerta_resuelta_usu_id: user?.id,
        alerta_nota: form.getFieldValue("comments")
      };

      const request = {
        endpoint: `transacciones/${id}/alertas/${detailId}/`,
        method: "PATCH",
        data: payload
      };

      await http(request);

      notification.success({
        message: "¡Correcto!",
        description: "Alerta fue resuelta correctamente."
      });

      navigate(`/pages/transactions/${id}/details`);

    } catch (error) {

      setSubmitted(false);

      if (error?.errorFields) return false;

      notification.error({
        message: "¡Ups!",
        description: error
      });

    }

  }

  const getData = async () => {

    try {
      
      setPageLoading(true);
      setPageError(undefined);

      const request = {
        endpoint: `transacciones/${id}/detalle/`,
        method: "GET"
      };

      const response = await http(request);

      const details = response.detalles_transaccion.filter((current) => current.id === parseInt(detailId));
      
      if (details.length === 0) throw { message: "Alerta no encontrada" };

      setTransaction(response);
      setDetail(details[0]);
      setPageLoading(false);

    } catch (error) {

      setPageLoading(false);
      setPageError(error);

    }

  };

  const pageHeaderExtras = [
    <Button
      size={"small"}
      type="primary"
      key={"acceptBtn"}
      onClick={onSubmitClick}
      loading={isSubmitted}
      ghost
    >
      {!isSubmitted && <span>Aceptar</span>}
      {isSubmitted && <span>Aceptando</span>}
    </Button>
  ]

  useEffect(
    () => {
      getData();
    },
    []
  );

  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && !pageError && transaction &&
        <>
          <PageHeader
            title={"Alerta"}
            subTitle={`${formatDateTime(detail?.alerta_fh)}`}
            onBack={() => navigate(`/pages/transactions/${id}/details`)}
            breadcrumb={{ routes, itemRender }}
            extra={pageHeaderExtras}
          >            
            <Descriptions size="small" column={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
              <Descriptions.Item label={"Empresa"}>
                {transaction?.empresa}
              </Descriptions.Item>
              <Descriptions.Item label={"Posición Inicial"}>
                {maskBigNumber(detail?.posicion_inicial_acciones)}
              </Descriptions.Item>
              <Descriptions.Item label={"Porcentaje Inicial"}>
                {maskPercentage(detail?.posicion_inicial_porcentaje)}
              </Descriptions.Item>
              <Descriptions.Item label={"Expediente"}>
                {detail?.accionista?.nombre_rs}
              </Descriptions.Item>
              <Descriptions.Item label={"Posición Final"}>
                {maskBigNumber(detail?.posicion_final_acciones)}
              </Descriptions.Item>
              <Descriptions.Item label={"Porcentaje Final"}>
                <span className="textDanger">
                  {maskPercentage(detail?.posicion_final_porcentaje)}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card className="mt1" title={`${detail?.alerta_mensaje}`}>
            <Form
              form={form}
              layout={"vertical"}
            >
              <Form.Item
                name="checkbox"
                rules={COMMON_REQUIRED_FORM_RULE}
                valuePropName="checked"
              >
                <Checkbox>
                  Se cumple con el criterio indicado
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="comments"
                rules={COMMON_REQUIRED_FORM_RULE}
                label={"Comentarios"}
              >
                <Input.TextArea
                  allowClear
                  showCount
                  maxLength={150}
                />
              </Form.Item>
            </Form>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={`/pages/transactions/${id}/details`}
        />
      }
    </>
  )
}

export default TransactionAlert;