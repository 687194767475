import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../styles/PurchaseOffer.module.css";
import { itemRender } from "../../../shared/helpers/breadcrumb";
import http from "../../../services/http.service";
import useAuth from "../../../hooks/useAuth";
import PageLoader from "../../../components/page-loader/PageLoader";
import ErrorResult from "../../../components/error-result/ErrorResult";
import { INPUT_NUMBER_FORMAT } from "../../../constants";
import {
  formatDateTime,
  getDiffDates,
  maskBigNumber
} from '../../../shared/utils';
import {
  PageHeader,
  Descriptions,
  Button,
  InputNumber,
  Card,
  Form,
  Checkbox,
  Space,
  Modal,
  Typography,
  Tooltip,
  notification
} from 'antd';

const PurchaseOffer = () => {

  const [transaction, setTransaction] = useState(undefined);
  const [pageError, setPageError] = useState(undefined);
  const [form] = Form.useForm();
  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitted, setSubmitted] = useState(false);
  const [checkboxGroup, setCheckboxGroup] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [canBuy, setCanBuy] = useState(false);
  const [canReject, setCanReject] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();
  const { transactionId } = useParams();

  const { Text, Paragraph } = Typography;

  const routes = [
    {
      path: '/pages/dashboard',
      breadcrumbName: 'Inicio',
    },
    {
      path: `/pages/purchase-offer/${transactionId}`,
      breadcrumbName: 'Oferta Compra',
    },
  ];

  const onCheckboxChange = (option, e) => {
    
    const isChecked = e.target.checked;

    setSelectedOption(option);

    if(option === "RENUNCIA" && isChecked) {
      setCheckboxGroup({
        firstOption: true,
        secondOption: false,
        thirdOption: false
      });
    }
    if(option === "COMPRA" && isChecked) {
      setCheckboxGroup({
        firstOption: false,
        secondOption: true,
        thirdOption: false
      });
    }
    if(option === "COMPRA+" && isChecked) {
      setCheckboxGroup({
        firstOption: false,
        secondOption: false,
        thirdOption: true
      });
    }
 
  };

  const onModalOkClick = async () => {

    try {
      
      setModalVisible(false);
      setSubmitted(true);

      let purchaseQuantity = 0;
      let purchaseAmount = 0;
      let extraQuantity = 0;

      if (selectedOption === "COMPRA") {
        purchaseQuantity = form.getFieldValue("stockQuantity");
        purchaseAmount = transaction.total_compra;
      }

      if (selectedOption === "COMPRA+") {
        purchaseQuantity = transaction.derecho_oferta_acciones;
        purchaseAmount = transaction.total_compra_3;
        extraQuantity =  form.getFieldValue("extraQuantity");
      }

      const data = {
        transaccion_id: transactionId,
        derecho_resultado: selectedOption,
        derecho_compra: purchaseQuantity,
        derecho_total: purchaseAmount,
        derecho_solicita_extra: extraQuantity,
        derecho_registro_admin: false,
        firma: "secreta"
      };

      const request = {
        endpoint: `accionistas/${user.shareholder.id}/derechos-oferta/`,
        method: "POST",
        data
      };

      const response = await http(request);

      setSubmitted(false);

      notification.success({
        message: "¡Correcto!",
        description: "Respuesta registrada correctamente.",
      });

      if (response.v_firma_digital && response.v_firma_obligatoria) {
        const { accionista, id_operacion_firma_digital, id } = response;
        const queryParams = `transactionId=${transactionId}&messageId=1135&rfc=${accionista?.rfc_firma}&operationId=${id_operacion_firma_digital}&transactionDetailId=${id}`;
        navigate(`/pages/shareholders/sign-documents?${queryParams}`);
      } else {
        navigate("/pages/dashboard");
      }
    } catch (error) {

      setSubmitted(false);

      notification.error({
        message: '¡Ups!',
        description: error.message,
      });

    }
  };

  const getData = async () => {

    try {
      
      setPageLoading(true);
      setPageError(undefined);

      const offer = await http({ endpoint: `accionistas/${user.shareholder.id}/derechos-oferta/${transactionId}/` });

      if (!offer) {

        notification.error({
          message: '¡Ups!',
          description: "Oferta no encontrada.",
        });
        
        navigate("/pages/dashboard");

      }

      if (!offer.venta_acciones) {
        setCanBuy(true);
        setCanReject(true);
      }

      if (
        offer.venta_acciones > 0 &&
        offer.derecho_oferta_acciones > 0
      ) {
        setCanBuy(false);
        setCanReject(true);
      }

      if (offer.derecho_resultado) {

        notification.error({
          message: '¡Ups!',
          description: "Ya tienes registrada una respuesta para esta oferta.",
        });
        
        navigate("/pages/dashboard");

      }

      const total_compra = (parseFloat(offer.derecho_oferta_acciones) * parseFloat(offer.transaccion.precio));

      setTransaction({...offer, total_compra, total_compra_3: total_compra  });
      setPageLoading(false);

    } catch (error) {
      
      setPageLoading(false);
      setPageError(error);

    }
  };

  const onFormChange = (field) => {

    if (field.stockQuantity) {
      const total_compra = (parseFloat(transaction.transaccion.precio) * field.stockQuantity);
      setTransaction({...transaction, total_compra});
      form.setFieldsValue({ stockAmount: total_compra });
    }

    if (field.extraQuantity) {
      const total_extra = (parseFloat(transaction.transaccion.precio) * field.extraQuantity);
      setTransaction({...transaction, total_extra});
      form.setFieldsValue({ extraAmount: total_extra });
    }

  }

  useEffect(
    () => {
      getData();
    },
    []
  );
  
  return (
    <>
      {isPageLoading && !pageError && <PageLoader />}
      {!isPageLoading && transaction &&
        <>
          <PageHeader
            title={"Usted tiene una oferta de compra"}
            onBack={() => navigate("/pages/dashboard")}
            breadcrumb={{ routes, itemRender }}
            extra={
              <Button
                onClick={() => setModalVisible(true)}
                type="primary"
                size="small"
                ghost
                loading={isSubmitted}
                disabled={!checkboxGroup.firstOption && !checkboxGroup.secondOption && !checkboxGroup.thirdOption}
              >
                {!isSubmitted && <span>Responder</span>}
                {isSubmitted && <span>Respondiendo</span>}
              </Button>
            }
          >
            <Descriptions size="small" column={{ xs: 1, sm: 2, md: 2, lg: 3}}>
              <Descriptions.Item label="Acciones en venta">
                {maskBigNumber(transaction?.transaccion?.derecho_acciones_en_venta)}
              </Descriptions.Item>
              <Descriptions.Item label="Oferta recibida">
                {formatDateTime(transaction?.derecho_oferta_fh)}
              </Descriptions.Item>
              <Descriptions.Item label="Empresa">
                {transaction?.transaccion?.empresa?.razon_social}
              </Descriptions.Item>
              <Descriptions.Item label="Acciones">
                {maskBigNumber(transaction?.derecho_oferta_acciones)}
              </Descriptions.Item>
              <Descriptions.Item label="Vencimiento">
                {formatDateTime(transaction?.derecho_plazo_limite_fh)}
              </Descriptions.Item>
              <Descriptions.Item label="Condiciones">
                <Tooltip title={transaction?.transaccion?.condiciones}>
                  <Paragraph className="my0" ellipsis style={{maxWidth: '210px'}}>
                    {transaction?.transaccion?.condiciones}
                  </Paragraph>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label="Precio">
                {maskBigNumber(transaction?.transaccion?.precio, "currency")}
              </Descriptions.Item>
              <Descriptions.Item label="Vence en">
                {getDiffDates(transaction?.derecho_plazo_limite_fh) || "Vencida"}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Card title="Usted tiene las siguientes opciones:" bordered={false} className="mt1">
            <Form form={form} onValuesChange={onFormChange}>
              <div className={styles.optionContainer}>
                {canReject &&
                  <div className={styles.optionRow}>
                    <div>
                      <Checkbox
                        checked={checkboxGroup?.firstOption}
                        onChange={(value) => onCheckboxChange("RENUNCIA", value)}
                      >
                        <strong>
                          - Opción 1:
                        </strong>
                      </Checkbox>
                    </div>
                    <div>
                      <span>
                        Rechazar la oferta de compra de <strong>{maskBigNumber(transaction.derecho_oferta_acciones)}</strong> acciones.
                      </span> 
                    </div>
                  </div>
                }
                {canBuy &&
                  <div className={styles.optionRow}>
                    <div>
                      <Checkbox
                        checked={checkboxGroup?.secondOption}
                        onChange={(value) => onCheckboxChange("COMPRA", value)}
                      >
                        <strong>
                          - Opción 2:
                        </strong>
                      </Checkbox>
                    </div>
                    <div>
                      <span>
                        Aceptar la oferta de compra de
                      </span> 
                    </div>
                    <div className="ml1">
                      <Form.Item
                        name="stockQuantity"
                        initialValue={transaction.derecho_oferta_acciones}
                        style={{marginBottom: 0}}
                      >
                        <InputNumber
                          formatter={value => maskBigNumber(value)}
                          parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                          min={1}
                          controls={false}
                          max={transaction.derecho_oferta_acciones}
                        />
                      </Form.Item>
                    </div>
                    <div className="ml1">
                      <span>
                        acciones, por un total de
                      </span>
                    </div>
                    <div className="ml1">
                      <strong>{maskBigNumber(transaction.total_compra, "currency", 2)}</strong>
                    </div>
                  </div>
                }
                {canBuy &&
                  <div className={styles.optionRow}>
                    <div>
                      <Checkbox
                        checked={checkboxGroup?.thirdOption}
                        onChange={(value) => onCheckboxChange("COMPRA+", value)}
                      >
                        <strong>
                          - Opción 3:
                        </strong>
                      </Checkbox>
                    </div>
                    <div>
                      <span>
                        Aceptar la oferta de compra de <strong>{maskBigNumber(transaction.derecho_oferta_acciones)}</strong> acciones por un total de <strong>{maskBigNumber(transaction.total_compra_3, "currency", 2)}</strong> y solicitar
                      </span> 
                    </div>
                    <div className="ml1">
                      <Form.Item
                        name="extraQuantity"
                        style={{marginBottom: 0}}
                      >
                        <InputNumber
                          formatter={value => maskBigNumber(value)}
                          parser={value => value?.replace(INPUT_NUMBER_FORMAT, '')}
                          min={1}
                          controls={false}
                        />
                      </Form.Item>
                    </div>
                    <div className="ml1">
                      <span>
                        acciones más, por un total de <strong>{maskBigNumber(form.getFieldValue("extraAmount"), "currency", 2)}</strong> si hay disponibles.
                      </span>
                    </div>
                  </div>
                }
              </div>
            </Form>
          </Card>
        </>
      }
      {!isPageLoading && pageError &&
        <ErrorResult
          message={`${pageError?.message}`}
          onRetry={getData}
          exitPath={"/pages/dashboard"}
        />
      }
      <Modal
        title="Confirmación"
        visible={isModalVisible}
        maskClosable={false}
        onOk={onModalOkClick}
        okText={"Aceptar"}
        onCancel={() => setModalVisible(false)}
      >
        <Space direction="vertical">
          <Text>
            Se enviará la respuesta de la oferta de compra.
          </Text>
        </Space>
      </Modal>
    </>
  )
}

export default PurchaseOffer;