import { useState, createContext } from "react";

const CompanyFormContext = createContext(null);

const CompanyFormProvider = ({ children }) => {

  const defaultValues = {
    loading: false,
    empresa: {
      razon_social: '',
      nombre_corto: '',
      notas: '',
      status_d1: "A"
    },
    valoracion_x_accion: {
      fecha: '',
      precio: 0
    },
    acciones_empresa: [],
    posicion_actual: []
  };

  const [values, setValues] = useState(defaultValues);

  return (
    <CompanyFormContext.Provider value={{ values, setValues }}>
      {children}
    </CompanyFormContext.Provider>
  );
}

export {
  CompanyFormProvider
}

export default CompanyFormContext;