import {
  TeamOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  CalendarOutlined
} from "@ant-design/icons";

export const BASE_API_URL = "https://api.accionistasintercam.com.mx"; // https://dev-api.accionistasintercam.com.mx
export const LITERAX_USERNAME = "root.intercam@gmail.com"
export const LITERAX_PASSWORD = "!#90Bv3xp2023#"
export const LITERAX_SESSION_URL = "https://api.literaxdemo.origon.app/api/ws/v1/sessions" // https://api.literaxdemo.origon.app/api/ws/v1/sessions
export const RECAPTCHA_SITE_KEY = "6LcSKa0mAAAAALv7wtJJxvcP2UFht3HMk9XldcLu"
export const COMMON_ACCEPT_FILE_FORMATS = "image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const STORAGE_TOKEN_NAME = "credentials";
export const STORAGE_CONFIG_NAME = "config";
export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const ALPHA_PATTERN = /^[a-zA-Z áéíóúñÑ]+$/;
export const ALPHA_NUMERIC_PATTERN = /^[a-zA-Z0-9 áéíóúñÑ]+$/;
export const NUMERIC_PATTERN = /^[0-9]+$/;
export const INPUT_NUMBER_FORMAT = /\$\s?|(,*)/g;
export const CURP_PATTERN = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/
export const RFC_PATTERN = /^([A-ZÑ&]{3,4})?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))?(?:- ?)?([A-Z\d]{2})([A\d])$/
export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
export const COMMON_REQUIRED_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
];
export const COMMON_EMAIL_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    whitespace: false,
    message: "No se acepta solo espacios.",
  },
  {
    pattern: EMAIL_PATTERN,
    message: "Formato incorrecto.",
  },
];
export const COMMON_TELEPHONE_FORM_RULE = [
  {
    validator(_, value) {
      if (!value) return Promise.reject(new Error("Campo requerido."));
      const valueSanitized = value.split(".").join("");
      const regex = new RegExp(NUMERIC_PATTERN);
      if (!valueSanitized) return Promise.reject(new Error("Campo requerido."));
      else if (valueSanitized.length < 10) return Promise.reject(new Error("Mínimo 10 números."));
      else if (!regex.test(valueSanitized)) return Promise.reject(new Error("Solo se aceptan números."));
      else return Promise.resolve();
    },
  },
];
export const COMMON_ALPHA_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: ALPHA_PATTERN,
    message: "Solo se aceptan letras.",
  },
];
export const COMMON_PASSWORD_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    min: 8,
    message: "Mínimo 8 caracteres.",
  },
  {
    pattern: PASSWORD_PATTERN,
    message: "Al menos una mayúscula, un número y un caracter especial.",
  },
]
export const COMMON_ALPHA_NUMERIC_FORM_RULE = [
  {
    required: true,
    message: "Campo requerido.",
  },
  {
    type: "string",
    whitespace: true,
    message: "No se aceptan solo espacios.",
  },
  {
    pattern: ALPHA_NUMERIC_PATTERN,
    message: "Solo se aceptan letras y números.",
  },
];

export const DRAWER_MODULES = [
  {
    label: "Transacciones",
    id: "3005",
    key: "/pages/transactions/overview",
    icon: <UnorderedListOutlined />,
  },
  {
    label: "Participantes",
    id: "1100",
    key: "/pages/shareholders/overview",
    icon: <TeamOutlined />,
  },
  {
    label: "Compañías",
    id: "1200",
    key: "/pages/companies/overview",
    icon: <AppstoreOutlined />,
  },
  {
    label: "Eventos",
    id: "2020",
    key: "/pages/events/overview",
    icon: <CalendarOutlined />,
  },
  {
    label: "Usuarios",
    id: "4010",
    key: "/pages/users/overview",
    icon: <TeamOutlined />,
  },
];

export const DATE_FORMAT = "DD/MM/YY";
export const DATETIME_FORMAT = "DD/MM/YY HH:mm";
export const DEFAULT_MODAL_STATE = {
  visible: false,
  loading: false
};
export const DEFAULT_CUSTOM_TABLE_CONFIG = {
  queryParams: [],
  data: [],
  rowKey: "",
  onRefresh: f => f,
  paginator: {
    total: 0,
    next: null,
    prev: null,
    pageSize: 10,
    showSizeChanger: false,
  },
  loading: true,
  columns: [],
  updateFromTable: false,
  endpoint: "",
  url: ""
};